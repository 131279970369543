import { useQuery } from '@tanstack/react-query';
import { getProfileHrContracts } from '../../api';

export const useProfileHrContracts = (userRecordId: string | undefined) => {
  const { data: profileHrContracts = null, isLoading: loading } = useQuery({
    queryKey: ['get-profile-hr-contracts', userRecordId],
    queryFn: () => getProfileHrContracts(userRecordId),
    enabled: !!userRecordId,
  });

  return {
    profileHrContracts,
    loading,
  };
};
