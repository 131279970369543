import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { getWindowSize, isFeatureEnabled } from '@/utils';
import { Form, InputNumber, Select, Switch } from 'antd';
import { SelectValue } from 'antd/es/select';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import SettingsForm from '../../components/SettingsForm';
import { useInsightSettingsUpdate } from './hooks';
import { turnoverUnitTranslation } from '@/pages/app/settings';

const { Option } = Select;
interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  refreshDepartments: () => void;
}

const InsightSettings: React.FC<Props> = ({ className, currentSettings, refreshDepartments }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<SelectValue | null>(null);
  const [unitType, setUnitType] = useState<SelectValue | null>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [checked, setChecked] = useState<(string | null)[]>([]);

  const { updateSettings, isLoading } = useInsightSettingsUpdate();
  
  const updateForm = (c: any, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
    setUnitType(prev => prev || currentSettings?.general?.insight_type);
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (currentSettings) {
      setChecked([
        ...checked,
        ...Object.entries(currentSettings.general!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
        ...Object.entries(currentSettings.schedule!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
        ...Object.entries(currentSettings.insights!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
      ]);
    }
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    if (currentSettings?.insights?.productivity_calculation_type) {
      setTypeSelected(currentSettings?.insights?.productivity_calculation_type);
    }
    form.setFieldsValue(fieldsValue);
  };

  const onFinish = (values: any) => {
    updateSettings({
      enableTurnover: values.general.enable_turnover,
      productivityBySectionEnabled: values.general.turnover_per_section,
      insightsType: values.general.insight_type,
      useProductivityIndexEnabled: values.general.productivity_clocking,
      staffQuotaCalculationMethod: values.general.staff_quota_calculation_method,
      productivityCalculation: {
        method: values.general.productivity_calculation_type,
        startValue: values.general.default_hours || 0,
        coefficient: values.general.coefficient,
        provisionalEnabled: values.general.enable_provisional,
      },
    })
    setFormHasChanged(false);
    refreshDepartments();
  };

  const onProductivityTypeChange = (value: SelectValue) => {
    setTypeSelected(value);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
    },
    {
      title: t('SETTINGS.INSIGHTS.SETTINGS.TITLES.PRODUCTIVITY'),
      visible: checked.includes('enable_turnover') && isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.DESCRIPTION'),
          name: ['general', 'enable_turnover'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.general?.enable_turnover}
              onChange={(checked, e) => updateForm(checked, e, 'enable_turnover')}
            />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.SECTION_TITLE'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.SECTION_DESCRIPTION'),
          name: ['general', 'turnover_per_section'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.productivity_by_section_enabled} />,
          show: isFeatureEnabled(features, FEATURES.SECTIONS),
        },
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.UNIT_TITLE'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.UNIT_DESCRIPTION'),
          name: ['general', 'insight_type'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Select
              defaultValue={currentSettings?.general?.insight_type || 'ca'}
              dropdownMatchSelectWidth={false}
              onChange={(value) => setUnitType(value)}
              options={[
                {
                  value: 'ca',
                  label: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.UNIT_TURNOVER'),
                },
                {
                  value: 'table_reservations',
                  label: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.UNIT_TABLE'),
                },
                {
                  value: 'number_of_events',
                  label: t('SETTINGS.INSIGHTS.SETTINGS.UNIT.UNIT_EVENT'),
                },
              ]}
            />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.STAFF_CALCULATION_METHOD.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.STAFF_CALCULATION_METHOD.DESCRIPTION'),
          name: ['general', 'staff_quota_calculation_method'],
          style: { margin: 0 },
          element: (
            <Select
              defaultValue={currentSettings?.general?.staff_quota_calculation_method}
              dropdownMatchSelectWidth={false}
              options={[
                {
                  value: 'insights_based',
                  label: t(turnoverUnitTranslation[form.getFieldValue(['general', 'insight_type']) || 'ca']),
                },
                {
                  value: 'manual',
                  label: t('SETTINGS.INSIGHTS.SETTINGS.STAFF_CALCULATION_METHOD.MANUAL'),
                },
              ]}
            />
          ),
          show: true,
        },
      ],
    ],
    [
      unitType !== 'ca'
        ? []
        : [
            {
              label: '',
              description: '',
              rowStyle: { marginTop: 10 },
              style: { margin: 0 },
              element: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: windowSize.innerWidth > 900 ? 'center' : 'flex-start',
                    flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column',
                    gap: 15,
                  }}
                >
                  <Form.Item
                    style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                    label={t('SETTINGS.INSIGHTS.PRODUCTIVITY_CALCULATION_TYPE')}
                    name={['insights', 'productivity_calculation_type']}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{ width: windowSize.innerWidth > 900 ? 300 : '100%' }}
                      onChange={onProductivityTypeChange}
                    >
                      <Option value="HOURS_WORKED">{t('SETTINGS.INSIGHTS.TURNOVER_VS_HOURS_WORKED')}</Option>
                      <Option value="STAFF_COSTS">{t('SETTINGS.INSIGHTS.TURNOVER_VS_STAFF_COSTS')}</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['insights', 'default_hours']}
                    label={
                      typeSelected && typeSelected == 'STAFF_COSTS'
                        ? t('INSIGHTS.COST_TO_ADD')
                        : t('INSIGHTS.HOURS_TO_ADD')
                    }
                    style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      parser={(value) => {
                        return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                      }}
                      style={{ width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['insights', 'coefficient']}
                    label={t('GLOBAL.COEFFICIENT')}
                    style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      parser={(value) => {
                        return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                      }}
                      style={{ width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                    />
                  </Form.Item>
                </div>
              ),
              elementBelow: true,
              show: true,
            },
            {
              label: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.LABEL'),
              description: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.DESCRIPTION'),
              name: ['schedule', 'enable_provisional'],
              valuePropName: 'checked',
              style: { margin: 0 },
              element: <Switch defaultChecked={currentSettings?.schedule?.enable_provisional} />,
              show: true,
            },
            {
              label: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.LABEL'),
              description: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.DESCRIPTION'),
              name: ['insights', 'productivity_clocking'],
              valuePropName: 'checked',
              style: { margin: 0 },
              element: <Switch defaultChecked={currentSettings?.insights?.productivity_clocking} />,
              show: isFeatureEnabled(features, FEATURES.CLOCKING),
            },
          ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={isLoading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default InsightSettings;
