import AppContext from '@/pages/app/context';
import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { duplicateContract } from '../api';
import { V4Contract } from '../types/contracts.types';

interface Props {
  className?: string;
  contract: V4Contract;
}

const DuplicateContract: React.FC<Props> = ({ className, contract }) => {
  const { t } = useTranslation();
  const {
    state: { users },
  } = useContext(AppContext);

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['duplicate-contract', contract.id],
    mutationFn: () => duplicateContract(contract.id),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-contracts'] }),
  });

  const onWantToDuplicateContract = () => {
    const user = users.find((user) => user.recordId == contract.userRecordId);
    if (!user) return;

    Modal.confirm({
      className: 'modal-constraints',
      title: t('documents.contracts.duplicate-contract-confirmation.title', { user: user.displayName }),
      icon: null,
      content: t('documents.contracts.duplicate-contract-confirmation.content', { user: user.displayName }),
      maskClosable: true,
      okText: t('general.duplicate'),
      onOk: () => {
        mutate();
      },
    });
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.duplicate-contract')}>
        <Button type="ghost" className="action" onClick={onWantToDuplicateContract} loading={loading}>
          <i className="icon-clone" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DuplicateContract)``;
