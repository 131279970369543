import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4ContractTemplate } from '../types/contract-templates.types';

interface Props {
  className?: string;
  file: V4ContractTemplate['file'];
}

const DownloadContractTemplate: React.FC<Props> = ({ className, file }) => {
  const { t } = useTranslation();

  const onDownloadContractTemplate = () => {
    if (!file) return;
    window.open(file, '_blank');
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.templates.download-contract-template')}>
        <Button type="ghost" className="action" disabled={!file} onClick={onDownloadContractTemplate}>
          <i className="icon-download" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DownloadContractTemplate)``;
