import AppContext from '@/pages/app/context';
import { useContractTemplates } from '@/pages/app/documents/contracts/templates/hooks/use-contract-templates';
import { IDepartment } from '@/types/department.model';
import { AUTO_DIMONA_WORK_TYPES } from '@/types/dimona.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Option = Select.Option;

interface Props {
  className?: string;
  department?: IDepartment;
}

const Automation: React.FC<Props> = ({ className, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features },
  } = useContext(AppContext);

  const { contractTemplates, loading: loadingContractTemplates } = useContractTemplates();

  return (
    <div className={className}>
      <h4>{t('GLOBAL.AUTOMATION')}</h4>
      <Row style={{ margin: '20px 0 20px' }}>
        <Col lg={16} md={24}>
          {isFeatureEnabled(features, FEATURES.DIMONA) && department?.params?.automaticDimona && (
            <div className="form-items-group one-line">
              <span style={{ width: 120 }}>Dimona</span>
              <Form.Item noStyle name={['params', 'workerType']} className="one-line">
                <Select
                  style={{ marginLeft: 10, width: '100%' }}
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                >
                  {AUTO_DIMONA_WORK_TYPES.map((workType) => {
                    const { id } = workType;
                    return (
                      <Option key={`workType_${id}`} value={id!}>
                        {t(`DIMONA.WORKTYPES.${id}`)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item noStyle name={['params', 'automatic_dimona']} valuePropName="checked" className={'one-line'}>
                <Checkbox style={{ marginLeft: 10 }}>{t('GLOBAL.AUTOMATIC')}</Checkbox>
              </Form.Item>
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.CONTRACTS) && department?.params?.automaticContracts && (
            <div className="form-items-group one-line">
              <span style={{ width: 120 }}>{t('GLOBAL.CONTRACT')}</span>
              <Form.Item noStyle name={['params', 'automatic_contract_template_id']}>
                <Select
                  style={{ marginLeft: 10, width: '100%' }}
                  getPopupContainer={(trigger) => trigger}
                  disabled={loadingContractTemplates}
                  loading={loadingContractTemplates}
                  placeholder={t('USER_CATEGORIES.DEFAULT_CONTRACT_TEMPLATE')}
                  allowClear={true}
                >
                  {contractTemplates?.map((contractTemplate) => (
                    <Option key={`template_${contractTemplate.id}`} value={`${contractTemplate.id}`}>
                      {contractTemplate.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                name={['params', 'automatic_contracts']}
                valuePropName="checked"
                className={'one-line'}
              >
                <Checkbox style={{ marginLeft: 10 }}>{t('GLOBAL.AUTOMATIC')}</Checkbox>
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const AutomationStyled = styled(Automation)`
  .ant-row.ant-form-item.one-line {
    .ant-col.ant-form-item-label {
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
`;

export default AutomationStyled;
