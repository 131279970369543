import React, { FC, ReactNode } from 'react';
import Tag from './Tag';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { minutesToHoursAndOrMinutes } from '@/utils';

type ShiftVerificationHoursColumnProps = {
  hours: number;
  status: string;
  className?: string;
};

const dataPerStatus: { [$key: string]: { translationKey: string; color: string; icon: ReactNode } } = {
  under: {
    translationKey: 'DASHBOARD.WIDGETS.UNDERBOOKING',
    color: '#3279bb',
    icon: <i className="icon-up-big" style={{ transform: 'rotate(180deg)', display: 'inline-block' }} />,
  },
  over: {
    translationKey: 'DASHBOARD.WIDGETS.OVERBOOKING',
    color: '#d95c62',
    icon: <i className="icon-up-big" />,
  },
};

const ShiftVerificationHoursColumn: FC<ShiftVerificationHoursColumnProps> = ({ hours, status, className }) => {
  const { t } = useTranslation();
  const { translationKey, color, icon } = dataPerStatus[status] ?? {};

  return (
    <span className={`${className} ${status}`}>
      <Tag
        icon={icon}
        color={color}
        name={`${minutesToHoursAndOrMinutes(Math.abs(hours) * 60)} ${t(translationKey)}`}
      />
    </span>
  );
};

const ShiftVerificationHoursColumnStyled = styled(ShiftVerificationHoursColumn)`
  display: flex;
  justify-content: flex-end;
`;

export default ShiftVerificationHoursColumnStyled;
