export enum FEATURES {
  PLUG_POS = 'plug-n-pos',
  DVORE = 'dvore',
  RESTOMAX = 'restomax',
  GROWZER = 'growzer',
  UCM_SYNCRO = 'ucm-syncro',
  LIANTIS_SYNCRO = 'liantis-syncro',
  SALARY_SOLUTION_SYNCRO = 'salary-solution-syncro',
  DAILY_DIMONA = 'daily-dimona',
  CLOCKING = 'clocking',
  SCHEDULE = 'schedule',
  FREESHIFTS = 'freeshifts',
  CONTRACTS = 'contracts',
  AUTOMATIC_DIMONA_CONTRACTS = 'automatic-dimona-contracts',
  LEAVE_MANAGEMENT = 'leaves-management',
  PRODUCTIVITY = 'productivity',
  QUOTAS = 'quotas',
  SECTIONS = 'sections',
  CUSTOM_PAYROLL_RULES = 'custom-payroll-rules',
  TASKS = 'tasks',
  ATTRIBUTES = 'attributes',
  DIMONA = 'dimona',
  SKILLS = 'skills',
  HR_PARTNER = 'hr-partner',
  ATTENTIA_SYNC = 'attentia-sync',
}

export interface IFeature {
  code: string;
  category: string;
  module_id: string;
  pack_id: string;
  installed: boolean;
}
