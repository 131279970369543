import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalSwitchToProAccount: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onCancel = () => {
    setVisible(false);
  };

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(false);
    history.push('/app/settings/billing/payment-details');
  };

  return (
    <Modal className={className} maskClosable visible={visible} footer={null} onCancel={onCancel} onOk={onCancel}>
      <div className="container">
        <h2 className="title">{t('MODAL_SWITCH_TO_PRO.TITLE')}</h2>
        <div className="features">{t('MODAL_SWITCH_TO_PRO.FEATURES')}</div>
        <Button type="primary" size="large" onClick={onClick} loading={loading}>
          {t('MODAL_SWITCH_TO_PRO.SUBMIT_BTN')}
        </Button>
      </div>
    </Modal>
  );
};

export default styled(ModalSwitchToProAccount)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .container {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-weight: bold;
  }

  .subtitle {
    margin-top: 1em;
    font-weight: bold;

    .accent {
      color: ${colors.green};
      font-weight: bold;
    }
  }
`;
