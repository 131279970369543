import TableView from '@/layouts/TableView';
import { Table } from 'antd';
import 'moment-timezone';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GetDocumentsParams } from '../api';
import { useDocuments } from '../hooks/use-documents';
import Header from './header';

type Props = {
  className?: string;
  userRecordId?: string;
};

const DocumentsList = ({ className, userRecordId }: Props) => {
  const initialParams = useMemo<GetDocumentsParams>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      userRecordId,
      page: searchParams.get('page') ? parseInt(searchParams.get('page') as string, 10) : 1,
      per_page: searchParams.get('per_page') ? parseInt(searchParams.get('per_page') as string, 10) : 10,
      q: searchParams.get('q') || undefined,
      sort_by: searchParams.get('sort_by') || undefined,
      sort_direction: (searchParams.get('sort_direction') as 'asc' | 'desc') || undefined,
    } as GetDocumentsParams;
  }, [userRecordId]);

  const [params, setParams] = useState<GetDocumentsParams>(initialParams);

  const { columns, documents, loading, meta } = useDocuments(params);

  // Mapping table column dataIndex to API sorting fields
  const columnSortMapping: { [key: string]: string } = {
    id: 'id',
    userRecordId: 'first_name',
    sentAt: 'sent_at',
    signatureRequired: 'signature_required',
    expiryDate: 'expiry_date',
    createdAt: 'created_at',
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let sort_by: GetDocumentsParams['sort_by'];
    let sort_direction: GetDocumentsParams['sort_direction'];
    if (!Array.isArray(sorter)) {
      sort_by = (columnSortMapping[sorter.field] as GetDocumentsParams['sort_by']) || undefined;
      if (sorter.order === 'ascend') {
        sort_direction = 'asc';
      } else if (sorter.order === 'descend') {
        sort_direction = 'desc';
      }
    }
    setParams((prev) => ({
      ...prev,
      page: pagination.current,
      per_page: pagination.pageSize,
      sort_by,
      sort_direction,
    }));
  };

  return (
    <TableView className={className}>
      <Header onSearchChange={(q: string) => setParams((prev) => ({ ...prev, q, page: 1 }))} currentSearch={params.q} />
      <Table
        dataSource={documents}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: params.page,
          pageSize: params.per_page,
          total: meta?.total,
          position: ['bottomRight'],
          showSizeChanger: true,
        }}
        rowClassName={(contract) => (contract.sentAt ? '' : 'pending-row')}
        onChange={handleTableChange}
      />
    </TableView>
  );
};

export default styled(DocumentsList)``;
