import React, {FC, useContext, useState} from 'react';
import 'moment-timezone';
import moment from 'moment';
import colors from '@/styles/colors';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { Spin } from 'antd';
import { isFeatureEnabled } from '@/utils';
import { useDailyTurnover } from './hooks';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@/types/features.model';
import { Header, LineChart, TurnoverForm, SectionSelector } from './components';

type InsightDailyTurnoverProps = {
  sectionEnabled: boolean;
  className?: string;
};

const InsightDailyTurnover: FC<InsightDailyTurnoverProps> = ({ sectionEnabled, className }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [sectionId, setSectionId] = useState<string | null>(null);
  const { dailyTurnover, loading } = useDailyTurnover(
    startDate.format('YYYY-MM-DD'),
    startDate.clone().endOf('month').format('YYYY-MM-DD'),
    sectionId,
  );

  const sectionsAllowed = isFeatureEnabled(features, FEATURES.SECTIONS) && sectionEnabled;

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.TITLE')}</h2>
      <Header startDate={startDate} onStartDateChange={(date) => setStartDate(date)} />
      <Spin spinning={loading}>
        <div className="chart-container">
          <LineChart loading={loading} data={dailyTurnover} startDate={startDate} />
        </div>
        {sectionsAllowed && (
          <SectionSelector
            sectionId={sectionId}
            onTabChange={(newSectionId) => setSectionId(newSectionId === 'null' ? null : newSectionId)}
          />
        )}
        <div className="form-header">
          <div>
            <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
            <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
          </div>
          <div>
            <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
            <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
          </div>
        </div>
        <TurnoverForm
          sectionsAllowed={sectionsAllowed}
          startDate={startDate}
          sectionId={sectionId}
          dailyTurnover={dailyTurnover}
          loading={loading}
        />
      </Spin>
    </div>
  );
};

export default styled(InsightDailyTurnover)`
  header {
    border-radius: 5px;
    background-color: ${colors.greyLight};
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-container {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .form-header {
    display: flex;

    > div {
      margin: 15px 0;
      display: flex;
      width: 50%;

      span {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: bold;

        &:nth-of-type(1) {
          margin-left: 185px;
          margin-right: 10px;
        }
      }

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      &:nth-of-type(2) {
        margin-left: 20px;
      }
    }
  }

  form {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .day {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:nth-of-type(odd) {
        padding-right: 20px;
      }

      &:nth-of-type(even) {
        padding-left: 20px;
      }

      .L {
        width: 100px;
      }

      .weekday {
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }

      .ant-row.ant-form-item {
        flex: 1;
        margin-bottom: 0;
        width: 100%;

        &:first-of-type {
          margin-right: 5px;
        }

        &:nth-of-type(2) {
          margin-left: 5px;
        }

        .ant-input-number.ant-input-number-lg {
          width: 100%;
        }
      }
    }

    > .actions {
      position: fixed;
      width: 100%;
      max-width: 1024px;
      background: white;
      bottom: -80px;
      margin: 0;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;
      z-index: 2;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
