import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getDocumentTemplate } from '../api';

export const useDocumentTemplate = (documentTemplateId: string) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const isEnabled = documentTemplateId !== 'new';

  const { data: documentTemplate = null, isLoading } = useQuery({
    queryKey: ['get-document-template', activeDepartmentId, documentTemplateId],
    queryFn: () => getDocumentTemplate(documentTemplateId),
    enabled: isEnabled,
  });

  return {
    loading: isEnabled ? isLoading : false,
    documentTemplate,
  };
};
