import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IField } from '@/types/field.model';
import { IUserRole, USER_ROLES } from '@/types/user-role.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled } from '@/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, List, Modal, Row, Select, Spin, Tooltip, message } from 'antd';
import axios from 'axios';
import { countryCodeEmoji } from 'country-code-emoji';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import i18next from 'i18next';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ModalChangePassword from '../../components/ModalChangePassword';
import UserInfosStyled from '../../components/UserInfos';
import FormActions from '../FormActions';
import ModalDeleteUserConfirmation from '../sider-left/ModalDeleteUserConfirmation';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);

const { Option } = Select;
const { confirm } = Modal;
interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
}

const ProfileTab: React.FC<Props> = ({ className, user, department }) => {
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const {
    state: { features },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [countriesTranslated, setCountriesTranslated] = useState<[string, string][]>();
  const [countriesEmojis, setCountriesEmojis] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [response, setResponse] = useState<IResponse>();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [loadingSyncAy, setLoadingSyncAy] = useState<boolean>(false);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState<boolean>(false);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const customFields = response?.data?.custom_fields;

  useEffect(() => {
    const countr = translatedCountries.getNames(i18next.language);
    const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
    setCountriesEmojis(sorted.map((el) => el[0]).map(countryCodeEmoji));
    setCountriesTranslated(sorted);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setResponse(undefined);
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/profile`, {
        params: {
          departmentId: department?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          setResponse(response.data as IResponse);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [user, department?.id]);

  useEffect(() => {
    onReset();
  }, [response]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (response) {
      const birthdate = response.data?.profile_extra?.birthdate;
      form.setFieldsValue({
        ...response.data,
        profile_extra: {
          ...response.data?.profile_extra,
          birthdate: birthdate && birthdate != 'Invalid date' ? moment.utc(birthdate) : null,
        },
        custom_fields: response?.data?.custom_fields?.reduce((a, b) => {
          a[b.id!] = b.value || null;
          return a;
        }, {} as { [key: string]: string | null }),
      });
    }
  };

  const [phone, setPhone] = useState('');

  const handlePhoneChange = (value: string) => {
    // Ensure the phone number always has a + prefix
    if (!value.startsWith('+') && !value.startsWith('0')) {
      value = `+${value}`;
    }
    setPhone(value);
  };

  const onFinish = (values: any) => {
    setSaving(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/${user!.recordId}/profile`,
        {
          ...values,
          profile_extra: {
            ...values?.profile_extra,
            civil_state: values?.profile_extra?.civil_state || '',
            birthdate: values?.profile_extra?.birthdate
              ? moment(values?.profile_extra?.birthdate).format('YYYY-MM-DD')
              : null,
          },
          profile: {
            ...values?.profile,
            phone: phone ? phone : values?.profile?.phone,
          },
        },
        {
          params: {
            departmentId: department?.id,
          },
        },
      )
      .then((response) => {
        setSaving(false);
        setResponse(response.data as IResponse);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(error.response.data.errors).flat(1) as string[]);
        }
        setSaving(false);
      });
  };

  const onRemove = (e: any) => {
    e.preventDefault();
    if (user?.pending) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}`, {
          params: {
            departmentId: department?.id,
          },
        })
        .then(() => {
          message.success(t('USERS.MODAL_DELETE_USER.MESSAGE_SUCCESS'));
          history.push('/app/team/pending-collaborators');
        })
        .catch(() => {
          message.error(t('USERS.MODAL_DELETE_USER.MESSAGE_ERROR'));
        });
    } else {
      setShowModalDeleteUser(true);
    }
  };

  const onSendInfo = (e: any) => {
    e.preventDefault();
    confirm({
      title: t('USERS.MODAL_SEND_INFO.TITLE'),
      icon: <ExclamationCircleOutlined />,
      content: t('USERS.MODAL_SEND_INFO.CONTENT', { name: user?.displayName }),
      okText: t('USERS.MODAL_SEND_INFO.CONFIRM'),
      cancelText: t('GLOBAL.CANCEL'),
      onOk() {
        axios
          .post(`${process.env.REACT_APP_API_URL}/v3/users/send-connection-info`, {
            recordIds: [user?.recordId],
            departmentId: department?.id,
          })
          .then(() => {
            message.success(t('USERS.MODAL_SEND_INFO.MESSAGE_SUCCESS'));
          })
          .catch((error) => {
            console.error(error);
            message.error(t('USERS.MODAL_SEND_INFO.MESSAGE_ERROR'));
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const canRemove =
    !((user?.role as IUserRole)?.id === USER_ROLES.ADMIN && department?.role !== 'ADMIN') &&
    userDetails?.id !== user?.mainId;

  const validateUser = (validate: boolean) => {
    setLoadingValidation(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/validation/${user?.recordId}`,
        {
          approvePending: validate,
        },
        {
          params: {
            departmentId: department?.id,
          },
        },
      )
      .then(() => {
        setLoadingValidation(false);
        history.push(`/app/team/pending-collaborators`);
        history.push(`/app/team/collaborators/${user?.recordId}/dashboard`, { activeTab: '2' });
      })
      .catch((error) => {
        console.error(error);
        message.error('GLOBAL.AN_ERROR_OCCURRED');
        setLoadingValidation(false);
      });
  };

  const onWantToActivate = (userId: string | undefined | null) => {
    Modal.confirm({
      title: t('GLOBAL.ACTIVATION'),
      icon: null,
      content: t('USERS.MODAL_ACTIVATE_USER.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.ACTIVATE'),
      okType: 'danger',
      onOk: () => {
        onActivate(userId);
      },
      onCancel: () => {},
    });
  };

  const onActivate = (userId: string | undefined | null) => {
    if (!userId) return;

    axios
      .patch(`${process.env.REACT_APP_API_URL}/v3/users/${userId}/params`, {
        departmentId: department?.id,
        active: true,
      })
      .then(() => {
        message.success(t('USERS.MODAL_ACTIVATE_USER.MESSAGE_SUCCESS', { name: user?.displayName }));
        history.push(`/app/team/collaborators`);
      })
      .catch((error) => {
        message.error(t('USERS.MODAL_ACTIVATE_USER.MESSAGE_ERROR'));
      });
  };

  const onChangePassword = () => {
    setShowChangePasswordModal(true);
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  const onFinishedFailed = (data: any) => {
    if (data.errorFields) {
      const error = data.errorFields[0];
      if (error.name.includes('phone')) {
        const phone_container = document.getElementById('profile.phone');
        phone_container?.getElementsByTagName('input')[0].focus();
      } else {
        if (form.getFieldInstance(error.name).focus) {
          form.getFieldInstance(error.name).focus();
        } else {
          form.scrollToField(error.name);
        }
      }
    }
  };

  return (
    <>
      <div className={className}>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            className="user-form"
            onValuesChange={() => setFormHasChanged(true)}
            onFinish={onFinish}
            size={'large'}
            style={{ marginBottom: formHasChanged ? '25px' : 0 }}
            onFinishFailed={onFinishedFailed}
          >
            <h4>{t('GLOBAL.BASIC_INFORMATION')}</h4>
            <Row className="row">
              <Col lg={16}>
                <Row gutter={[20, 0]}>
                  <Col md={12} style={{ width: '100%' }}>
                    <Form.Item
                      id={`['profile', 'first_name']`}
                      label={t('GLOBAL.FIRSTNAME')}
                      name={['profile', 'first_name']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} style={{ width: '100%' }}>
                    <Form.Item
                      id={`profile.last_name`}
                      label={t('GLOBAL.LASTNAME')}
                      name={['profile', 'last_name']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} style={{ width: '100%' }}>
                    <Form.Item id={`profile.name`} label={t('GLOBAL.NICKNAME')} name={['profile', 'name']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} style={{ width: '100%' }}>
                    <Form.Item
                      id={`profile.preferred_language_code`}
                      label={t('FORMS.LANGUAGE')}
                      name={['profile', 'preferred_language_code']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select style={{ width: '100%' }} placeholder={t('FORMS.LANGUAGE')}>
                        <Option value={1}>Français</Option>
                        <Option value={2}>Nederlands</Option>
                        <Option value={3}>English</Option>
                        <Option value={4}>Español</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} style={{ width: '100%' }}>
                    <Form.Item
                      id={`profile.email`}
                      label={t('GLOBAL.EMAIL')}
                      name={['profile', 'email']}
                      rules={[{ required: true, message: '', type: 'email' }]}
                    >
                      <Input
                        disabled={department?.role !== 'ADMIN' || !response?.flags?.edit_email}
                        addonAfter={
                          department?.role !== 'ADMIN' ||
                          (!response?.flags?.edit_email && (
                            <Tooltip title={t('USERS.CANNOT_EDIT_EMAIL')}>
                              <i className="icon-info-circled" />
                            </Tooltip>
                          ))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col id="profile.phone" md={12} style={{ width: '100%' }}>
                    <Form.Item
                      id={`profile.phone`}
                      label={t('GLOBAL.PHONE')}
                      name={['profile', 'phone']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <PhoneInput
                        containerClass="ant-form-item-control-input-content"
                        inputClass="ant-input"
                        inputStyle={{ width: '100%', background: '#f7f8fb' }}
                        onChange={handlePhoneChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Col lg={24} className="user-infos">
                  <UserInfosStyled
                    user={user}
                    departmentId={department?.id}
                    route="/app/team/collaborators/$/dashboard"
                  />
                  {!!user?.pending && (
                    <div style={{ display: 'flex', gap: 10 }}>
                      <Button
                        type="primary"
                        size="large"
                        loading={loadingValidation}
                        className="action"
                        onClick={() => validateUser(true)}
                        style={{ marginBottom: 10 }}
                      >
                        <span>{t('GLOBAL.VALIDATE')}</span>
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        loading={loadingValidation}
                        className="action"
                        onClick={() => validateUser(false)}
                        danger
                        style={{ marginBottom: 10 }}
                      >
                        <span>{t('GLOBAL.INVALIDATE')}</span>
                      </Button>
                    </div>
                  )}
                  <div className="actions">
                    {!user?.pending && isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                      <button
                        className="action"
                        onClick={() => history.push(`/app/team/collaborators/${user?.recordId}/schedule`)}
                      >
                        <i className="icon-calendar-alt"></i>
                        <span>{t('GLOBAL.SCHEDULE')}</span>
                      </button>
                    )}
                    {!user?.pending && (
                      <button id="user_send_info_btn" className="action" onClick={onSendInfo}>
                        <i className="icon-paper-plane-empty" />
                        <span>{t('GLOBAL.SEND_INFO')}</span>
                      </button>
                    )}
                    {response?.flags.edit_password && (
                      <button className="action" onClick={onChangePassword}>
                        <i className="icon-lock"></i>
                        <span>{t('GLOBAL.CHANGE_PASSWORD')}</span>
                      </button>
                    )}
                    {canRemove && user?.active && (
                      <button className="action danger" onClick={onRemove}>
                        <i className="icon-trash-empty"></i>
                        <span>{t('GLOBAL.DEACTIVATE')}</span>
                      </button>
                    )}
                    {canRemove && !user?.active && !user?.pending && (
                      <button
                        className="action"
                        onClick={(e) => {
                          e.preventDefault();
                          onWantToActivate(user?.recordId);
                        }}
                      >
                        <i className="icon-ok"></i>
                        <span>{t('GLOBAL.REACTIVATE')}</span>
                      </button>
                    )}
                  </div>
                </Col>
              </Col>
            </Row>
            <h4>{t('GLOBAL.ADDITIONAL_INFORMATION')}</h4>
            <Row gutter={[20, 0]}>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.national_number`}
                  label={t('GLOBAL.NATIONAL_NUMBER')}
                  name={['profile_extra', 'national_number']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.birthdate`}
                  label={t('GLOBAL.BIRTH_DATE')}
                  name={['profile_extra', 'birthdate']}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={'L'}
                    onSelect={(e) => {
                      const profile_extra = { ...form.getFieldValue('profile_extra') };
                      profile_extra.birthdate = e;
                      form.setFieldsValue({
                        profile_extra,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.birthplace`}
                  label={t('GLOBAL.BIRTH_PLACE')}
                  name={['profile_extra', 'birthplace']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.civil_state`}
                  label={t('GLOBAL.CIVIL_STATE')}
                  name={['profile_extra', 'civil_state']}
                >
                  <Select style={{ width: '100%' }} allowClear>
                    {response?.meta.civil_state.map((_item) => (
                      <Option key={'civil_state_' + _item.id} value={`${_item.id}`}>
                        {_item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item id={`profile_extra.gender`} label={t('GLOBAL.GENDER')} name={['profile_extra', 'gender']}>
                  <Select style={{ width: '100%' }} allowClear>
                    {[...new Array(3)].map((_item, index) => (
                      <Option key={'genders_' + index} value={`${index + 1}`}>
                        {t(`GENDERS.${index + 1}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.nationality_country_code`}
                  label={t('GLOBAL.BIRTH_COUNTRY')}
                  name={['profile_extra', 'nationality_country_code']}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={t('GLOBAL.BIRTH_COUNTRY')}
                    filterOption={(input, option) => {
                      const label = option!.label! as string;
                      return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    allowClear
                  >
                    {countriesTranslated?.map((key, i) => {
                      return (
                        <Option value={key[0]} key={key[0]} label={key[1]}>
                          <div className="demo-option-label-item">
                            <span role="img" aria-label={key[1]}>
                              {countriesEmojis[i]}{' '}
                            </span>
                            {key[1]}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.bank_account_iban`}
                  label={'IBAN'}
                  name={['profile_extra', 'bank_account_iban']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_extra.bank_account_bic`}
                  label={'BIC'}
                  name={['profile_extra', 'bank_account_bic']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <h4>{t('GLOBAL.ADDRESS')}</h4>
            <Row gutter={[20, 0]}>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_address.line_1`}
                  label={t('FORMS.ADDRESS_LINE', {
                    line: 1,
                  })}
                  name={['profile_address', 'line_1']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_address.line_2`}
                  label={t('FORMS.ADDRESS_LINE', {
                    line: 2,
                  })}
                  name={['profile_address', 'line_2']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item id={`profile_address.zipCode`} label={t('GLOBAL.ZIP')} name={['profile_address', 'zipCode']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item id={`profile_address.city`} label={t('GLOBAL.CITY')} name={['profile_address', 'city']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_address.state`}
                  label={t('FORMS.ADDRESS_STATE')}
                  name={['profile_address', 'state']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} style={{ width: '100%' }}>
                <Form.Item
                  id={`profile_address.country_code`}
                  label={t('GLOBAL.COUNTRY')}
                  name={['profile_address', 'country_code']}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={t('GLOBAL.COUNTRY')}
                    filterOption={(input, option) => {
                      const label = option!.label! as string;
                      return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    allowClear
                  >
                    {countriesTranslated?.map((key, i) => {
                      return (
                        <Option value={key[0]} key={key[0]} label={key[1]}>
                          <div className="demo-option-label-item">
                            <span role="img" aria-label={key[1]}>
                              {countriesEmojis[i]}{' '}
                            </span>
                            {key[1]}
                          </div>
                        </Option>
                      );
                    })}
                    {/* {Object.entries(countries).map((key) => {
                    const { name, emoji } = key[1];
                    return (
                      <Option value={key[0]} key={key[0]} label={name}>
                        <div className="demo-option-label-item">
                          <span role="img" aria-label={name}>
                            {emoji}{' '}
                          </span>
                          {name}
                        </div>
                      </Option>
                    );
                  })} */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {!!customFields?.length && (
              <React.Fragment>
                <br />
                <h4>{t('GLOBAL.CUSTOM_FIELDS')}</h4>
                <Row gutter={[20, 0]}>
                  {customFields?.map((field) => {
                    const { id, label, mandatory = false } = field;
                    return (
                      <Col lg={8} md={12} key={`custom_fields_${id}`} style={{ width: '100%' }}>
                        <Form.Item
                          id={`custom_fields.${id}`}
                          label={label}
                          name={['custom_fields', id!]}
                          rules={[{ required: mandatory, message: '', max: 255 }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
            )}
            <FormActions saving={saving} onReset={onReset} active={formHasChanged} />
          </Form>
          {!!user && (
            <ModalDeleteUserConfirmation
              visible={showModalDeleteUser}
              userId={user.recordId!}
              department={department}
              onClose={() => setShowModalDeleteUser(false)}
            />
          )}
          {!!user && (
            <ModalChangePassword
              visible={showChangePasswordModal}
              setVisible={setShowChangePasswordModal}
              user={user}
            />
          )}
        </Spin>
      </div>
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('USERS.USER.DASHBOARD.USER_VALIDATION_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default styled(ProfileTab)`
  .user-infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    .actions {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .action {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: ${colors.green};

        &.danger {
          color: ${colors.red};
          i {
            color: ${colors.red};
          }
        }

        i {
          border: 1px solid ${colors.blueLight};
          border-radius: 4px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${colors.green};
          margin-bottom: 6px;
          font-size: 24px;
        }

        span {
          font-size: 10px;
          line-height: 12px;
          font-weight: 500;
          max-width: 50px;
          font-weight: bold;
        }
      }
    }

    .user-form {
      width: 100%;
      padding: 0 20px 60px;

      .ant-form-item-label > label {
        color: ${colors.grey};
      }

      .ant-input.ant-input-disabled {
        color: ${colors.blue};
        background: none;
        border: none;
        padding: 0;
      }
    }

    .skeleton {
      text-align: center;
      width: 258px;
    }

    .skeleton-button {
      text-align: center;
      width: 150px;
    }
  }

  @media screen and (max-width: 900px) {
    .row {
      justify-content: center;
      flex-direction: column-reverse;
    }
  }
`;

interface IResponse {
  data: {
    profile: {
      name?: string;
      first_name: string;
      last_name: string;
      preferred_language_code: number;
      email: string;
      avatar?: string;
      phone: string;
    };
    profile_address: {
      line_1?: string;
      line_2?: string;
      zipCode?: string;
      city?: string;
      state?: string;
      country_code?: string; // ISO 3166 - alpha 2
    };
    profile_extra: {
      personal_email?: string;
      national_number?: string;
      birthdate?: string; // ISO 8601, UTC 0
      birthplace?: string;
      civil_state?: number;
      nationality_country_code?: string; // ISO 3166 - alpha 2
      gender?: string;
      bank_account_iban?: string;
      bank_account_bic?: string;
    };
    custom_fields: IField[];
  };
  meta: {
    civil_state: { id: string; name: string }[];
  };
  flags: {
    edit_email?: boolean;
    edit_password?: boolean;
  };
}
