import { useQuery } from '@tanstack/react-query';
import { getDailyTurnovers } from '../api/index';
import { V4DailyTurnoversResponse } from '../types';

export const useDailyTurnovers = (
  startDate: string,
  endDate: string,
): {
  dailyTurnovers?: V4DailyTurnoversResponse;
  loading: boolean;
} => {
  const { data: dailyTurnovers, isLoading: loading } = useQuery({
    queryKey: ['get-daily-turnovers'],
    queryFn: () => getDailyTurnovers(startDate, endDate),
  });

  return {
    loading,
    dailyTurnovers,
  };
};
