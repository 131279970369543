import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { ISection } from '@/types/section.model';
import { IShift } from '@/types/shift.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { Dropdown, Modal, Spin } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftAndContext, updateShiftsAndContextWithShiftResponse } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import MonthRightClickMenu from './MonthRightClickMenu';
import Shift from './Shift';
import Unaivailability from './Unaivailability';

interface Props {
  showComment?: boolean;
  className?: string;
  userRecordId: string | null;
  hideShifts?: boolean;
  days: {
    date: number;
    shifts: IShift[];
  }[];
  section?: ISection;
}

const Shifts: React.FC<Props> = ({ className, showComment, hideShifts, userRecordId, days, section }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, skills, userCategories, features },
  } = useContext(AppContext);
  const {
    shiftsByUsers,
    shiftsByUsersMapWithShiftId,
    picker,
    startDate,
    endDate,
    monthly_selected_elements,
    monthly_selecting_elements,
    selected_shifts_on_shift_key,
    loading_elements,
    loading,
    shiftsMap,
    clipboardHistory,
    opened_monthly_dropdown_identifier,
    monthly_start_point_element,
    monthly_selecting_direction,
    usersMap,
  } = useSelector(
    ({
      shiftsMap,
      shiftsByUsers,
      shiftsByUsersMapWithShiftId,
      picker,
      startDate,
      endDate,
      usersMap,
      monthly_selected_elements,
      monthly_selecting_elements,
      selected_shifts_on_shift_key,
      loading_elements,
      loading,
      clipboardHistory,
      opened_monthly_dropdown_identifier,
      monthly_start_point_element,
      monthly_selecting_direction,
    }: InitialStateType) => ({
      shiftsMap,
      shiftsByUsers,
      shiftsByUsersMapWithShiftId,
      picker,
      startDate,
      endDate,
      usersMap,
      monthly_selected_elements,
      monthly_selecting_elements,
      selected_shifts_on_shift_key,
      loading_elements,
      loading,
      clipboardHistory,
      opened_monthly_dropdown_identifier,
      monthly_start_point_element,
      monthly_selecting_direction,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const [startPointElement, setStartPointElement] = useState<Element | null>();
  const [firstTarget, setFirstTarget] = useState<string>('');

  const onDrop = (e: React.DragEvent, dayIndex: number) => {
    hoursDispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    (e.target as HTMLElement).style.opacity = '1';
    (e.target as HTMLElement).classList.remove('onDragOver');

    const shift_block_id: string = e.dataTransfer.getData('shift_block_id');
    if (shift_block_id) {
      const target_id = (e.target as Element).id;
      const userRecordId = target_id.split('_')[0];
      const date = target_id.split('_')[1];
      const section_id = target_id.split('_')[2];

      let data: any[] = [];
      const selected_ids: string[] = [];
      if (monthly_selected_elements.length > 0) {
        data = monthly_selected_elements.map((el) => {
          const target_id = (el as Element).id;
          selected_ids.push(target_id);
          const userRecordId = target_id.split('_')[0];
          const date = target_id.split('_')[1];

          return { userRecordId, date: +date / 1000 };
        });
        hoursDispatch({
          type: 'SET_LOADING_ELEMENTS',
          payload: selected_ids,
        });
      } else {
        hoursDispatch({
          type: 'SET_LOADING_ELEMENTS',
          payload: [target_id],
        });
        data = [{ userRecordId, date: +date / 1000 }];
      }

      const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/month`,
          {
            shift_block_id,
            data,
          },
          {
            params: {
              departmentId: activeDepartmentId,
              picker: 'month',
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              sectionId: isFeatureEnabled(features, FEATURES.SECTIONS)
                ? activeSection
                  ? activeSection
                  : undefined
                : undefined,
            },
            cancelToken: cancel_token_source.token,
          },
        )
        .then((res) => {
          if (res.data.result) {
            const recordIds = Object.keys(res.data.result);
            const data = Object.values(res.data.result);
            const errors: any[] = [];
            recordIds.forEach((id, index) => {
              const username = usersMap.get(id)?.displayName;
              const err = data[index];
              errors.push({ username, data: err });
            });
            if (errors.length > 0) {
              Modal.confirm({
                className: 'modal-constraints',
                title: t('SCHEDULE.CONSTRAINTS.TITLE'),
                icon: null,
                content: (
                  <ul>
                    {errors.map((error) => (
                      <li>
                        <div>{error.username}</div>
                        <ul>
                          {error.data.map((err: any) => (
                            <li>
                              {err.detail} : {t(`SCHEDULE.CONSTRAINTS.${err.type}`)}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                ),
                cancelText: t('GLOBAL.CANCEL'),
                okText: t('GLOBAL.OK'),
                closable: true,
              });
            }
          }
          updateShiftsAndContextWithShiftResponse(hoursDispatch, res, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          monthly_selected_elements.map((el: Element) => el.classList.remove('highlight'));
          hoursDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
          hoursDispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
          if (res.data.message.totalErrors > 0) {
            if ((Object.values(res.data.message.error).flat(1)[0] as any).message[0].title === 'WORKING') {
              hoursDispatch({
                type: 'SET_ERRORS',
                payload: [t('SCHEDULE.CONSTRAINTS.WORKING')],
              });
            }
          }
        })
        .catch((err) => {
          hoursDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
          hoursDispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
          if (err?.response?.data?.errors) {
            hoursDispatch({
              type: 'SET_ERRORS',
              payload: Object.values(err.response.data.errors).flat(1) as string[],
            });
          }
        });
    } else {
      const source = {
        shiftId: e.dataTransfer.getData('shiftId'),
        userRecordId: e.dataTransfer.getData('userRecordId') === 'null' ? null : e.dataTransfer.getData('userRecordId'),
        dayIndex: e.dataTransfer.getData('dayIndex'),
      };
      if (
        `${dayIndex}` === source.dayIndex &&
        (`${userRecordId}` === source.userRecordId || (!userRecordId && !source.userRecordId))
      ) {
        return;
      }
      const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(source.shiftId);

      if (!shiftsByUsersMapWithShiftIdResult) {
        return console.log(`Shift not found ${JSON.stringify(source)}`);
      }
      const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;
      const shift = shiftsByUsers[userIndex].shifts[shiftIndex];

      if (shift.start && shift.end) {
        const newStart = moment
          .unix(shift.start!)
          .subtract(+source.dayIndex - dayIndex, 'day')
          .unix();
        const newEnd = moment
          .unix(shift.end!)
          .subtract(+source.dayIndex - dayIndex, 'day')
          .unix();
        const oldValues: IShift = {
          id: source.shiftId,
          start: shift.start,
          end: shift.end,
        };
        const newValues: IShift = {
          id: source.shiftId,
          start: newStart,
          end: newEnd,
        };
        if (userRecordId !== shift.userRecordId) {
          oldValues.userRecordId = shift.userRecordId;
          newValues.userRecordId = userRecordId;
        }
        if (shift.tasks && shift.tasks.length) {
          oldValues.tasks = shift.tasks.map((task) => ({
            ...task,
          }));
          const difference = newStart - shift.start!;
          newValues.tasks = shift.tasks.map((task) => ({
            ...task,
            start: task.start! + difference,
            end: task.end! + difference,
          }));
        }
        updateShiftAndContext(hoursDispatch, oldValues, newValues, {
          picker,
          startDate,
          endDate: endDate.unix(),
          departmentId: activeDepartmentId,
          sectionId: activeSection,
          department: activeDepartment,
          activeSection,
          skills,
          userStatus: userCategories,
        });
      }
    }
  };

  const onDragEnter = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.add('onDragOver');
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: false,
    });
  };

  const onDragLeave = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.remove('onDragOver');
  };

  const onDragStart = (e: React.DragEvent, shiftId: string, dayIndex: number) => {
    (e.target as HTMLElement).style.opacity = '0.3';
    e.dataTransfer.setData('shiftId', `${shiftId}`);
    e.dataTransfer.setData('dayIndex', `${dayIndex}`);
    e.dataTransfer.setData('userRecordId', `${userRecordId}`);
    e.dataTransfer.dropEffect = 'move';
  };

  const onDragEnd = (e: React.DragEvent) => {
    (e.target as HTMLElement).style.opacity = '1';
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (!startPointElement) {
      setStartPointElement(e.currentTarget);
    }
    if (!monthly_start_point_element) {
      hoursDispatch({
        type: 'SET_MONTH_START_POINT_ELEMENT',
        payload: e.currentTarget.id,
      });
    }
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: true,
    });
    if (!e.shiftKey && e.button === 0) {
      if (monthly_selected_elements.length > 0 && !e.currentTarget.firstChild) {
        const section_id =
          isFeatureEnabled(features, FEATURES.SECTIONS) && monthly_selected_elements[0].id.split('_')[2];

        if (monthly_selected_elements.includes(e.target)) {
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: monthly_selected_elements.filter((el) => el !== e.target),
          });
        } else {
          if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
            if (e.currentTarget.id.split('_')[2] == section_id) {
              hoursDispatch({
                type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                payload: [...monthly_selected_elements, e.target],
              });
            }
          } else {
            hoursDispatch({
              type: 'SET_MONTHLY_SELECTED_ELEMENTS',
              payload: [...monthly_selected_elements, e.target],
            });
          }
        }
      } else {
        if (!e.currentTarget.firstChild) {
          if (monthly_selected_elements.includes(e.target)) {
            hoursDispatch({
              type: 'SET_MONTHLY_SELECTED_ELEMENTS',
              payload: [],
            });
          } else {
            hoursDispatch({
              type: 'SET_MONTHLY_SELECTED_ELEMENTS',
              payload: [e.target],
            });
          }
        }
      }
      if (e.button == 0) {
        if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
          if (
            monthly_selected_elements.length > 0 &&
            e.currentTarget.id.split('_')[2] == monthly_selected_elements[0].id.split('_')[2]
          ) {
            highlight(e, true);
          }
        } else {
          highlight(e, true);
        }
      }
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {};

  const onMouseEnter = (e: React.MouseEvent) => {
    if (monthly_selected_elements.length > 0) {
      const data = (monthly_selected_elements[0] as Element).id.split('_');
      const userRecordId = data[0];
      const date = data[1];

      if (monthly_start_point_element) {
        const start_userRecordId = monthly_start_point_element.split('_')[0];
        const start_date = monthly_start_point_element.split('_')[1];
        const target_userRecordId = e.currentTarget.id.split('_')[0];
        const target_date = e.currentTarget.id.split('_')[1];

        if (!isFeatureEnabled(features, FEATURES.SECTIONS)) {
          if (start_date == target_date && e.button == 0) {
            highlight(e);
          }
        }

        if (
          e.currentTarget.id.split('_')[0] ==
            monthly_selected_elements[
              isFeatureEnabled(features, FEATURES.SECTIONS) ? 0 : monthly_selected_elements.length - 1
            ].id.split('_')[0] &&
          e.currentTarget.id.split('_')[1] !=
            monthly_selected_elements[
              isFeatureEnabled(features, FEATURES.SECTIONS) ? 0 : monthly_selected_elements.length - 1
            ].id.split('_')[1]
        ) {
          if (e.button == 0) {
            highlight(e);
            if (!monthly_selected_elements.includes(e.target)) {
              hoursDispatch({
                type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                payload: [...monthly_selected_elements, e.target],
              });
            } else {
              hoursDispatch({
                type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                payload: monthly_selected_elements.filter((el) => el !== e.target),
              });
            }
          }
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_DIRECTION',
            payload: 'row',
          });
        } else if (
          e.currentTarget.id.split('_')[1] ==
            monthly_selected_elements[
              isFeatureEnabled(features, FEATURES.SECTIONS) ? 0 : monthly_selected_elements.length - 1
            ].id.split('_')[1] &&
          e.currentTarget.id.split('_')[0] !=
            monthly_selected_elements[
              isFeatureEnabled(features, FEATURES.SECTIONS) ? 0 : monthly_selected_elements.length - 1
            ].id.split('_')[0]
        ) {
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_DIRECTION',
            payload: 'column',
          });
        }

        const doc_user =
          isFeatureEnabled(features, FEATURES.SECTIONS) && section
            ? document.getElementById(`${start_userRecordId}_${target_date}_${section.id}`)
            : document.getElementById(`${start_userRecordId}_${target_date}`);
        const doc_date = isFeatureEnabled(features, FEATURES.SECTIONS)
          ? !section
            ? document.getElementById(`${target_userRecordId}_${start_date}_undefined`)
            : document.getElementById(`${target_userRecordId}_${start_date}_${section.id}`)
          : document.getElementById(`${target_userRecordId}_${start_date}`);

        if (!monthly_selecting_direction) {
          if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
            if (e.currentTarget.id.split('_')[2] == monthly_selected_elements[0].id.split('_')[2]) {
              if (!monthly_selected_elements.includes(e.target)) {
                hoursDispatch({
                  type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                  payload: [...monthly_selected_elements, e.target],
                });
              }
            }
          }
        }

        if (doc_user && monthly_selecting_direction == 'row' && e.button == 0) {
          const has_child = doc_user.firstChild;
          if (!has_child && e.button == 0) {
            if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
              if (monthly_selected_elements[0]?.id.split('_')[2] == e.currentTarget.id.split('_')[2]) {
                if (!monthly_selected_elements.includes(e.target)) {
                  hoursDispatch({
                    type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                    payload: [...monthly_selected_elements, e.target],
                  });
                }
              }
            } else {
              doc_user.classList.add('highlight');
              if (!monthly_selected_elements.includes(e.target)) {
                hoursDispatch({
                  type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                  payload: [...monthly_selected_elements, e.target],
                });
              }
            }
          }
        } else if (doc_date && monthly_selecting_direction == 'column' && e.button == 0) {
          const has_child = doc_date.firstChild;
          if (!has_child && e.button == 0) {
            if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
              if (monthly_selected_elements[0]?.id.split('_')[2] == e.currentTarget.id.split('_')[2]) {
                if (!monthly_selected_elements.includes(e.target)) {
                  doc_date.classList.add('highlight');
                  hoursDispatch({
                    type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                    payload: [...monthly_selected_elements, e.target],
                  });
                }
              }
            } else {
              doc_date.classList.add('highlight');
              if (!monthly_selected_elements.includes(e.target)) {
                hoursDispatch({
                  type: 'SET_MONTHLY_SELECTED_ELEMENTS',
                  payload: [...monthly_selected_elements, e.target],
                });
              }
            }
          }
        }
      }
    }
  };

  const onMouseUp = () => {
    hoursDispatch({
      type: 'SET_MONTH_START_POINT_ELEMENT',
      payload: null,
    });
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: false,
    });
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_DIRECTION',
      payload: null,
    });
    setStartPointElement(null);
    setFirstTarget('');
  };

  const highlight = (e: React.MouseEvent, is_start = false) => {
    const has_child = (e.target as HTMLElement).firstChild;
    const selecting = is_start ? true : monthly_selecting_elements;
    if (!has_child && selecting && e.button == 0) {
      (e.target as HTMLElement).classList.toggle('highlight');
    }
  };

  const closeMonthRightMenu = () => {
    hoursDispatch({
      type: 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER',
      payload: null,
    });
  };

  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  const [dayComments, setDayComments] = useState<any[]>([]);

  useEffect(() => {
    getDayComments();
  }, []);

  const getDayComments = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/schedule/day-comments`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setDayComments(data);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <div className={className} onMouseMove={onMouseMove}>
      {days.map((day, dayIndex) => {
        let identifier = `sgi_${userRecordId}_day_${dayIndex}`;
        const id = isFeatureEnabled(features, FEATURES.SECTIONS)
          ? `${userRecordId}_${day.date}_${section?.id}`
          : `${userRecordId}_${day.date}`;
        const is_loading_element = loading_elements?.includes(id);
        const today = moment(day.date).isSame(moment(), 'day');
        const is_weekend = moment(day.date).day() == 6 || moment(day.date).day() == 0;
        const comment = dayComments.find(
          (dayComment) => moment(dayComment.day).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'),
        );

        return (
          <Dropdown
            key={`dropdown_${identifier}`}
            getPopupContainer={(trigger) => {
              return trigger.parentNode?.parentNode as HTMLElement;
            }}
            overlay={() => (
              <MonthRightClickMenu
                close={() => {
                  hoursDispatch({
                    type: 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER',
                    payload: null,
                  });
                  setRightClickMenuVisible(false);
                }}
              />
            )}
            trigger={['contextMenu']}
            visible={opened_monthly_dropdown_identifier === id && rightClickMenuVisible}
            onVisibleChange={(e) => {
              hoursDispatch({
                type: 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER',
                payload: id,
              });
              setRightClickMenuVisible(e);
            }}
          >
            <div
              key={identifier}
              onDrop={(e) => onDrop(e, dayIndex)}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              onMouseDown={onMouseDown}
              onMouseEnter={onMouseEnter}
              onMouseUp={onMouseUp}
              id={id}
              className={`droppable-zone ${picker} ${is_weekend && 'weekend'} ${today && 'today'} ${
                monthly_selected_elements.find((el: HTMLElement) => el.id == id) && 'highlight'
              } ${comment ? 'comment' : ''}`}
            >
              {!hideShifts && (
                <>
                  {loading && is_loading_element && (
                    <div className="loading_element">
                      <Spin spinning={loading} size="small" style={{ paddingTop: 5 }} />
                    </div>
                  )}
                  {day.shifts.map((shift) => {
                    if (shift) {
                      identifier += `_si_${shift.id}`;
                      if (shift.shyftType === 3) {
                        return <Unaivailability shift={shift} key={identifier} />;
                      } else {
                        if (
                          isFeatureEnabled(features, FEATURES.SECTIONS) &&
                          activeDepartment?.scheduleParams?.monthly_group_by_sections &&
                          !activeSection
                        ) {
                          if (shift.section?.id === section?.id) {
                            return (
                              <Shift
                                showComment={showComment}
                                shift={shift}
                                key={identifier}
                                onDragStartHandler={(e: any) => onDragStart(e, shift.id!, dayIndex)}
                                onDragEndHandler={onDragEnd}
                                activeSection={activeSection}
                                activeDepartment={activeDepartment}
                                closeMonthRightMenu={closeMonthRightMenu}
                              />
                            );
                          }
                        } else {
                          return (
                            <Shift
                              showComment={showComment}
                              shift={shift}
                              key={identifier}
                              onDragStartHandler={(e: any) => onDragStart(e, shift.id!, dayIndex)}
                              onDragEndHandler={onDragEnd}
                              activeDepartment={activeDepartment}
                              closeMonthRightMenu={closeMonthRightMenu}
                            />
                          );
                        }
                      }
                    }
                    return null;
                  })}
                </>
              )}
            </div>
          </Dropdown>
        );
      })}
    </div>
  );
};

const ShiftsStyled = styled(React.memo(Shifts))`
  height: 100%;
  flex: 1;
  display: flex;
  text-align: center;
  border-bottom: 1px solid ${Colors.blueLight};
  width: auto;
  @media print {
    align-items: stretch !important;
  }

  .loading_element {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .today {
    background-color: #f8e5d6;

    &.comment {
      background-color: #00a65117;
    }
  }

  .weekend {
    background-color: #efefef;
  }

  > .droppable-zone {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;

    &.highlight {
      background: lightgray;
    }

    &.counter-column {
      display: inline-block;
      text-align: right;

      .tag {
        border-radius: 3px;
        font-size: 10px;
        padding: 3px 5px;
      }

      .tag:nth-of-type(n + 2) {
        margin-left: 5px;
      }
    }

    @media print {
      height: 100%;
      padding: 2px 4px;
    }

    &.month {
      flex: 1;
    }

    &.onDragOver  {
      background: rgba(196, 199, 208, 0.2);
      * {
        pointer-events: none;
      }
    }

    &:nth-child(n + 2) {
      border-left: 1px solid ${Colors.blueLight};
    }

    > .shift-counter {
      background: rgba(0, 0, 0, 0.1);
      padding: 0px 10px;
      border-radius: 5px;
    }
  }
`;

export default ShiftsStyled;
