import { client } from '@/ReactQueryProvider';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { useMutation } from '@tanstack/react-query';
import { Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import { updateDocumentSettings } from '../api';
import { useDocumentSettings } from '../hooks/use-document-settings';
import { UpdateDocumentSettings } from '../types/documents.types';

interface Props {
  className?: string;
}

const DocumentSettings: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { documentSettings, loading } = useDocumentSettings();

  const { mutateAsync: updateDocumentSettingsMutation, isLoading } = useMutation({
    mutationKey: ['update-document-settings'],
    mutationFn: (payload: UpdateDocumentSettings) => updateDocumentSettings(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-document-settings'] });
      setFormHasChanged(false);
    },
  });

  const [form] = useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [documentSettings]);

  const elements = [
    {
      title: t('general.general'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('settings.documents.settings.enable-auto-contracts.title'),
          description: t('settings.documents.settings.enable-auto-contracts.description'),
          name: ['general', 'automaticContracts'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={documentSettings?.general.automaticContracts} />,
          show: true,
        },
        {
          label: t('settings.documents.settings.group-document-signatures.title'),
          description: t('settings.documents.settings.group-document-signatures.description'),
          name: ['general', 'groupDocumentSignatures'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={documentSettings?.general.groupDocumentSignatures} />,
          show: true,
          newBadge: '2025-04-30',
        },
        {
          label: t('settings.documents.settings.auto-approve-documents.title'),
          description: t('settings.documents.settings.auto-approve-documents.description'),
          name: ['general', 'autoApproveDocuments'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={documentSettings?.general.autoApproveDocuments} />,
          show: true,
          newBadge: '2025-04-30',
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue(documentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = async (values: any) => {
    await updateDocumentSettingsMutation(values);
  };

  return (
    <div className={className}>
      <h2>{t('settings.documents.settings.title')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading || isLoading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(DocumentSettings)``;
