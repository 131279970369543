import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  document: V4Document;
  userRecordId: string;
}

const DownloadUserDocument: React.FC<Props> = ({ className, document, userRecordId }) => {
  const { t } = useTranslation();

  const onDownloadDocument = () => {
    if (!document.userDocuments.find((userDocument) => userDocument.employee.recordId == userRecordId)) return;
    window.open(document.file, '_blank');
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.download-document')}>
        <Button type="ghost" className="action" onClick={onDownloadDocument}>
          <i className="icon-download" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DownloadUserDocument)``;
