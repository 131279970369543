import { Modal, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDocumentDetails } from '../hooks/use-document-details';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  visible: boolean;
  document: V4Document;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDocumentDetails: React.FC<Props> = ({ className, visible, document, setVisible }) => {
  const { t } = useTranslation();

  const { columns } = useDocumentDetails();

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      width="50%"
      visible={visible}
      className={className}
      title={document.title}
      onOk={onCancel}
      onCancel={onCancel}
    >
      <Table columns={columns} dataSource={document.userDocuments} pagination={false} />
    </Modal>
  );
};

export default styled(ModalDocumentDetails)``;
