import React, { FC, useState } from 'react';
import colors from '@/styles/colors';
import styled from 'styled-components';
import { RcFile } from 'antd/lib/upload';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { UploadTemplatePayload } from '../types';
import { useTurnoverTemplateUpload } from '../hooks';
import { Button, Form, Input, Modal, Upload } from 'antd';

type UploadTemplateModalProps = {
  isVisible: boolean;
  onVisibilityChange: (newVisibility: boolean) => void;
  className?: string;
};

const UploadTemplateModal: FC<UploadTemplateModalProps> = ({ className, isVisible, onVisibilityChange }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { uploadTemplate, isLoading } = useTurnoverTemplateUpload(() => onVisibilityChange(false));
  const [file, setFile] = useState<RcFile | null>(null);

  const onFinish = (values: UploadTemplatePayload) => {
    if(!file) {
      return;
    }

    uploadTemplate({ ...values, file });
  };

  const onDownloadExample = () => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_APP_URL}/turnover-templates-example.xlsx`;
    link.download = 'turnover-templates-example.xlsx';
    link.click();
  };

  return (
    <Modal
      className={className}
      visible={isVisible}
      maskClosable
      title={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.IMPORT_FROM_FILE')}
      okText={t('GLOBAL.CREATE')}
      confirmLoading={isLoading}
      onOk={() => form.submit()}
      cancelButtonProps={{ danger: true }}
      onCancel={() => {
        form.resetFields();
        onVisibilityChange(false);
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
        </Form.Item>
        <Form.Item name="file" label={t('GLOBAL.FILE')} rules={[{ required: true }]}>
          <Upload.Dragger
            maxCount={1}
            multiple={false}
            onRemove={() => setFile(null)}
            beforeUpload={(file: RcFile) => {
              setFile(file);
              return false;
            }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <p className="ant-upload-text">{t('UPLOAD.DRAGGER.TEXT')}</p>
          </Upload.Dragger>
        </Form.Item>
        <Button
          block
          type="primary"
          size="large"
          onClick={onDownloadExample}
          style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
        >
          {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.DOWNLOAD_EXAMPLE_FILE')}
        </Button>
      </Form>
    </Modal>
  );
};

export const UploadTemplateModalStyled = styled(UploadTemplateModal)``;
