import api from '@/api';
import { V4Contract, V4ManageContractPayload } from '@/pages/app/documents/contracts/types/contracts.types';
import { V4DocumentVariables } from '../../types/index.types';

export type GetContractsParams = {
  userRecordId?: string;
  q?: string;
  active?: boolean;
  signed?: boolean;
  sort_by?: 'id' | 'first_name' | 'starts_at' | 'ends_at' | 'sent_at' | 'sign_date' | 'date_created';
  sort_direction?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
};

export type GetContractsResponse = {
  data: V4Contract[];
  meta: {
    total: number;
    total_pages: number;
  };
};

export const getContracts = async (params: GetContractsParams) => {
  const queryParams = new URLSearchParams();

  if (params.userRecordId) queryParams.append('userRecordId', params.userRecordId);
  if (params.q) queryParams.append('q', params.q);
  if (params.active !== undefined) queryParams.append('active', params.active ? '1' : '0');
  if (params.signed !== undefined) queryParams.append('signed', params.signed ? '1' : '0');
  if (params.sort_by) queryParams.append('sort_by', params.sort_by);
  if (params.sort_direction) queryParams.append('sort_direction', params.sort_direction);
  if (params.page) queryParams.append('page', String(params.page));
  if (params.per_page) queryParams.append('per_page', String(params.per_page));

  if (typeof window !== 'undefined') {
    window.history.replaceState(null, '', `?${queryParams.toString()}`);
  }

  const { response } = await api<GetContractsResponse>(`documents/contracts?${queryParams.toString()}`);
  return response;
};

export const getContract = async (contractId: string) => {
  const { response } = await api<{ data: V4Contract }>(`documents/contracts/${contractId}`);
  return response.data;
};

export const sendContract = async (contractId: string) => {
  const { response } = await api(`documents/contracts/${contractId}/send`, { method: 'POST' });
  return response;
};

export const duplicateContract = async (documentId: string) => {
  const { response } = await api(`documents/contract/duplicate`, {
    method: 'POST',
    body: JSON.stringify({ documentId }),
  });
  return response;
};

export const createContract = async (payload: V4ManageContractPayload) => {
  const { response } = await api(`documents/contracts`, { method: 'POST', body: JSON.stringify(payload) });
  return response;
};

export const editContract = async (contractId: string, payload: V4ManageContractPayload) => {
  const { response } = await api(`documents/contracts/${contractId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
  return response;
};

export const deleteContract = async (contractId: string) => {
  const { response } = await api(`documents/contracts/${contractId}`, { method: 'DELETE' });
  return response;
};

export const getContractVariables = async () => {
  const { response } = await api<{ data: V4DocumentVariables }>('documents/contract/variables');
  return response.data;
};
