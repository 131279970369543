import React from 'react';
import styled from 'styled-components';
import { V4DocumentTemplate } from '../types/document-templates.types';
import DeleteDocumentTemplate from './delete-document-template';
import DownloadDocumentTemplate from './download-document-template';
import EditDocumentTemplate from './edit-document-template';

interface Props {
  className?: string;
  documentTemplate: V4DocumentTemplate;
}

const DocumentTemplateActions: React.FC<Props> = ({ className, documentTemplate }) => {
  return (
    <div className={className}>
      <EditDocumentTemplate documentTemplateId={documentTemplate.id} />
      <DownloadDocumentTemplate file={documentTemplate.file} />
      <DeleteDocumentTemplate documentTemplate={documentTemplate} />
    </div>
  );
};

export default styled(DocumentTemplateActions)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
