import { useQuery } from '@tanstack/react-query';
import { getContractVariables } from '../api';

export const useContractVariables = () => {
  const { data: contractVariables, isLoading: loading } = useQuery({
    queryKey: ['get-contract-variables'],
    queryFn: getContractVariables,
    initialData: null,
  });

  return {
    contractVariables,
    loading,
  };
};
