import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { capitalize, isDescendant, isFeatureEnabled, lioWeekdays } from '@/utils';
import { Affix, Button } from 'antd';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import AddShift from './AddShift';
import DayRightClick from './DayRightClick';
import SubHeaderSearch from './SubHeaderSearch';
import { Birthdays, Comment, Weather } from './header-extra-info';
import { useBirthdays, useScheduleComments, useWeather } from '../hooks';

interface Props {
  className?: string;
  refSubHeader: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  shiftNotApprovedIds: string[];
  shiftsToApprove: string[];
}

const SubHeader: React.FC<Props> = ({
  className,
  refSubHeader,
  refPageContainer,
  shiftNotApprovedIds,
  shiftsToApprove,
}) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const { picker, days, hours, selectedDate, taskScheduler, showWeather, showScheduleComments, showBirthday } = useSelector(
    ({
      picker,
      days,
      hours,
      filterOptions,
      selectedDate,
      taskScheduler,
      active_weekly_view,
      showWeather,
      showScheduleComments,
      showBirthday,
    }: InitialStateType) => ({
      picker,
      selectedDate,
      days,
      hours,
      filterOptions,
      taskScheduler,
      active_weekly_view,
      showWeather,
      showScheduleComments,
      showBirthday,
    }),
    shallowEqual,
  );

  const selectedDateRef = useRef<moment.Moment>(selectedDate);
  selectedDateRef.current = selectedDate;
  
  const beginningWeek = selectedDate.clone().startOf('week');
  const endWeek = selectedDate.clone().endOf('week');

  const { weather } = useWeather();
  const { birthdays } = useBirthdays(beginningWeek.format('YYYY-MM-DD'), endWeek.format('YYYY-MM-DD'));
  const { comments } = useScheduleComments(beginningWeek.format('YYYY-MM-DD'), endWeek.format('YYYY-MM-DD'));

  const history = useHistory();
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { state: appContextState } = useContext(AppContext);
  const { activeSection, activeDepartment, tasks, features } = appContextState;
  const [affixed, setAffixed] = useState(false);
  const [columnWidth, setColumnWidth] = useState<number>(0);
  const { pathname } = useLocation();
  const month_view = pathname.startsWith('/app/hours/manage/month');

  moment.tz.setDefault('Atlantic/Reykjavik');
  moment.locale(i18n.language, {
    week: {
      dow: 1,
    },
  });

  const keyDownHandler = (e: any) => {
    const { keyCode } = e;
    if ([37, 39].includes(keyCode)) {
      if (
        !isDescendant(
          document.getElementsByClassName('app-hours-container')[0] as HTMLElement,
          document.activeElement as HTMLElement,
        )
      ) {
        onArrowClick(keyCode === 37 ? -1 : 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const resizeHandler = () => {
    if (window.innerWidth > 1024) {
      const width = (window.innerWidth - 240) / hours?.length || 1;
      setColumnWidth(width);
    } else {
      setColumnWidth((1024 - 240) / hours?.length || 1);
    }
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [hours?.length]);

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      if (element) {
        element.dispatchEvent(new Event('scroll'));
      }
    });
  };

  const onArrowClick = (value: number) => {
    const date = moment(selectedDateRef.current).add(value, picker);

    if (picker === 'day' && !(activeDepartment?.scheduleParams as any)?.[lioWeekdays[date.weekday()]]) {
      if (value > 0) {
        onArrowClick(value + 1);
      } else {
        onArrowClick(value - 1);
      }
      return;
    }
    const formattedDate = date.format('YYYY-MM-DD');
    history.push(`/app/hours/manage/${picker}/${formattedDate}`);
  };

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
    >
      <div ref={refSubHeader} className={`${className} ${picker} ${affixed ? 'affixed' : ''}`}>
        <div className="left">
          {!affixed && <SubHeaderSearch />}
          {affixed && (
            <div className="subheader-shortcuts">
              <div className="left">
                <button onClick={() => onArrowClick(-1)}>
                  <i className="icon-angle-left" />
                </button>
                <button onClick={() => onArrowClick(1)}>
                  <i className="icon-angle-right" />
                </button>
              </div>
              <div className="right">
                {picker === 'day' && !!isFeatureEnabled(features, FEATURES.TASKS) && !!tasks?.length && (
                  <Button
                    size="small"
                    shape="circle"
                    type={taskScheduler ? 'primary' : 'default'}
                    style={{ borderRadius: 20, marginLeft: 10 }}
                    onClick={() => {
                      const container = document.getElementsByClassName('app-hours-container')?.[0];
                      if (container) {
                        (container as HTMLElement).style.maxHeight = !taskScheduler
                          ? 'calc(100vh - 80px - 70px)'
                          : 'calc(100vh - 80px)';
                      }
                      hoursDispatch({
                        type: 'SET_TASK_SCHEDULER',
                        payload: !taskScheduler,
                      });
                    }}
                  >
                    T
                  </Button>
                )}
                <button
                  style={{ backgroundColor: colors.blue, color: 'white' }}
                  onClick={() => {
                    history.push(moment().format('YYYY-MM-DD'));
                  }}
                >
                  <i className="icon-calendar-alt" />
                </button>
                {shiftNotApprovedIds.length > 0 && (
                  <button
                    style={{ backgroundColor: colors.green, color: 'white' }}
                    onClick={() => hoursDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: [...shiftsToApprove] })}
                  >
                    <i className="icon-ok-circled" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="right">
          {picker !== 'day' &&
            days.map((day, dayIndex) => {
              const startHour = activeDepartment?.scheduleParams?.default_shift_start_time || 0;
              const endHour = activeDepartment?.scheduleParams?.default_shift_end_time || 0;
              const { date } = day;
              const shiftDate = moment.unix(date).set({ hours: startHour });
              const start = shiftDate.unix();
              const end = shiftDate.set({ hours: endHour }).unix();
              const today = moment.unix(day.date).isSame(moment(), 'day');
              const holiday = !!day?.publicHoliday;

              const dayComment = comments?.find((c) => c.date === day.date_string);
              const dayWeather = weather?.find((w) => moment(w.date).format('YYYY-MM-DD') === day.date_string);
              const dayBirthdays = birthdays?.filter((b) => b.employee.birthday === day.date_string);

              return (
                <DayRightClick key={day.date} dayIndex={dayIndex}>
                  <div
                    className={`period ${picker} ${holiday ? 'holiday' : ''} ${today ? 'today' : ''} ${
                      dayComment ? 'comment' : ''
                    }`}
                    style={
                      !!activeDepartment?.scheduleParams?.sidebarCounters && dayIndex >= days.length - 1
                        ? { borderTopRightRadius: 10, borderRight: `1px solid ${colors.greyLight}` }
                        : undefined
                    }
                  >
                    <div className="date">
                      {capitalize(shiftDate.format('ddd DD')).replace('.', '')}
                      {holiday && <span className="holiday">{day.publicHoliday}</span>}
                    </div>

                    <AddShift
                      style={{ zIndex: 2 }}
                      className={'add-shift'}
                      size="large"
                      shift={{
                        start,
                        end,
                        section: activeSection ? { id: activeSection } : undefined,
                      }}
                    />
                    <div className={'icon-group-wrapper'}>
                      {showScheduleComments && <Comment date={day.date_string} comment={dayComment} />}
                      {showBirthday && <Birthdays birthdays={dayBirthdays} />}
                      {showWeather && <Weather weather={dayWeather} />}
                    </div>
                  </div>
                </DayRightClick>
              );
            })}
          {picker === 'day' &&
            hours.map((hour) => {
              const { date } = hour;
              const outOfBounds = !moment.unix(date).isSame(selectedDate, 'day');
              return (
                <div
                  className={`period ${picker} ${outOfBounds ? 'out-of-bounds' : ''}`}
                  key={date}
                  style={{ width: columnWidth + 'px' }}
                >
                  <div className="date">{moment.unix(hour.date).format('HH[h]')}</div>
                  <AddShift
                    style={{ zIndex: 2 }}
                    size="small"
                    className={'add-shift'}
                    shift={{ start: hour.date, section: activeSection ? { id: activeSection } : undefined }}
                  />
                </div>
              );
            })}
          {!month_view && !!activeDepartment?.scheduleParams?.sidebarCounters && picker !== 'day' && (
            <div className={`period ${picker} counter-column`} />
          )}
        </div>
      </div>
    </Affix>
  );
};

const SubHeaderStyled = styled(React.memo(SubHeader))`
  display: flex;
  position: relative;
  height: 45px;
  min-height: 45px;
  z-index: 11;

  &.month {
    width: fit-content;

    > div.right > div {
      width: 180px;
    }

    &.affixed {
      max-width: 100%;
      overflow: hidden;
    }
  }

  &.date {
    @media (max-width: 1440px) {
      width: fit-content;
    }

    &.affixed {
      max-width: 100%;
      overflow: hidden;
    }
  }

  &.affixed {
    height: 45px;
    z-index: 11;
  }

  @media screen and (max-width: 900px) {
    > div {
      &.left {
        width: 150px !important;
      }
    }
  }

  > div {
    &.left {
      background: #f7f9ff;
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-top: 1px solid ${Colors.blueLight};
      border-right: 1px solid ${Colors.blueLight};
      width: 210px;
      padding: 10px;
      z-index: 3;
      border-bottom: 1px solid ${Colors.blueLight};

      @media print {
        font-size-adjust: 0.5;
      }

      .search-col  {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .ant-select-multiple {
          white-space: nowrap;
          overflow: auto;
          height: 30px;
          width: 100%;
        }
      }

      .subheader-shortcuts {
        display: flex;
        justify-content: space-between;

        .left {
          button {
            background-color: white;
            box-shadow: 0 0 10px rgb(0 0 0 / 10%);
            margin-right: 5px;
          }
        }

        .right {
          button {
            margin-left: 5px;
          }
        }

        button {
          border-radius: 2px;
          padding: 2px 5px;
        }
      }
    }

    &.right {
      display: flex;
      flex-grow: 1;

      > .period {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;
        position: relative;
        border-top: 1px solid ${Colors.blueLight};
        background: #f7f9ff;
        border-right: 1px solid ${Colors.blueLight};
        border-bottom: 1px solid ${Colors.blueLight};

        &:hover {
          .date {
            display: none;  
          }
          .add-shift {
              opacity: 1;
              display: flex;
              button {
                position: relative;
              }
          }
        }

        &.counter-column {
          background-color: white;
          border: none;
          border-bottom: 1px solid ${Colors.blueLight};
        }

        &.comment {
          background-color: #00a65117;
        }

        &.today {
          background-color: ${colors.blueLight};

          &.comment {
            background-color: #00a65126;
          }
        }
        
        &.week {
          padding: 5px 10px;
          flex-direction: column;
        }

        .holiday {
          color: grey;
          font-style: italic;
          display: block;
        }

        &.date {
          &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 8px;
            background-color: ${Colors.blueLight};
            bottom: 0px;
          }
        }

        .date {
          font-weight: bold;
          text-align: center;
          width: 100%;
          z-index: 0;
          font-size: 13px;
          min-height: 10px;
        }
        
        .icon-group-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &.out-of-bounds {
          background: ${Colors.greyLight} !important;
        }
      }
    }
  }
`;

export default SubHeaderStyled;
