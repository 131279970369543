import ModalProvisional from '@/pages/app/components/ModalProvisional';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import {Affix, Tooltip} from 'antd';
import moment, { Moment } from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useScheduleTurnover } from '../../hooks';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
  offsetTop: number;
  department: IDepartment | null;
  startDate: Moment;
  totalBudget: number | null;
  totalBudgets: (number | null)[];
  columnWidth: number;
  refPageContainer: React.MutableRefObject<null> | null;
  turnover: any;
  selectedDate: Moment;
  hours: IScheduleHour[];
}

const Insights: React.FC<Props> = ({
  className,
  offsetTop,
  department,
  startDate,
  totalBudget,
  totalBudgets,
  columnWidth,
  refPageContainer,
  turnover,
  hours,
  selectedDate,
}) => {
  const [affixed, setAffixed] = useState(false);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeSection, activeDepartment, features },
  } = useContext(AppContext);

  const { scheduleTurnover } = useScheduleTurnover(
    selectedDate.format('Y-MM-DD'),
    selectedDate.format('Y-MM-DD'),
    activeSection,
  );

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      element.dispatchEvent(new Event('scroll'));
    });
  };

  const sectionsAllowed =
    isFeatureEnabled(features, FEATURES.SECTIONS) && activeDepartment?.scheduleParams?.productivity_by_section_enabled;

  const turnoverForDay =
    turnover &&
    turnover[startDate.startOf('day').unix()] &&
    (turnover[startDate.startOf('day').unix()].value || turnover[startDate.startOf('day').unix()].provisional);

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
      offsetTop={offsetTop}
    >
      <div>
        <div className={`${className} ${affixed ? 'affixed' : ''}`}>
          <div className="left">
            <div className={'turnover-wrapper'}>
              <span className={'turnover'}>
                {turnoverForDay
                  ? new Intl.NumberFormat(language, {
                      style: 'currency',
                      currency: department?.currency || 'EUR',
                      maximumFractionDigits: 0,
                    }).format(turnover[startDate.startOf('day').unix()].value)
                  : t('SCHEDULE.NO_TURNOVER')}
              </span>
              {scheduleTurnover && (
                <span className={'template-name'}>{scheduleTurnover[0]?.turnoverQuotaTemplateName || ''}</span>
              )}
            </div>
            {!(sectionsAllowed && !activeSection) && (
              <button
                className={'add-insight-button'}
                onClick={() => setTimestamp(moment(startDate).startOf('day').unix())}
              >
                +
              </button>
            )}
          </div>
          <div className={`right`}>
            {scheduleTurnover &&
              turnover &&
              hours.map((hour, index) => {
                if (!scheduleTurnover || scheduleTurnover.length === 0) {
                  return <div key={`budget_${index}`} style={{ width: columnWidth }} />;
                }

                const hours = moment.unix(hour.date).hours();
                const turnoverTemplate = scheduleTurnover[0].details.find((quota) => quota.hour === hours);

                if (!turnoverTemplate) {
                  return <div key={`budget_${index}`} style={{ width: columnWidth }} />;
                }

                let date = moment.unix(hour.date).startOf('day').unix();
                if (moment.unix(hour.date).day() != startDate.day()) {
                  date = startDate.startOf('day').unix();
                }

                const turnoverForDay = turnover[date];
                if (!turnoverForDay) {
                  return <div key={`budget_${index}`} style={{ width: columnWidth }} />;
                }

                return (
                  <div
                    key={`budget_${index}`}
                    style={{ width: columnWidth, fontSize: 10 }}
                    className={`day-insights hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                  >
                    {turnoverForDay.value || turnoverForDay.provisional
                      ? new Intl.NumberFormat(language, {
                          style: 'currency',
                          currency: department?.currency || 'EUR',
                        })
                          .format(Number((turnoverForDay.value * turnoverTemplate.value) / 100))
                          .replace(/\D00(?=\D*$)/, '')
                          .replace(/\s/g, '')
                      : '-'}
                  </div>
                );
              })}
            {/* {totalBudgets?.map((budget, index) => (
              <div key={`budget_${index}`} style={{ width: columnWidth }}>
                {budget &&
                  new Intl.NumberFormat(language, { style: 'currency', currency: department?.currency || 'EUR' })
                    .format(budget)
                    .replace(/\D00(?=\D*$)/, '')}
              </div>
            ))} */}
          </div>
        </div>
        {department?.role === 'ADMIN' && (
          <ModalProvisional
            sectionId={activeSection}
            timestamp={timestamp}
            onClose={() => setTimestamp(null)}
            onSave={() => location.reload()}
            value={turnover?.[startDate.startOf('day').unix()]?.value}
            provisional={turnover?.[startDate.startOf('day').unix()]?.provisional}
          />
        )}
      </div>
    </Affix>
  );
};

const InsightsStyled = styled(Insights)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colors.blueLight};
  background-color: #ffe9d0;
  width: 100%;

  @media (max-width: 1440px) {
    min-width: 100%;
  }

  &.affixed {
    max-width: 100%;
    overflow: hidden;
  }

  > div {
    &.left {
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-right: 1px solid ${colors.blueLight};
      width: 210px;
      background-color: #ffe9d0;
      padding: 3px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 10px;
        color: ${colors.red};
        font-weight: 800;
        background-color: #ffcf97;
        border-radius: 3px;
      }
      
      .turnover-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      
      .turnover {
        font-size: 10px;
      }
      
      .add-insight-button {
        height: 25px;
        width: 25px;
      }
      
      .template-name{
        display: flex;
        font-size: 10px;
        color: #7f8894;
      }
    }

    &.right {
      display: flex;
      flex-grow: 1;

      > div {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        flex-shrink: 0;

        &:nth-child(n + 2) {
          border-left: 1px solid ${colors.blueLight};
        }
      }
    }
  }
`;

export default InsightsStyled;
