import { useCountries } from '@/hooks/use-countries';
import PlaceAutocomplete from '@/pages/app/components/PlaceAutocomplete';
import { client } from '@/ReactQueryProvider';
import { IAddress } from '@/types/address.model';
import { handleError } from '@/utils';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, DatePicker, Form, Input, InputNumber, message, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createAddress, deleteAddress, editAddress } from '../api';
import { V4GetProfileResponse, V4ProfileAddress } from '../types/profile.types';

interface Props {
  className?: string;
  profile: V4GetProfileResponse;
  form: FormInstance<any>;
}

const Addresses: React.FC<Props> = ({ className, profile, form }) => {
  const { t } = useTranslation();

  const { countries } = useCountries();

  const [allAddresses, setAllAddresses] = useState<V4ProfileAddress[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    if (!profile) return;
    setAllAddresses(profile.data.addresses);
    setActiveKey(profile.data.addresses.length > 0 ? [profile.data.addresses[0].id] : []);
  }, [profile, form]);

  const onAddressChange = (address: IAddress, i: number) => {
    if (!address.street) return;

    form.setFieldsValue({
      addresses: {
        [i]: {
          id: form.getFieldValue(['addresses', i, 'id']),
          validFrom: form.getFieldValue(['addresses', i, 'validFrom']),
          line1: address.street,
          houseNumber: address.streetNumber,
          zipCode: address.zip,
          city: address.city,
          state: address.city,
          countryCode: address.country,
        },
      },
    });
  };

  const onAddAddress = () => {
    const newAddress = {
      id: `new-${Date.now()}`,
      validFrom: moment().format('YYYY-MM-DD'),
      line1: null,
      houseNumber: null,
      postBox: null,
      zipCode: null,
      city: null,
      state: null,
      countryCode: null,
    };

    setAllAddresses((prev) => [...prev, newAddress] as any);

    form.setFieldsValue({
      addresses: [
        ...(allAddresses || []).map((addess) => ({
          ...addess,
          validFrom: moment(newAddress.validFrom),
        })),
        {
          ...newAddress,
          validFrom: moment(newAddress.validFrom),
        },
      ],
    });

    setActiveKey([newAddress.id]);
  };

  const onSaveAddress = async (addressId: string) => {
    if (!profile) return;

    const { addresses } = form.getFieldsValue();
    const address = addresses.filter((address: any) => !!address).find((address: any) => address.id == addressId);
    if (!address) return;

    try {
      const res: any = await createAddress(profile.data.id, {
        ...address,
        validFrom: moment(address.validFrom).format('YYYY-MM-DD'),
      });
      setAllAddresses((prev) => prev.map((address) => (address.id !== res.data.date ? address : res.data.data)));
      message.success(t('users.profile.addresses.address-created'));
    } catch (error) {
      handleError(error);
    }

    client.invalidateQueries({ queryKey: ['get-profile', profile.data.id] });
  };

  const onEditAddress = async (addressId: string) => {
    if (!profile) return;

    const { addresses } = form.getFieldsValue();
    const address = addresses.filter((address: any) => !!address).find((address: any) => address.id == addressId);
    if (!address) return;

    try {
      await editAddress(profile.data.id, addressId, {
        ...address,
        validFrom: moment(address.validFrom).format('YYYY-MM-DD'),
      });
      message.success(t('users.profile.addresses.address-edited'));
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteAddress = async (e: React.MouseEvent<HTMLElement, MouseEvent>, addressId: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (!profile) return;

    const { addresses } = form.getFieldsValue();
    const address = addresses.find((address: any) => address.id == addressId);
    if (!address) return;

    if (address.id.startsWith('new-')) {
      setAllAddresses((prev) => prev.filter((address) => address.id !== addressId));
      return;
    }

    try {
      await deleteAddress(profile.data.id, addressId);
      message.success(t('users.profile.addresses.address-deleted'));
    } catch (error) {
      handleError(error);
    }

    client.invalidateQueries({ queryKey: ['get-profile', profile.data.id] });
  };

  return (
    <div className={className}>
      <h4>{t('general.address.addresses')}</h4>
      <div className="content">
        {allAddresses.length > 0 && (
          <Collapse
            accordion
            activeKey={activeKey}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            onChange={(key) => setActiveKey(key as string[])}
          >
            {allAddresses.map((address, i) => {
              const expired = address.validUntil ? moment(address.validUntil).isBefore(moment()) : false;
              const newAddress = address.id.startsWith('new-');

              return (
                <Collapse.Panel
                  header={`${address && address.line1 ? `${address.line1} -` : ''} ${
                    address.validUntil
                      ? t('general.from-date-to-date', { start: address.validFrom, end: address.validUntil })
                      : address.validFrom
                      ? t('general.since-date', { date: address.validFrom })
                      : t('general.address.new-address')
                  }`}
                  key={address.id}
                  id={address.id}
                  className="site-collapse-custom-panel"
                  extra={
                    address.external && !address.external.canDelete ? undefined : (
                      <Button type="primary" danger onClick={(e) => onDeleteAddress(e, address.id)}>
                        <i className="icon-trash-empty" />
                      </Button>
                    )
                  }
                >
                  <Form.Item name={['addresses', i, 'id']} hidden />
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.valid-from')} name={['addresses', i, 'validFrom']}>
                        <DatePicker size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.street')} name={['addresses', i, 'line1']}>
                        <PlaceAutocomplete
                          size="large"
                          onAddressChange={(address) => onAddressChange(address, i)}
                          disabled={expired}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.house-number')} name={['addresses', i, 'houseNumber']}>
                        <Input size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.box')} name={['addresses', i, 'postBox']}>
                        <Input size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.zip')} name={['addresses', i, 'zipCode']}>
                        <Input size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.city')} name={['addresses', i, 'city']}>
                        <Input size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.state')} name={['addresses', i, 'state']}>
                        <Input size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.address.country')} name={['addresses', i, 'countryCode']}>
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder={t('GLOBAL.COUNTRY')}
                          filterOption={(input, option) => {
                            const label = option!.label! as string;
                            return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                          size="large"
                          disabled={expired}
                        >
                          {countries.map((country) => {
                            return (
                              <Select.Option value={country.code} key={country.code} label={country.label}>
                                <div className="demo-option-label-item">
                                  <span role="img" aria-label={country.label}>
                                    {country.emoji}
                                  </span>{' '}
                                  {country.label}
                                </div>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.distance')} name={['addresses', i, 'distance']}>
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} min={0} step="0.01" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!expired && (
                    <Button
                      type="primary"
                      onClick={() => (newAddress ? onSaveAddress(address.id) : onEditAddress(address.id))}
                    >
                      {newAddress ? t('general.save') : t('general.save-changes')}
                    </Button>
                  )}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
        <Button style={{ marginTop: 25 }} type="primary" onClick={onAddAddress}>
          <i className="icon-plus" /> {t('general.address.new-address')}
        </Button>
      </div>
    </div>
  );
};

export default styled(Addresses)``;
