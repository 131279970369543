import colors from '@/styles/colors';
import { BackTop } from 'antd';
import React, { useRef } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children?: any;
}

const TableView: React.FC<Props> = ({ className, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={`${className} table-view`} ref={ref}>
      {children}
      <BackTop visibilityHeight={20} target={() => ref.current?.parentElement || window}>
        <i className="icon-up-big"></i>
      </BackTop>
    </div>
  );
};

export default styled(TableView)`
  > header {
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    position: relative;

    > .left {
      display: flex;

      .ant-select {
        width: 130px;
      }

      > h2 {
        font-weight: 600;
        margin-bottom: 0;
        margin-right: 4rem;
        margin-left: 1rem;
      }
    }

    > .right {
      .actions {
        button:last-of-type {
          margin-left: 5px;
        }
      }
    }
  }

  .ant-back-top {
    bottom: 25px;
    right: 25px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 18px;
      height: 100%;
      width: 100%;

      .icon-up-big {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${colors.blue};
        color: white;
        border-radius: 5px;
      }
    }
  }
`;
