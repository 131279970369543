import api from '@/api';
import { V4DocumentSettings } from '../../types/document-settings.types';
import { UpdateDocumentSettings } from '../types/documents.types';

export const getDocumentSettings = async () => {
  const { response } = await api<{ data: V4DocumentSettings }>(`documents/settings`);
  return response.data;
};

export const updateDocumentSettings = async (payload: UpdateDocumentSettings) => {
  const { response } = await api(`documents/settings`, {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
  return response;
};
