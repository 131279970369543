import api from '@/api';
import { V4SignedStorageFile, V4SignedUrl } from '../types/index.types';

export const uploadFileToSignedUrl = async (
  payload: V4SignedStorageFile[],
  files: File[],
  documentFile: boolean = false,
) => {
  let data = {
    documentFiles: [] as string[],
    filenames: [] as string[],
  };

  const { response } = await api<{ data: V4SignedUrl[] }>('signed-storage-urls', {
    body: JSON.stringify({ urls: payload }),
    method: 'POST',
  });

  // First, upload all files to signed storage
  const uploadPromises = files.map((file, index) => {
    const url = response.data[index];
    data.filenames.push(url.filename);

    return fetch(url.url, {
      body: file,
      method: 'PUT',
      headers: {
        ...url.headers,
        'Content-Type': url.filetype,
      },
    });
  });

  // Ensure all uploads are completed before proceeding
  await Promise.all(uploadPromises);

  if (documentFile) {
    // Now that uploads are complete, send the filenames to the document upload API
    const documentFilePromises = files.map((file, index) => {
      const url = response.data[index];

      return api<{ data: { id: string } }>('documents/upload', {
        body: JSON.stringify({ file: url.filename }),
        method: 'POST',
      });
    });

    const documentResponses = await Promise.all(documentFilePromises);
    data.documentFiles = documentResponses.map((res) => res.response.data.id);
  }

  return data;
};
