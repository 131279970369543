import { useQuery } from '@tanstack/react-query';
import { getProfile } from '../api';

export const useProfile = (userRecordId: string) => {
  const { data: profile = null, isLoading: loadingProfile } = useQuery({
    queryKey: ['get-profile', userRecordId],
    queryFn: () => getProfile(userRecordId),
  });

  return {
    profile,
    loadingProfile,
  };
};
