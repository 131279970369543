import { TQuotaUnit } from './insights/quota-unit.model';
type Unit = 'day' | 'hour';
export enum ColoredShiftsTypes {
  SKILLS = 'skills',
  STATUS = 'status',
  ATTRIBUTES = 'attributes',
}
export enum ScheduleViewsTypes {
  DAY = 'day',
  OPS = 'ops',
  WEEK = 'week',
  MONTH = 'month',
}
export interface IDepartmentSettings {
  department?: {
    company?: string;
    shortcode?: string;
    country?: string;
    api_key?: string;
    image?: string;
    qrcode?: string;
    fulladdress?: string;
    currency?: string;
    public_url?: string;
    calendar_link?: string;
    constraintsId: string;
    tablet_picture?: boolean;
    random_picture?: boolean;
    clockin?: boolean;
    clockin_enable?: boolean;
    tablet_logo_url?: string | null;
  };
  schedule?: {
    start_hour: number;
    end_hour: number;
    order_by_week: string;
    order_by_day: string;
    validate_shifts: boolean;
    shyfts_market: boolean;
    backgrounds: { start: number; end: number; color: string }[];
    dynamic_hour?: boolean;
    shift_grouping?: boolean;
    force_section?: boolean;
    ghost_shifts?: boolean;
    global_stats?: boolean;
    filter_stats?: boolean;
    showall_ressources?: boolean;
    show_shift_price?: boolean;
    count_free_shift_in_head_count?: boolean;
    show_comments?: boolean;
    sidebarCounters?: boolean;
    split_daynight?: boolean;
    notif_approve_schedule?: boolean;
    enable_provisional?: boolean;
    monthly_group_by_sections?: boolean;
    swap_shift?: {
      active?: boolean;
      auto_approve?: boolean;
      message_each_request?: boolean;
      message_on_transfert?: boolean;
    };
    colored_shifts?: ColoredShiftsTypes;
    schedule_views?: ScheduleViewsTypes;
    default_schedule_view: string;
  };
  general?: {
    allow_contract_signature?: boolean;
    extrahours_enabled?: boolean;
    extrahours_endtime?: string;
    extrahours_starttime?: string;
    enable_section?: boolean;
    enable_availability?: boolean;
    nightshift_start?: string;
    nightshift_end?: string;
    nightshift_code_id?: string;
    homeworking?: boolean;
    automatic_dimona?: boolean;
    automatic_contracts?: boolean;
    contract_direct_dimona?: boolean;
    group_document_signatures?: boolean;
    auto_approve_documents?: boolean;
    sms_active?: boolean;
    hide_past_shifts?: boolean;
    show_extra_hours?: boolean;
    intervention_sheets?: boolean;
    vacation_requests?: boolean;
    show_only_startdate?: boolean;
    counters_values?: 'HOURS' | 'DAYS';
    treshold_halfday?: number;
    enable_turnover?: boolean;
    productivity_by_section_enabled?: boolean;
    insight_type: 'ca' | 'table_reservations' | 'number_of_events';
    staff_quota_calculation_method: 'manual' | 'insights_based';
    clockin_dashboard?: boolean;
    activateAvailabilityAll?: boolean;
    availability_delay?: number;
    dimona_shyft_clocking_correction?: boolean;
    dimona_free_clocking_registration?: boolean;
    enable_clocking_costs?: boolean;
  };
  hr?: {
    holiday_special_code_enabled?: boolean;
    holiday_special_code?: string;
    custom_hr_codes?: {
      code?: string;
      description?: string;
    }[];
    hr_pause_deduction?: 'start' | 'end';
  };
  account?: {
    clockin_account?: boolean;
    accountType?: string;
    demoData?: boolean;
    totalUsers?: number;
    totalPrice?: number;
    pricePerUser?: number;
    dimona_daily_registrations?: boolean;
    hasPaymentMethods?: boolean;
    paymentMethods?: {
      account_id: number;
      bank_code: string | null;
      bank_country: string | null;
      bank_last4: string | null;
      branch_code: string | null;
      card_brand: string | null;
      card_country: string | null;
      card_exp_month: number | null;
      card_exp_year: number | null;
      card_funding: string | null;
      card_last4: number | null;
      created_at: string;
      default: number;
      deleted_at: string | null;
      id: number;
      method_type: 'card' | 'sepa_debit';
      payment_method_id: string;
      stripe_customer_id: string;
      updated_at: string;
    }[];
  };
  datas?: {
    languages?: {
      id: number;
      name: string;
    }[];
    hr_partners?: {
      id: number;
      name: string;
    }[];
    constraints?: {
      id: number;
      name: string;
    }[];
  };
  pauses?: {
    paid: boolean;
    from: number;
    duration: number;
    onlyClocking: boolean;
  }[];
  allowances?: {
    id?: string;
    name: string;
    from: number;
    to: number;
    minwork: number;
    value?: number;
  }[];
  alerts?: {
    value: number;
    unit: Unit;
  }[];
  user?: {
    fistname?: string;
    lastname?: string;
    recordId?: string;
    picture?: string;
    lang?: number;
  };
  integrations?: {
    officient?: {
      id: string;
      account: string;
    };
  };
  clockin?: {
    offline_clockings?: boolean;
    paid_clockin_break?: boolean;
    enable_mobile_clockin?: boolean;
    enable_qrcode?: boolean;
    show_tools?: boolean;
    enable_comment?: boolean;
    comment_mandatory?: boolean;
    geolocation?: boolean;
    freeclockin?: boolean;
    approve_freeclockin?: boolean;
    clockin_warning_manager?: boolean;
    clockout_warning_manager?: boolean;
    clockout_warning?: boolean;
    clockout_warning_late?: boolean;
    clocking_forgot?: boolean;
    clocking_round_start?: boolean;
    clocking_round_end?: boolean;
    clockin_round_planned_start_enabled?: boolean;
    clockin_round_planned_end_enabled?: boolean;
    clocking_before?: boolean;
    clocking_after?: boolean;
    pause_round_start?: boolean;
    pause_round_end?: boolean;
    qrcode_mandatory?: boolean;
    breaks?: {
      direction?: string;
      interval?: number;
      takeShiftMinimum?: boolean;
    };
    rounding?: {
      start: {
        strategy?: string;
        until_minutes?: number;
        until_direction?: string;
        near_interval?: number;
        near_direction?: string;
        max_limit?: number;
      };
      end: {
        strategy?: string;
        until_minutes?: number;
        until_direction?: string;
        near_interval?: number;
        near_direction?: string;
        max_limit?: number;
      };
    };
  };
  subscribe?: {
    subscribe_active?: boolean | null;
    subscribe_title?: string | null;
    subscribe_content?: string | null;
    extraFields?: string[] | null;
  };
  'resources-subscribe'?: {
    [key: string]: {
      subscribe_active?: boolean | null;
      subscribe_title?: string | null;
      subscribe_content?: string | null;
      extraFields?: string[] | null;
    };
  };
  clocking?: {
    restomax_clockings?: boolean;
  };
  insights?: {
    coefficient?: number;
    productivity_clocking?: boolean;
    unit?: TQuotaUnit;
    productivity_calculation_type?: 'HOURS_WORKED' | 'STAFF_COSTS';
  };
  pos?: {
    logo: string | null;
    primary: string;
    secondary: string;
    background: string;
    disabled: string;
    error: string;
    clocking_details?: boolean;
    worked_hours?: boolean;
    comment?: boolean;
    comment_mandatory?: boolean;
    allow_contract_signature?: boolean;
  };
}
