import { useQuery } from '@tanstack/react-query';
import { getDocumentVariables } from '../api';

export const useDocumentVariables = () => {
  const { data: documentVariables, isLoading: loading } = useQuery({
    queryKey: ['get-document-variables'],
    queryFn: getDocumentVariables,
    initialData: null,
  });

  return {
    documentVariables,
    loading,
  };
};
