import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Tag as AntdTag } from 'antd';

type Tag = {
  color: string;
  name: string;
  icon?: ReactNode;
  className?: string;
};

const Tag: FC<Tag> = ({ color = '#000000', name, icon, className }) => {
  return (
    <AntdTag className={className} color={`${color}33`} icon={icon} style={{ color: color }}>
      {name}
    </AntdTag>
  );
};

const TagStyled = styled(Tag)``;

export default TagStyled;
