import React, { FC } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import {Modal, Form, Select, message} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { PickerType } from '../../manage/redux/store';
import { useTurnoverTemplates, useUpdateScheduleTurnover } from '../hooks';

type ModalApplyTurnoverTemplateProps = {
  className?: string;
  sectionId?: string;
  visible: boolean;
  picker: PickerType;
  selectedDate: Moment;
  onSetVisibility: (newVisibility: boolean) => void;
};

const ModalApplyTurnoverTemplate: FC<ModalApplyTurnoverTemplateProps> = ({
  className,
  picker,
  visible,
  sectionId,
  selectedDate,
  onSetVisibility,
}) => {
  const [form] = useForm();
  const { t } = useTranslation();

  const { turnoverTemplates, loading } = useTurnoverTemplates(visible);
  const { updateScheduleTurnover } = useUpdateScheduleTurnover(() => {
    message.success(t('SCHEDULE.MODAL_SCHEDULE_TURNOVER.SUCCESS'))
    closeModal();
  });

  const closeModal = () => {
    onSetVisibility(false);
    form.resetFields();
  };

  const onFinish = (values: { turnover_template_id: string }) => {
    const start = moment(picker === 'day' ? selectedDate : selectedDate.clone().startOf('week'));
    const end = moment(picker === 'day' ? selectedDate : selectedDate.clone().endOf('week'));
    const days = Array.from({ length: end.diff(start, 'days') + 1 }, (_, i) => ({
      date: start.clone().add(i, 'days').format('YYYY-MM-DD'),
      turnoverQuotaTemplateId: values.turnover_template_id,
    }));

    updateScheduleTurnover({
      sectionId,
      days,
    });
  };

  return (
    <Modal
      className={className}
      maskClosable={true}
      visible={visible}
      destroyOnClose
      onOk={() => form.submit()}
      onCancel={() => closeModal()}
      title={t('SCHEDULE.MODAL_SCHEDULE_TURNOVER.TITLE')}
      okText={t('GLOBAL.CONFIRM')}
      cancelText={t('GLOBAL.CANCEL')}
    >
      <span>
        {t('SCHEDULE.MODAL_SCHEDULE_TURNOVER.SUB_TITLE', {
          date:
            picker === 'day'
              ? selectedDate.format('LL')
              : `${selectedDate.clone().startOf('week').format('LL')} - ${selectedDate
                  .clone()
                  .endOf('week')
                  .format('LL')}`,
        })}
      </span>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="turnover_template_id" rules={[{ required: true }]}>
          <Select
            allowClear
            showSearch
            loading={loading}
            placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}
            filterOption={(input, option) =>
              option!.children.props.children[0].props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {turnoverTemplates &&
              turnoverTemplates
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((template) => (
                  <Select.Option key={template.id} value={template.id}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span>{template.name}</span>
                      {template.is_default && <span>{t('GLOBAL.DEFAULT')}</span>}
                    </div>
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalApplyTurnoverTemplate)`
  .ant-form-item {
    margin: 10px 0 0 0;
  }
`;
