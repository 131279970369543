import React, { FC, useContext, useEffect, useState } from 'react';
import moment from 'moment/moment';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import ModalYesterdayTurnover from './ModalYesterdayTurnover';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

const DailyTurnover: FC<{ widgetsLoading: boolean }> = ({ widgetsLoading }) => {
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      !widgetsLoading &&
      activeDepartment?.params?.enableTurnover &&
      activeDepartment.accountType !== 'TRIAL' &&
      activeDepartment.accountType !== 'TRIAL_UNCONFIRMED' &&
      isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
      userDetails?.params?.access?.manage_productivity &&
      localStorage.getItem(`${activeDepartment?.id}_hideTurnover`) !== moment().format('YYYY-MM-DD')
    ) {
      setVisible(true);
    }
  }, [activeDepartment, widgetsLoading, userDetails]);

  if (!visible) {
    return null;
  }

  return <ModalYesterdayTurnover visible={visible} onClose={() => setVisible(false)} />;
};

export default DailyTurnover;
