import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContractTemplates } from '../api';
import ContractTemplateActions from '../components/contract-template-actions';
import { V4ContractTemplate } from '../types/contract-templates.types';

export const useContractTemplates = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users },
  } = useContext(AppContext);

  const { data: contractTemplates = [], isLoading: loading } = useQuery({
    queryKey: ['get-contract-templates', activeDepartmentId],
    queryFn: getContractTemplates,
  });

  const [columns, setColumns] = useState<ColumnsType<V4ContractTemplate>>([]);

  useEffect(() => {
    setColumns([
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: (a: V4ContractTemplate, b: V4ContractTemplate) => a.id.localeCompare(b.id),
      },
      {
        title: t('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: V4ContractTemplate, b: V4ContractTemplate) => a.name.localeCompare(b.name),
      },
      {
        title: t('general.created-on'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a: V4ContractTemplate, b: V4ContractTemplate) =>
          moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
        render: (_, template) => {
          return moment(template.createdAt).format('L HH:mm');
        },
      },
      {
        title: t('general.actions'),
        key: 'actions',
        render: (_, contractTemplate: V4ContractTemplate) => {
          return <ContractTemplateActions contractTemplate={contractTemplate} />;
        },
      },
    ]);
  }, [users]);

  return {
    columns,
    loading,
    contractTemplates,
  };
};
