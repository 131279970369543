import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sendDocument } from '../api';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  documentId: V4Document['id'];
}

const ApproveDocument: React.FC<Props> = ({ className, documentId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['send-document', { documentId }],
    mutationFn: () => sendDocument(documentId),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-documents'] }),
  });

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.send-document')}>
        <Button type="primary" className="action" onClick={() => mutate()} loading={loading}>
          {t('general.approve')}
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(ApproveDocument)``;
