import AppContext from '@/pages/app/context';
import { getWindowSize } from '@/utils';
import { Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: (value: React.SetStateAction<boolean>) => void;
}

const ModalTrialToPaid: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onCancel = () => {
    setVisible(false);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('from');
    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
  };

  return (
    <StyledModal
      className={`${className} modal-success`}
      maskClosable
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
      title={`🎉 ${t('GLOBAL.CONGRATULATIONS')}`}
    >
      <Confetti width={windowSize.innerWidth} height={windowSize.innerHeight} />
      <h3 dangerouslySetInnerHTML={{ __html: t('TRIAL.START_NOW.SUCCESS') }}></h3>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &.modal-success {
    .ant-modal-content {
      border-radius: 8px;
      overflow: hidden;
      position: relative;
    }

    .ant-modal-header {
      background: repeating-linear-gradient(-45deg, #00a651, #00a651 15px, #14aa5c 15px, #14aa5c 30px);
      color: white;
      font-size: 24px;
      font-weight: 600;
      padding: 20px;
    }

    .ant-modal-title {
      color: white;
    }

    .ant-modal-body {
      font-size: 16px;
      padding: 20px;
    }

    .ant-btn-primary {
      background: #00a651;
      border-color: #00a651;
      color: white;
    }

    .ant-btn:not(.ant-btn-primary) {
      background: #e2e6ef;
      border-color: #e2e6ef;
      color: #273140;
    }
  }
`;

export default ModalTrialToPaid;
