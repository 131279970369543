import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { Form, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useProfile } from '../hooks/use-profile';
import Addresses from './addresses';
import FamilySituations from './family-situations';
import { useProfileHrContracts } from './hooks';
import HrContracts from './hr-contracts';

interface Props {
  className?: string;
  user: IUser;
}

const Attentia: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const { profile, loadingProfile } = useProfile(user.recordId!);
  const { profileHrContracts, loading: loadingProfileHrContracts } = useProfileHrContracts(profile?.data.id);

  useEffect(() => {
    onReset();
  }, [form, profile, profileHrContracts]);

  const onReset = () => {
    if (!profile || !profileHrContracts) return;
    form.setFieldsValue({
      ...profile.data,
      familySituations: profile.data.familySituations.map((familySituation) => ({
        ...familySituation,
        validFrom: moment(familySituation.validFrom),
        validUntil: familySituation.validUntil ? moment(familySituation.validFrom) : undefined,
      })),
      addresses: profile.data.addresses.map((address) => ({
        ...address,
        validFrom: moment(address.validFrom),
        validUntil: address.validUntil ? moment(address.validFrom) : undefined,
      })),
      profileHrContracts: profileHrContracts?.data.map((profileHrContract) => ({
        ...profileHrContract,
        startDate: moment(profileHrContract.startDate),
        endDate: profileHrContract.endDate ? moment(profileHrContract.endDate) : undefined,
        hireDate: profileHrContract.hireDate ? moment(profileHrContract.hireDate) : undefined,
        seniorityDate: profileHrContract.seniorityDate ? moment(profileHrContract.seniorityDate) : undefined,
        careerStartDate: profileHrContract.careerStartDate ? moment(profileHrContract.careerStartDate) : undefined,
      })),
    });
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <div className={className}>
      <Spin spinning={loading || loadingProfile}>
        {form && profile && (
          <Form className="container" layout="vertical" form={form} onFinish={onFinish} onReset={onReset}>
            <FamilySituations profile={profile} form={form} />
            <div style={{ marginTop: 25 }}>
              <Addresses profile={profile} form={form} />
            </div>
            <div style={{ marginTop: 25 }}>
              <HrContracts profile={profile} profileHrContracts={profileHrContracts} form={form} />
            </div>
          </Form>
        )}
      </Spin>
    </div>
  );
};

export default styled(Attentia)`
  .ant-collapse-header {
    align-items: center !important;
  }
`;
