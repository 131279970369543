import { Button, Input } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  currentSearch?: string;
  onSearchChange: (q: string) => void;
}

const Header: React.FC<Props> = ({ className, currentSearch = '', onSearchChange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: userRecordId } = useParams<any>();

  const onCreateNewDocument = () => {
    history.push('/app/documents/documents/new');
  };

  // Local state for immediate search input updates
  const [localSearch, setLocalSearch] = useState(currentSearch);

  // Update local state if parent's value changes
  useEffect(() => {
    setLocalSearch(currentSearch);
  }, [currentSearch]);

  // Debounce parent's onSearchChange by 500ms
  const debouncedSearch = useMemo(() => debounce(onSearchChange, 500), [onSearchChange]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value;
    setLocalSearch(q); // update immediately for UI responsiveness
    debouncedSearch(q);
  };

  return (
    <header className={className}>
      <div>
        <h2>{t('general.documents')}</h2>
      </div>
      <div className="right">
        <Input
          allowClear
          placeholder={t('general.search')}
          suffix={<i className="icon-search" />}
          onChange={onSearch}
          value={localSearch}
        />
        <Button type="primary" onClick={onCreateNewDocument}>
          <i className="icon-plus" />
          {t('documents.documents.create-document')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
