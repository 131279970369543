import React, {FC, useState} from 'react';
import moment from 'moment/moment';
import CopyModal from './CopyModal';
import CustomDatePicker from '@/pages/app/components/CustomDatePicker';
import { Button } from 'antd';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  startDate: Moment;
  onStartDateChange: (date: Moment) => void;
};

export const Header: FC<HeaderProps> = ({ startDate, onStartDateChange }) => {
  const { t } = useTranslation();
  const [copyModalVisible, setCopyModalVisible] = useState(false);

  return (
    <header>
      <button onClick={() => onStartDateChange(moment(startDate).add(-1, 'month'))}>
        <i className="icon-angle-left" />
      </button>
      <CustomDatePicker
        picker="month"
        startDate={startDate}
        onDatePickerChange={(date) => onStartDateChange(date || moment().startOf('month'))}
      />
      <button onClick={() => onStartDateChange(moment(startDate).add(1, 'month'))}>
        <i className="icon-angle-right" />
      </button>
      <Button onClick={() => setCopyModalVisible(true)} style={{ position: 'absolute', right: 20 }}>
        {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_LAST_MONTH')}
      </Button>
      <CopyModal
        startDate={startDate}
        isVisible={copyModalVisible}
        onVisibilityChange={(newVisibility) => setCopyModalVisible(newVisibility)}
      />
    </header>
  );
};
