import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4Signature } from '../types/signatures.types';
import DrawerSignature from './drawer-signature';

interface Props {
  className?: string;
  signature: V4Signature;
}

const EditSignature: React.FC<Props> = ({ className, signature }) => {
  const { t } = useTranslation();
  const [drawerSignatureVisible, setDrawerSignatureVisible] = useState(false);

  const onEdit = () => {
    setDrawerSignatureVisible(true);
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.signatures.edit-signature')}>
        <Button type="ghost" className="action" onClick={onEdit}>
          <i className="icon-edit" />
        </Button>
      </Tooltip>
      <DrawerSignature signature={signature} visible={drawerSignatureVisible} setVisible={setDrawerSignatureVisible} />
    </div>
  );
};

export default styled(EditSignature)``;
