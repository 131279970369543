import { usePusher } from '@/components/PusherProvider';
import AuthContext from '@/context';
import { useWindowSize } from '@/hooks/use-window-size';
import TableViewStyled from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import {
  appendToFilename,
  getHtmlWithCodes,
  getHtmlWithVariables,
  handleError,
  isFeatureEnabled,
  isset,
  TINYMCE_CONTENT_STYLE,
  TINYMCE_PLUGINS,
  TINYMCE_TOOLBAR,
  variablesObjectToArray,
} from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Checkbox, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/es/upload/interface';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import 'moment-timezone';
import { Channel } from 'pusher-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { uploadFileToSignedUrl } from '../../api';
import ModalVariablesUsage from '../../components/modal-variables-usage';
import { useSignatures } from '../../signatures/hooks/use-signatures';
import { V4DocumentVariables } from '../../types/index.types';
import { createDocument, editDocument } from '../api';
import { useDocument } from '../hooks/use-document';
import { useDocumentVariables } from '../hooks/use-document-variables';
import { useDocumentTemplates } from '../templates/hooks/use-document-templates';
import { V4ManageDocumentPayload } from '../types/documents.types';
import Header from './header';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
}

const Document: React.FC<Props> = ({ className }) => {
  const location = useLocation<any>();
  const {
    state: {
      activeDepartmentId,
      users,
      loadingUsers,
      userCategories,
      loadingUserCategories,
      skills,
      loadingSkills,
      features,
    },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  //V4
  const [form] = useForm();
  const { documentId } = useParams<any>();
  const history = useHistory();
  const placeholdersRef = useRef<V4DocumentVariables | null>(null);
  const editorRef = useRef<any>(null);
  const previewRef = useRef<any>(null);
  const pusher = usePusher();

  const { windowSize } = useWindowSize();
  const { document: activeDocument, loading: loadingDocument } = useDocument(documentId);
  const { documentTemplates, loading: loadingDocumentTemplates } = useDocumentTemplates();
  const { signatures, loading: loadingSignatures } = useSignatures();
  const { documentVariables } = useDocumentVariables();

  const [loading, setLoading] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [modalVariablesUsageVisible, setModalVariablesUsageVisible] = useState<boolean>(false);
  const [activeTemplateId, setActiveTemplateId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const [uploadedFileData, setUploadedFileData] = useState<{
    documentFiles: string[];
  } | null>(null);
  const [previewPdf, setPreviewPdf] = useState<string | null>(null);

  const newDocument = documentId == 'new';
  const activeTemplate = documentTemplates.find((documentTemplate) => documentTemplate.id == activeTemplateId);
  const locked = activeDocument ? !!activeDocument.sentAt : false;

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusher && activeDepartmentId && userDetails) {
      channel = pusher.channel(`private-App.User.${userDetails.id}.Department.${activeDepartmentId}`);

      if (!channel) return;

      const callbacks = channel.callbacks;

      if (!callbacks.get('document_file_preview_generated')) {
        channel.bind('document_file_preview_generated', async (message: any) => {
          setPreviewPdf(message.pdfUrl);
        });
      }
    }

    return () => {
      if (channel) {
        channel.unbind();
      }
    };
  }, [pusher, activeDepartmentId, userDetails]);

  // Set default signatures (if any)
  useEffect(() => {
    const defaultSignature = signatures.find((signature) => signature.default);

    if (!defaultSignature) return;

    form.setFieldsValue({
      adminSignatureId: defaultSignature.id,
    });
  }, [signatures]);

  useEffect(() => {
    if (!file) return;
    const upload = async () => {
      const data = await uploadFileToSignedUrl(
        [{ filename: appendToFilename(file.name, `${activeDepartmentId}_${moment().unix()}`), filetype: file.type }],
        [file],
        true,
      );
      setUploadedFileData(data);
    };
    upload();
  }, [file]);

  // Add variables to the editor "variabled" selector
  useEffect(() => {
    if (documentVariables) {
      placeholdersRef.current = documentVariables;
    }
  }, [documentVariables]);

  useEffect(() => {
    if (!activeDocument) return;

    const activeRecipient = activeDocument.recipients[0];

    form.setFieldsValue({
      ...activeDocument,
      expiryDate: activeDocument.expiryDate ? moment(activeDocument.expiryDate) : undefined,
      recipientType: activeRecipient.type,
      users: activeRecipient.type === 'users' ? activeRecipient.ids : undefined,
      skills: activeRecipient.type === 'skills' ? activeRecipient.ids : undefined,
      status: activeRecipient.type === 'status' ? activeRecipient.ids : undefined,
    });

    if (activeDocument.fileName && activeDocument.type == 'upload') {
      setFileName(activeDocument.fileName);
    }

    if (activeDocument.type == 'html') {
      setHtmlContent(getHtmlWithCodes(activeDocument.html, documentVariables));
    }

    if (activeDocument.file) {
      setPreviewPdf(activeDocument.file);
    }
  }, [location.pathname, documentId, activeDocument, documentVariables]);

  // Set template data
  useEffect(() => {
    if (!activeTemplate) return;

    const { signatureRequired, adminSignatureId } = form.getFieldsValue();

    const {
      fields: { signatureRequired: tdSignatureRequired, signatureId: tdSignatureId },
      html,
      fileName,
      type,
      file,
    } = activeTemplate;

    if (fileName && type == 'upload') {
      setFileName(fileName);
    }

    if (html) {
      setHtmlContent(getHtmlWithCodes(html, documentVariables));
    }

    if (file) {
      setPreviewPdf(file);
    }

    form.setFieldsValue({
      signatureRequired: signatureRequired ? signatureRequired : tdSignatureRequired,
      adminSignatureId: adminSignatureId ? adminSignatureId : isset(tdSignatureId) ? tdSignatureId : undefined,
    });
  }, [form, activeTemplate, documentTemplates, documentVariables]);

  const onWantToChangeTemplateId = (value: string) => {
    if (editorRef.current.getContent()) {
      Modal.confirm({
        title: t('GLOBAL.WARNING'),
        type: 'warning',
        content: t('DOCUMENTS.MODAL_CHANGE_TEMPLATE.CONTENT'),
        cancelText: t('GLOBAL.CANCEL'),
        okText: t('GLOBAL.VALIDATE'),
        okType: 'danger',
        onOk: () => {
          setActiveTemplateId(value);
          setFile(null);
          setFileName(null);
          setActiveTemplateId(value);
        },
        onCancel: () => {
          form.setFieldsValue({ templateId: activeTemplateId });
        },
      });
    } else {
      setFile(null);
      setFileName(null);
      setActiveTemplateId(value);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const basePayload = {
      title: values.title,
      fileName: 'filename',
      signatureRequired: values.signatureRequired || false,
      adminSignatureId: values.adminSignatureId,
      recipients: [
        values.recipientType == 'all' && { type: 'all', ids: [] },
        values.users && { type: 'users', ids: values.users },
        values.skills && { type: 'skills', ids: values.skills },
        values.status && { type: 'status', ids: values.status },
      ].filter(Boolean),
      expiryDate: values.expiryDate?.format('YYYY-MM-DD'),
      templateId: values.templateId,
      message: values.message,
    };

    let payload: V4ManageDocumentPayload;

    payload = {
      ...basePayload,
      type: 'html',
      html: getHtmlWithVariables(editorRef?.current?.getContent()),
    };

    if (file && uploadedFileData) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: uploadedFileData.documentFiles[0],
      };
    } else if (fileName && activeDocument?.documentFileId) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: activeDocument?.documentFileId,
      };
    } else if (fileName && activeTemplate?.documentFileId) {
      payload = {
        ...basePayload,
        type: 'upload',
        file: activeTemplate?.documentFileId,
      };
    }

    try {
      if (newDocument) {
        await createDocument(payload);
        message.success(t('documents.documents.document-created'));
      } else {
        await editDocument(documentId, payload);
        message.success(t('documents.documents.document-edited'));
      }
      history.push('/app/documents/documents/all');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    setPreviewPdf(null);
    return false;
  };

  const onClearTemplate = () => {
    setFile(null);
    setFileName(null);
  };

  const onVariablesUsage = () => {
    setModalVariablesUsageVisible(true);
  };

  return (
    <>
      <ModalVariablesUsage
        visible={modalVariablesUsageVisible}
        setVisible={setModalVariablesUsageVisible}
        variables={documentVariables}
      />
      <TableViewStyled className={className}>
        <Header locked={locked} form={form} loading={loading} />
        <Spin spinning={loadingDocument || loading}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{ recipientType: 'all' }}
            className="contract-container"
          >
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <div className="card-shadow">
                  <Form.Item label={t('GLOBAL.RECIPIENTS')} name="recipientType" rules={[{ required: true }]}>
                    <Radio.Group
                      options={[
                        { label: t('GLOBAL.ALL'), value: 'all' },
                        { label: t('GLOBAL.USER(S)'), value: 'users' },
                        { label: t('GLOBAL.USER_CATEGORY(IES)'), value: 'status' },
                        ...(isFeatureEnabled(features, FEATURES.SKILLS)
                          ? [{ label: t('GLOBAL.SKILL(S)'), value: 'skills' }]
                          : []),
                      ]}
                      disabled={locked}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    shouldUpdate={(prevValues, curValues) => prevValues.recipientType !== curValues.recipientType}
                  >
                    {(form) => {
                      const recipientType = form.getFieldValue('recipientType');
                      let formItem = null;
                      switch (recipientType) {
                        case 'users': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.USER(S)')}
                              name="users"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingUsers}
                                placeholder={t('FORMS.USER_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={locked}
                              >
                                {users?.map((user) => {
                                  const { recordId, displayName } = user;
                                  return (
                                    <Option key={`user_${recordId}`} value={`${recordId}`}>
                                      {displayName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                        case 'status': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.USER_CATEGORY(IES)')}
                              name="status"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                allowClear
                                mode="multiple"
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingUserCategories}
                                placeholder={t('FORMS.USER_CATEGORY_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={locked}
                              >
                                {userCategories?.map((userCategory) => {
                                  const { id, name } = userCategory;
                                  return (
                                    <Option key={`userCategory_${id}`} value={`${id}`}>
                                      {name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                        case 'skills': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.SKILL(S)')}
                              name="skills"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                allowClear
                                mode="multiple"
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingSkills}
                                placeholder={t('FORMS.SKILL_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={locked}
                              >
                                {skills?.map((skill) => {
                                  const { id, name } = skill;
                                  return (
                                    <Option key={`skill_${id}`} value={`${id}`}>
                                      {name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                      }
                      return (
                        <React.Fragment>
                          {formItem}
                          <Form.Item
                            label={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.NAME')}
                            name="title"
                            rules={[{ required: true }]}
                            style={{
                              display: 'inline-block',
                              width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                              marginRight: '10px',
                            }}
                          >
                            <Input placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.NAME')} disabled={locked} />
                          </Form.Item>
                          <Form.Item
                            label={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.TEMPLATE')}
                            name="templateId"
                            style={{
                              display: 'inline-block',
                              width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            }}
                          >
                            <Select
                              allowClear
                              getPopupContainer={(trigger) => trigger}
                              loading={loadingDocumentTemplates}
                              showSearch
                              placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.TEMPLATE_PLACEHOLDER')}
                              optionFilterProp="children"
                              onChange={onWantToChangeTemplateId}
                              filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={locked}
                            >
                              {documentTemplates.map((template) => {
                                const { name, id } = template;
                                return (
                                  <Option key={`template_${id}`} value={`${id}`}>
                                    {name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </React.Fragment>
                      );
                    }}
                  </Form.Item>
                  <div style={{ display: 'flex', flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column' }}>
                    <Form.Item
                      name="expiryDate"
                      label={t('GLOBAL.EXPIRATION_DATE')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            expiryDate: e,
                          });
                        }}
                        disabled={locked}
                      />
                    </Form.Item>
                    {(!locked || (locked && fileName)) && (
                      <Form.Item
                        label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}
                        style={{
                          display: 'inline-block',
                          width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          marginRight: '10px',
                        }}
                      >
                        <div className="upload-file-container">
                          <div className={`left ${locked ? 'locked' : ''}`}>
                            <Upload
                              beforeUpload={beforeUpload}
                              accept=".docx"
                              maxCount={1}
                              showUploadList={false}
                              className="browse-btn-container"
                              disabled={locked}
                            >
                              <div className="content">
                                <button type="button" className="browse-btn">
                                  {file ? file.name : fileName ? fileName : t('GLOBAL.BROWSE')}
                                </button>
                              </div>
                            </Upload>
                            {file || fileName ? <i className="icon-plus" onClick={onClearTemplate} /> : null}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <button type="button" style={{ textDecoration: 'underline' }} onClick={onVariablesUsage}>
                            {t('DOCUMENTS.VARIABLES.TITLE')}
                          </button>
                        </div>
                      </Form.Item>
                    )}
                    <Form.Item
                      label={t('GLOBAL.SIGNATURE')}
                      name="signatureRequired"
                      valuePropName="checked"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <Checkbox disabled={locked}>{t('FORMS.REQUIRED_SIGNATURE')}</Checkbox>
                    </Form.Item>
                  </div>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.signatureRequired !== curValues.signatureRequired
                    }
                  >
                    {(form) => {
                      const signatureRequired = form.getFieldValue('signatureRequired');
                      if (signatureRequired) {
                        return (
                          <Form.Item name="adminSignatureId" label={t('CONTRACTS.FORM.SIGNATORY')}>
                            <Select
                              allowClear
                              getPopupContainer={(trigger) => trigger}
                              showSearch
                              loading={loadingSignatures}
                              placeholder={t('CONTRACTS.FORM.SIGNATORY_PLACEHOLDER')}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option!.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={locked}
                            >
                              {signatures.map((signature) => {
                                const { name, id, title } = signature;
                                return (
                                  <Option key={`signatory_${id}`} value={`${id}`}>
                                    {name} - {title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item label={t('GLOBAL.MESSAGE')} name="message">
                    <TextArea
                      rows={4}
                      placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.MESSAGE_PLACEHOLDER')}
                      disabled={locked}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {!file && !fileName && !locked ? (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          variablesObjectToArray(placeholdersRef.current)?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            ) : (
              <>
                {previewPdf ? (
                  <iframe src={previewPdf} width="100%" height="800px" />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin spinning={!previewPdf} />
                    <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                  </div>
                )}
              </>
            )}
            {!locked && (
              <div className={`actions active`}>
                <Button loading={loading} type="primary" htmlType="submit">
                  {t('GLOBAL.SAVE')}
                </Button>
              </div>
            )}
          </Form>
        </Spin>
      </TableViewStyled>
    </>
  );
};

const DocumentStyled = styled(Document)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;

      &.locked {
        background-color: #f5f5f5 !important;
      }

      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
    }

    .browse-btn-container {
      flex: 1;

      .ant-upload-select {
        width: 100%;

        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 9px;
          width: 100%;
        }
      }
    }
  }

  .ant-row {
    margin-bottom: 20px;
  }

  .contract-container {
    padding: 0 25px 25px;

    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }

  .tox-tinymce {
    margin-bottom: 40px;
  }

  .card-shadow {
    padding: 0;
    margin-bottom: 40px;
  }

  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: 40px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;

export default DocumentStyled;
