import api from '@/api';
import { CreateSignaturePayload, UpdateSignaturePayload, V4Signature } from '../types/signatures.types';

export const getSignatures = async () => {
  const { response } = await api<{ data: V4Signature[] }>('documents/signatures');
  return response.data;
};

export const createSignature = async (payload: CreateSignaturePayload) => {
  const { response } = await api('documents/signatures', {
    body: JSON.stringify(payload),
    method: 'POST',
  });
  return response;
};

export const updateSignature = async (payload: UpdateSignaturePayload) => {
  const { response } = await api(`documents/signatures/${payload.id}`, {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
  return response;
};

export const deleteSignature = async (signatureId: string) => {
  const { response } = await api(`documents/signatures/${signatureId}`, { method: 'DELETE' });
  return response;
};
