import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  onClose: () => void;
  loading: boolean;
}

const DrawerActions: React.FC<Props> = ({ className, onClose, loading }) => {
  const { t } = useTranslation();

  return (
    <div className={`actions-container ${className}`}>
      <div className="actions">
        <Button type="primary" danger onClick={onClose}>
          {t('general.cancel')}
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
};

export default styled(DrawerActions)``;
