import { IDepartment } from '@/types/department.model';
import { Menu } from 'antd';
import { Crisp } from 'crisp-sdk-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  department?: IDepartment;
  openSupport: () => void;
}

const MenuHelp: React.FC<Props> = (props) => {
  const { department, openSupport, ...otherProps } = props;
  const { t, i18n } = useTranslation(undefined, { useSuspense: true });

  const openChat = (e: any) => {
    if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  };

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
      <Menu.Item key="support" onClick={openSupport} className="menu_help_support">
        &#x2753; {t('MENU.HELP.SUPPORT')}
      </Menu.Item>
      {department?.support && (
        <Menu.Item key="chat" onClick={openChat} className="menu_help_chat">
          &#x1F4AC; {t('MENU.HELP.CHAT')}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MenuHelp;
