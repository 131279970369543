import React, { FC } from 'react';
import Tag from './Tag';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type ClockingStatusProps = {
  status: string;
  clockingType: 'start' | 'end';
  className?: string;
};

const dataPerStatus: { [$key: string]: { translationKey: string; color: string } } = {
  start_late: {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_START_LATE',
    color: '#d95c62',
  },
  start_early: {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_START_EARLY',
    color: '#3279bb',
  },
  'start_on-time': {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_START_ON_TIME',
    color: '#3aa119',
  },
  end_late: {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_END_LATE',
    color: '#3279bb',
  },
  end_early: {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_END_EARLY',
    color: '#d95c62',
  },
  'end_on-time': {
    translationKey: 'DASHBOARD.WIDGETS.CLOCKING_END_ON_TIME',
    color: '#3aa119',
  },
};

const ClockingStatus: FC<ClockingStatusProps> = ({ status, clockingType, className }) => {
  const { t } = useTranslation();
  const { translationKey, color } = dataPerStatus[`${clockingType}_${status}`] ?? {};

  if (status === 'free') {
    return null;
  }

  return <Tag color={color} name={t(translationKey)} />;
};

const ClockingStatusStyled = styled(ClockingStatus)``;

export default ClockingStatusStyled;
