import AppContext from '@/pages/app/context';
import { Button, Form, Input, Modal, Switch, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCreateScheduleComment, useDeleteScheduleComment, useUpdateScheduleComment } from '../../hooks';
import { V4CommentResponse } from '../../types';

type CommentProps = {
  date: string;
  comment?: V4CommentResponse;
  className?: string;
};

const Comment: FC<CommentProps> = ({ comment, date, className }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection },
  } = useContext(AppContext);

  const { createScheduleComment } = useCreateScheduleComment(() => Modal.destroyAll());
  const { deleteScheduleComment } = useDeleteScheduleComment(() => Modal.destroyAll());
  const { updateScheduleComment } = useUpdateScheduleComment(comment?.id || '', () => Modal.destroyAll());

  const onManageComment = (values: any, date: string, comment: any) => {
    if (!comment) {
      createScheduleComment({
        sectionId: activeSection,
        date,
        ...values,
      });
    } else {
      updateScheduleComment({
        sectionId: activeSection,
        date,
        ...values,
      });
    }
  };

  const onDeleteComment = (comment: any) => {
    if (!comment) {
      Modal.destroyAll();
      return;
    }

    deleteScheduleComment(comment.id);
  };

  const manageComment = (date: string, comment: any) => {
    form.setFieldsValue({
      comment: comment ? comment.comment : null,
      isPublic: comment ? comment.isPublic : true,
    });

    Modal.confirm({
      title: `${t('GLOBAL.DAY_COMMENT')} : ${moment(date).format('L')}`,
      closable: true,
      maskClosable: true,
      icon: null,
      content: (
        <Form
          form={form}
          onFinish={(values: any) => onManageComment(values, date, comment)}
          initialValues={{ isPublic: true }}
        >
          <Form.Item name="comment" style={{ marginBottom: 10 }}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <label>{t('GLOBAL.PUBLIC_DAY_COMMENT')}</label>
            <Form.Item name="isPublic" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
            <Button type="primary" danger onClick={() => onDeleteComment(comment)}>
              {comment ? t('GLOBAL.DELETE') : t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" onClick={() => form.submit()}>
              {comment ? t('GLOBAL.EDIT') : t('GLOBAL.ADD_DAY_COMMENT')}
            </Button>
          </div>
        </Form>
      ),
      okButtonProps: {
        hidden: true,
      },
      cancelButtonProps: {
        hidden: true,
      },
    });
  };

  return (
    <div className={className} onClick={() => manageComment(date, comment)}>
      <Tooltip
        placement={'bottom'}
        overlay={
          comment ? (
            <div>
              <i className={comment.isPublic ? 'icon-globe' : 'icon-lock'} />
              {comment.comment}
            </div>
          ) : (
            t('GLOBAL.ADD_DAY_COMMENT')
          )
        }
      >
        <i className={comment ? 'icon-commenting-o' : 'icon-plus'} />
      </Tooltip>
    </div>
  );
};

export const CommentStyled = styled(Comment)``;
