import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setDrawerSignatureVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ className, setDrawerSignatureVisible }) => {
  const { t } = useTranslation();

  const onCreateSignature = () => {
    setDrawerSignatureVisible(true);
  };

  return (
    <header className={className}>
      <div className="left">
        <h2>{t('general.signatures')}</h2>
      </div>
      <div className="right">
        <Button type="primary" onClick={onCreateSignature}>
          <i className="icon-plus" />
          {t('documents.signatures.create-signature')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
