import colors from '@/styles/colors';
import { Collapse, message, Modal, Tooltip } from 'antd';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4DocumentVariables } from '../types/index.types';

interface Props {
  className?: string;
  visible: boolean;
  variables: V4DocumentVariables | null;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalVariablesUsage: React.FC<Props> = ({ className, visible, variables, setVisible }) => {
  const { t } = useTranslation();

  const onCopy = (text: string) => {
    message.success(t('DOCUMENTS.VARIABLES.CATEGORIES.COPIED', { name: text }), 1);
  };

  const onCancel = () => {
    setVisible(false);
  };

  if (!variables) return <></>;

  return (
    <div className={className}>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={onCancel}
        closable
        footer={null}
        width="75%"
        style={{ top: '16px' }}
      >
        <h1>{t('DOCUMENTS.VARIABLES.MODAL.TITLE')}</h1>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em' }}>
          <span>{t('DOCUMENTS.VARIABLES.MODAL.DESCRIPTION_1')}</span>
          <span>{t('DOCUMENTS.VARIABLES.MODAL.DESCRIPTION_2')}</span>
        </div>
        <Collapse accordion defaultActiveKey="account">
          {Object.entries(variables).map(([key, value]: [string, any]) => {
            let name = '';
            let required = false;
            switch (key) {
              case 'account': {
                name = t('GLOBAL.ACCOUNT');
                break;
              }
              case 'department': {
                name = t('GLOBAL.DEPARTMENT');
                break;
              }
              case 'user_general': {
                name = t('DOCUMENTS.VARIABLES.CATEGORIES.USER_DEFAULT');
                break;
              }
              case 'user_custom': {
                name = t('DOCUMENTS.VARIABLES.CATEGORIES.USER_CUSTOM');
                break;
              }
              case 'document': {
                name = t('GLOBAL.DOCUMENT');
                break;
              }
            }
            return (
              <Collapse.Panel
                header={`${name}`}
                key={`${key}`}
                extra={required && <span style={{ color: colors.redDark }}>{t('GLOBAL.REQUIRED')}</span>}
              >
                {Object.entries(value)
                  .map(([key, value]) => ({ id: key, label: value }))
                  .map((el: any) => (
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                      <span>{el.label}</span>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <div
                          style={{
                            width: 'fit-content',
                            backgroundColor: colors.greyLight,
                            padding: '2px 5px',
                          }}
                        >
                          &#36;{`{${el.id}}`}
                        </div>
                        <CopyToClipboard text={`$\{${el.id}\}`} onCopy={() => onCopy(`$\{${el.id}\}`)}>
                          <Tooltip title={t('GLOBAL.COPY')}>
                            <i className="icon-clone" style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Modal>
    </div>
  );
};

export default styled(ModalVariablesUsage)``;
