import api from '@/api';
import {
  UpdateDailyTurnoverPayload,
  UpdateScheduleTemplatePayload,
  CreateScheduleCommentPayload,
  UpdateScheduleCommentPayload,
  V4DailyTurnoverResponse,
  V4ScheduleTurnoverResponse,
  V4TurnoverTemplateResponse,
  V4BirthdayResponse,
  V4CommentResponse,
  V4WeatherResponse
} from '../types/index';

export const getTurnoverTemplates = async () => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse[] }>(`turnover-quota-templates`);
  return response.data;
};

export const getScheduleTurnover = async (startDate: string, endDate: string, sectionId?: string) => {
  const { response } = await api<{
    data: V4ScheduleTurnoverResponse;
  }>(
    `turnover-quota-templates/schedule?startDate=${startDate}&endDate=${endDate}${
      sectionId ? `&sectionId=${sectionId}` : ''
    }`,
  );
  return response.data;
};

export const getDailyTurnover = async (startDate: string, endDate: string, sectionId?: string) => {
  const { response } = await api<{ data: V4DailyTurnoverResponse }>(
      `insights?startDate=${startDate}&endDate=${endDate}${sectionId ? `&sectionId=${sectionId}` : ''}`,
  );
  return response.data;
};

export const updateScheduleTemplate = async (payload: UpdateScheduleTemplatePayload) => {
  await api<null>(`turnover-quota-templates/schedule-apply`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return null;
};

export const updateDailyTurnover = async (payload: UpdateDailyTurnoverPayload) => {
  await api<null>(`insights/daily-turnover`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return null;
};

export const getComments = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: V4CommentResponse[] }>(
    `schedule/day-comments?startDate=${startDate}&endDate=${endDate}`,
  );
  return response.data;
};

export const getWeather = async () => {
  const { response } = await api<{ data: V4WeatherResponse[] }>(`schedule/weather`);
  return response.data;
};

export const getBirthdays = async (startDate: string, endDate: string) => {
  const { response } = await api<{
    data: V4BirthdayResponse[];
  }>(`schedule/birthdays?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

export const createComment = async (payload: CreateScheduleCommentPayload) => {
  const { response } = await api<{ data: CreateScheduleCommentPayload }>(`day-comments`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const updateComment = async (commentId: string, payload: UpdateScheduleCommentPayload) => {
  const { response } = await api<{ data: UpdateScheduleCommentPayload }>(`day-comments/${commentId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const deleteComment = async (commentId: string) => {
  const { response } = await api<null>(`day-comments/${commentId}`, {
    method: 'DELETE',
  });

  return response;
};
