import { updateInsightSettings } from '../api';
import { useMutation } from '@tanstack/react-query';
import { UpdateInsightSettingsPayload } from '../types';

export const useInsightSettingsUpdate = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-insight-settings'],
    mutationFn: (payload: UpdateInsightSettingsPayload) => updateInsightSettings(payload),
  });

  return {
    updateSettings: mutate,
    isLoading,
  };
};
