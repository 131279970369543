import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deleteContractTemplate } from '../api';
import { V4ContractTemplate } from '../types/contract-templates.types';

interface Props {
  className?: string;
  contractTemplate: V4ContractTemplate;
}

const DeleteContractTemplate: React.FC<Props> = ({ className, contractTemplate }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['delete-contract-template', contractTemplate.id],
    mutationFn: () => deleteContractTemplate(contractTemplate.id),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-contract-templates'] }),
  });

  const onWantToDeleteContract = () => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('documents.contracts.templates.delete-contract-template-confirmation.title', {
        name: contractTemplate.name,
      }),
      icon: null,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('documents.contracts.templates.delete-contract-template-confirmation.content', {
              name: contractTemplate.name,
            }),
          }}
        ></span>
      ),
      maskClosable: true,
      okText: t('general.delete'),
      onOk: () => {
        mutate();
      },
    });
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.templates.delete-contract-template')}>
        <Button type="primary" danger className="action" onClick={onWantToDeleteContract} loading={loading}>
          <i className="icon-trash-empty" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DeleteContractTemplate)``;
