import React, { FC } from 'react';
import styled from 'styled-components';
import ColorPickerOption from './ColorPickerOption';
import { availableColors } from '../../utils';

type ColorPickerProps = {
  selectedColor: string;
  className?: string;
};

const ColorPicker: FC<ColorPickerProps> = ({ selectedColor, className }) => {
  return (
    <div className={className}>
      {Object.keys(availableColors).map((key) => (
        <ColorPickerOption
          colors={availableColors[key]}
          isSelected={selectedColor === key}
        />
      ))}
    </div>
  );
};

const ColorPickerStyled = styled(ColorPicker)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export default ColorPickerStyled;
