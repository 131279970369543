import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4Document } from '../types/documents.types';
import ModalDocumentDetails from './modal-document-details';

interface Props {
  className?: string;
  document: V4Document;
}

const DetailsDocument: React.FC<Props> = ({ className, document }) => {
  const { t } = useTranslation();

  const [modalDocumentDetailsVisible, setModalDocumentDetailsVisible] = useState(false);

  const onDocumentDetails = () => {
    setModalDocumentDetailsVisible(true);
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.details-document')}>
        <Button
          type="ghost"
          className="action"
          disabled={document.userDocuments.length == 0}
          onClick={onDocumentDetails}
        >
          <i className="icon-user" />
        </Button>
      </Tooltip>
      <ModalDocumentDetails
        visible={modalDocumentDetailsVisible}
        document={document}
        setVisible={setModalDocumentDetailsVisible}
      />
    </div>
  );
};

export default styled(DetailsDocument)``;
