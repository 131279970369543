import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  documentTemplateId: string;
}

const EditDocumentTemplate: React.FC<Props> = ({ className, documentTemplateId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.templates.edit-document-template')}>
        <Button
          type="ghost"
          className="action"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/app/documents/documents/templates/${documentTemplateId}`);
          }}
        >
          <i className="icon-edit" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(EditDocumentTemplate)``;
