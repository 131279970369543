import React, { FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputTable, ListTable } from './components';

type TurnoverTemplateProps = {
  sectionEnabled: boolean;
  settings: {
    start_hour: number;
    end_hour: number;
    dynamic_hour: boolean;
  };
  className?: string;
};

const TurnoverTemplate: FC<TurnoverTemplateProps> = ({ sectionEnabled, className, settings }) => {
  const { t } = useTranslation();
  const { templateId } = useParams();

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}</h2>
      {templateId && <p>{t("SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.SUB_TITLE")}</p>}
      {templateId ? (
        <InputTable templateId={templateId} sectionEnabled={sectionEnabled} settings={settings} />
      ) : (
        <ListTable />
      )}
    </div>
  );
};

const TurnoverTemplateStyled = styled(TurnoverTemplate)``;

export default TurnoverTemplateStyled;
