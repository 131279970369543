import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  contractTemplateId: string;
}

const EditContractTemplate: React.FC<Props> = ({ className, contractTemplateId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onEditContractTemplate = () => {
    history.push(`/app/documents/contracts/templates/${contractTemplateId}`);
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.templates.edit-contract-template')}>
        <Button type="ghost" className="action" onClick={onEditContractTemplate}>
          <i className="icon-edit" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(EditContractTemplate)``;
