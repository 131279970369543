import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deleteSignature } from '../api';
import { V4Signature } from '../types/signatures.types';

interface Props {
  className?: string;
  signature: V4Signature;
}

const DeleteSignature: React.FC<Props> = ({ className, signature }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['delete-signature', signature.id],
    mutationFn: () => deleteSignature(signature.id),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-signatures'] }),
  });

  const onWantToDeleteContract = () => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('documents.signatures.delete-signature-confirmation.title', { user: signature.name }),
      icon: null,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('documents.signatures.delete-signature-confirmation.content', { user: signature.name }),
          }}
        ></span>
      ),
      maskClosable: true,
      okText: t('general.delete'),
      onOk: () => {
        mutate();
      },
    });
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.signatures.delete-signature')}>
        <Button type="primary" danger className="action" onClick={onWantToDeleteContract} loading={loading}>
          <i className="icon-trash-empty" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DeleteSignature)``;
