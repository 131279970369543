import React, { FC, useContext } from 'react';
import L from 'leaflet';
import moment from 'moment/moment';
import AppContext from '@/pages/app/context';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import LocationWidgetTriggerResize from './LocationWidgetTriggerResize';
import { useTranslation } from 'react-i18next';
import { V4GeoClockingsResponse } from '../../types';
import { Icon, LatLngBoundsLiteral, Map } from 'leaflet';
import { convertUtcToTimezoneDateAndTime } from '@/utils';
import { MapContainer, Marker, Popup } from 'react-leaflet';

import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';

const createClusterCustomIcon = (cluster: any) => {
  return L.divIcon({
    html: `<div class="cluster-container">${cluster.getChildCount()}</div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });
};

const currentClockingMarker = (L as any).ExtraMarkers.icon({
  icon: 'fa-spinner',
  prefix: 'fa',
  extraClasses: 'fa-spin',
  markerColor: 'green',
  svg: true,
});

const todayClockingMarker = (L as any).ExtraMarkers.icon({
  markerColor: 'green',
});

const pastClockingMarker = (L as any).ExtraMarkers.icon({
  markerColor: 'orange',
});

Icon.Default.mergeOptions({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
});

type LocationWidgetProps = {
  data: V4GeoClockingsResponse;
  editing: boolean;
};

const LocationWidget: FC<LocationWidgetProps> = ({ editing, data }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const clockingBounds: LatLngBoundsLiteral = data
    .filter((l) => l.location.lat || l.location.lng)
    .map((l) => [l.location.lat ?? 0, l.location.lng ?? 0]);
  const departmentBounds: LatLngBoundsLiteral =
    activeDepartment?.lat && activeDepartment?.lng
      ? [
          [parseFloat(activeDepartment.lat), parseFloat(activeDepartment.lng)],
          [parseFloat(activeDepartment.lat), parseFloat(activeDepartment.lng)],
        ]
      : [];
  const defaultBounds: LatLngBoundsLiteral = [
    [50.85045, 4.34878],
    [50.95045, 4.44878],
  ];

  const bounds =
    (clockingBounds.length ? clockingBounds : undefined) ??
    (departmentBounds.length ? departmentBounds : undefined) ??
    defaultBounds;

  return (
    <MapContainer
      zoom={13}
      maxZoom={17}
      scrollWheelZoom={false}
      style={{
        width: '100%',
        borderRadius: 3,
        border: '1px solid #e6e5e5',
        height: 'calc(100% - 35px)',
      }}
      whenCreated={(map: Map) => map.fitBounds(bounds)}
    >
      <VectorTileLayer
        attribution="© MapTiler © OpenStreetMap contributors"
        styleUrl="https://api.maptiler.com/maps/70ddf71e-c9a7-4112-b00f-9124ba482a89/style.json?key=a9UYy1T8E5qkxvDgC0fH"
      />
      <LocationWidgetTriggerResize editing={editing} bounds={bounds} />
      <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
        {data.map(({ id, startDate, endDate, location, employee }) => (
          <Marker
            key={id}
            position={
              location.lat || location.lng
                ? [location.lat ?? 0, location.lng ?? 0]
                : departmentBounds[0] || defaultBounds[0]
            }
            icon={
              !endDate
                ? currentClockingMarker
                : moment(startDate) < moment().startOf('day')
                ? pastClockingMarker
                : todayClockingMarker
            }
          >
            <Popup>
              <b>{employee.fullName}</b>
              <br />
              <span>
                {convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'll HH:mm')} -{' '}
                {endDate ? convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'll HH:mm') : t('GLOBAL.IN_PROGRESS')}
              </span>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default LocationWidget;
