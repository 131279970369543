import React, { FC } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { Form, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { useCopyDailyTurnover } from '../hooks';
import { CopyDailyTurnoverPayload } from '../types';

type CopyModalProps = {
  startDate: Moment;
  isVisible: boolean;
  onVisibilityChange: (newVisibility: boolean) => void;
  className?: string;
};

const CopyModal: FC<CopyModalProps> = ({ startDate, isVisible, onVisibilityChange, className }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { copyTurnover, isLoading } = useCopyDailyTurnover();

  const onFinish = (values: Pick<CopyDailyTurnoverPayload, 'copyType'>) => {
    copyTurnover({
      copyType: values.copyType,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: startDate.clone().endOf('month').format('YYYY-MM-DD'),
    });
    onVisibilityChange(false);
    form.resetFields();
  };

  return (
    <Modal
      className={className}
      title={t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_LAST_MONTH')}
      maskClosable
      destroyOnClose={true}
      visible={isVisible}
      onCancel={() => {
        form.resetFields();
        onVisibilityChange(false);
      }}
      onOk={() => form.submit()}
      okText={t('GLOBAL.COPY')}
      cancelButtonProps={{ danger: true }}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <span className={'subTitle'}>
          {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_MODAL.SUB_TITLE', { date: moment(startDate).format('MMMM Y') })}
        </span>
        <Form.Item initialValue={'by-week-day'} name="copyType" rules={[{ required: true }]}>
          <Select
            options={[
              {
                value: 'by-week-day',
                label: t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_MODAL.BY_WEEK_DAY'),
              },
              {
                value: 'day-to-day',
                label: `${t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_MODAL.DAY_TO_DAY')}: 1 ${startDate.format(
                  'MMMM',
                )} -> 1 ${moment().format('MMMM')}`,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CopyModalStyled = styled(CopyModal)`
  .ant-modal-body {
    padding: 10px 24px;
  }
  
  .subTitle {
    font-size: 15px;
    margin-bottom: 15px;
    display: inline-block;
  }
`;

export default CopyModalStyled;
