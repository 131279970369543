import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'fallback',
      useSuspense: true,
    },
    fallbackLng: 'fr',
    debug: false,
    initImmediate: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      crossDomain: true,
    },
    ns: ['translation', 'v4'],
    defaultNS: 'translation',
    fallbackNS: ['v4'],
  });

export default i18n;
