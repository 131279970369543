import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sendDocument } from '../api';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  documentId: V4Document['id'];
}

const ResendDocument: React.FC<Props> = ({ className, documentId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['send-document', { documentId }],
    mutationFn: () => sendDocument(documentId),
    onSuccess: () => {
      message.success(t('documents.documents.document-resent'));
      client.invalidateQueries({ queryKey: ['get-documents'] });
    },
  });

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.resend-document')}>
        <Button type="ghost" className="action" onClick={() => mutate()} loading={loading}>
          <i className="icon-paper-plane-empty" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(ResendDocument)``;
