import api from '../../../../api/index';
import {
  V4DailyTurnoversResponse,
  V4DailyTurnoversUpdatePayload,
  V4WidgetDataResponse,
  V4WidgetResponse,
  V4WidgetsColorUpdatePayload,
  V4WidgetSPositionUpdatePayload,
  V4WidgetUpdatePayload,
} from '../types/index';

export const getWidgets = async () => {
  const { response } = await api<{ data: V4WidgetResponse[] }>(`widgets`);
  return response.data;
};

export const getWidget = async (slug: string, startDate: string, endDate: string, sectionId?: string) => {
  const { response } = await api<{ data: V4WidgetDataResponse }>(
    `widgets/${slug}?startDate=${startDate}&endDate=${endDate}${sectionId ? `&sectionId=${sectionId}` : ''}`,
  );
  return response.data;
};

export const updateWidget = async (slug: string, payload: V4WidgetUpdatePayload) => {
  const { response } = await api<{ data: V4WidgetDataResponse }>(`widgets/${slug}/configurations`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const updateWidgetsPosition = async (payload: V4WidgetSPositionUpdatePayload) => {
  const { response } = await api<{ data: V4WidgetDataResponse }>(`widgets/order `, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const updateWidgetsColor = async (payload: V4WidgetsColorUpdatePayload) => {
  const { response } = await api<{ data: V4WidgetDataResponse }>(`widgets/updateColor`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return response.data;
};

export const updateDailyTurnovers = async (payload: V4DailyTurnoversUpdatePayload) => {
  const { response } = await api<null>(`insights/daily-turnover-per-section`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return response;
};

export const getDailyTurnovers = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: V4DailyTurnoversResponse }>(
    `insights/per-sections?startDate=${startDate}&endDate=${endDate}`,
  );
  return response.data;
};
