import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IWeeklyView } from '@/types/weekly-view.model';
import { Drawer, Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PickerType } from '../../redux/store';
import GeneratePdf from '../actions-old/GeneratePdf';
import GenerateScheduleModels from '../actions-old/GenerateScheduleModels';
import PublishShifts from '../actions-old/PublishShifts';
import SubHeaderSearch from '../SubHeaderSearch';

interface Props {
  className?: string;
  visible: boolean;
  toggleDrawer: () => void;
  picker: PickerType;
  loadingWeeklyViews: boolean;
  weeklyViews: IWeeklyView[];
  onWeeklyViewChange: (value: string) => void;
  active_weekly_view: IWeeklyView | null;
  setSaveViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalChooseShiftTemplate: React.Dispatch<React.SetStateAction<'import' | 'compare' | null>>;
  setIsGeneratingSchedule: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  shiftNotApprovedIds: string[];
  shiftsToApprove: string[];
}

const HeaderSmallDrawer: React.FC<Props> = ({
  className,
  visible,
  toggleDrawer,
  picker,
  loadingWeeklyViews,
  weeklyViews,
  onWeeklyViewChange,
  active_weekly_view,
  setSaveViewModalVisible,
  setShowModalChooseShiftTemplate,
  setIsGeneratingSchedule,
  isLoading,
  shiftNotApprovedIds,
  shiftsToApprove,
}) => {
  const { t } = useTranslation();
  const { state, dispatch: appContextDispatch } = useContext(AppContext);
  const { activeDepartment } = state;
  return (
    <Drawer
      className={className}
      title={t('GLOBAL.ACTIONS')}
      width={'75%'}
      visible={visible}
      onClose={toggleDrawer}
      closable={false}
      style={{ zIndex: 999 }}
    >
      <SubHeaderSearch size="large" label="Search" />
      <div className="item select-view-container">
        {picker == 'week' && !loadingWeeklyViews && weeklyViews && weeklyViews.length ? (
          <>
            <span>{t('WEEKLY_VIEWS.TITLE')}</span>
            <Select
              placeholder={t('WEEKLY_VIEWS.PLACEHOLDER')}
              style={{ marginRight: 10 }}
              allowClear
              onChange={onWeeklyViewChange}
              value={active_weekly_view?.id}
              size="large"
            >
              {weeklyViews.map((wv) => (
                <Select.Option key={`weekly_view_${wv.id}`} value={wv.id!}>
                  {wv.name}
                </Select.Option>
              ))}
            </Select>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="buttons">
        <GenerateScheduleModels small setIsGeneratingSchedule={setIsGeneratingSchedule} />
        <PublishShifts
          small
          isLoading={isLoading}
          shiftNotApprovedIds={shiftNotApprovedIds}
          shiftsToApprove={shiftsToApprove}
        />
        {picker !== 'operational' && (
          <GeneratePdf small isLoading={isLoading} shiftNotApprovedIds={shiftNotApprovedIds} />
        )}
      </div>
    </Drawer>
  );
};

export default styled(HeaderSmallDrawer)`
  .ant-drawer-body  {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;
  }

  .select-view-container {
    > div {
      width: 100%;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  .options {
    .option  {
      padding: 10px;
      margin-top: 10px;
    }
  }

  .buttons {
    display: flex;
    gap: 15px;

    .button {
      background-color: ${colors.blueLightPastel};
      border-color: ${colors.blueLightPastel};
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .generate-button {
      flex: 1;
      color: #fff;
    }
  }
`;
