import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getDocument } from '../api';

export const useDocument = (documentId: string) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const isEnabled = documentId !== 'new';

  const { data: document = null, isLoading } = useQuery({
    queryKey: ['get-document', activeDepartmentId, documentId],
    queryFn: () => getDocument(documentId),
    enabled: isEnabled,
  });

  return {
    loading: isEnabled ? isLoading : false,
    document,
  };
};
