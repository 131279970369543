import { countryCodeEmoji } from 'country-code-emoji';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);

export const useCountries = () => {
  const { i18n } = useTranslation();

  const [countriesTranslated, setCountriesTranslated] = useState<[string, string][]>([]);
  const [countriesEmojis, setCountriesEmojis] = useState<string[]>([]);

  useEffect(() => {
    const countr = translatedCountries.getNames(i18n.language);
    const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
    setCountriesEmojis(sorted.map((el) => el[0]).map(countryCodeEmoji));
    setCountriesTranslated(sorted);
  }, []);

  return {
    countries: countriesTranslated.map((country, i) => ({
      code: country[0],
      label: country[1],
      emoji: countriesEmojis[i],
    })),
  };
};
