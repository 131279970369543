import React from 'react';
import styled from 'styled-components';
import { V4Signature } from '../types/signatures.types';
import DeleteSignature from './delete-signature';
import EditSignature from './edit-signature';

interface Props {
  className?: string;
  signature: V4Signature;
}

const SignatureActions: React.FC<Props> = ({ className, signature }) => {
  return (
    <div className={className}>
      <EditSignature signature={signature} />
      <DeleteSignature signature={signature} />
    </div>
  );
};

export default styled(SignatureActions)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
