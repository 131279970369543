import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    // new Sentry.Replay({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});
