import { Button } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  form: FormInstance<any>;
  loading: boolean;
  locked: boolean;
}

const Header: React.FC<Props> = ({ className, form, loading, locked }) => {
  const { t } = useTranslation();
  const { documentId } = useParams<any>();
  const history = useHistory();

  return (
    <header className={className}>
      <div className="left">
        <h2>
          {documentId !== 'new'
            ? t('DOCUMENTS.DOCUMENTS.DOCUMENT.EDIT_DOCUMENT')
            : t('DOCUMENTS.DOCUMENTS.DOCUMENT.NEW_DOCUMENT')}
        </h2>
      </div>
      <div className="right">
        {!locked && (
          <Button loading={loading} type="primary" onClick={() => form.submit()}>
            {t('GLOBAL.SAVE')}
          </Button>
        )}
        <Button type="primary" danger onClick={() => history.push('/app/documents/documents/all')}>
          {t('GLOBAL.BACK')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  padding: 20px 25px !important;

  .left {
    h2 {
      margin-left: 0 !important;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
