import TableView from '@/layouts/TableView';
import { Table } from 'antd';
import 'moment-timezone';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GetContractsParams } from '../api';
import { useContracts } from '../hooks/use-contracts';
import Header from './header';

type Props = {
  className?: string;
  userRecordId?: string;
};

const ContractList = ({ className, userRecordId }: Props) => {
  const initialParams = useMemo<GetContractsParams>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      userRecordId,
      page: searchParams.get('page') ? parseInt(searchParams.get('page') as string, 10) : 1,
      per_page: searchParams.get('per_page') ? parseInt(searchParams.get('per_page') as string, 10) : 10,
      q: searchParams.get('q') || undefined,
      active: searchParams.get('active') ? searchParams.get('active') === '1' : undefined,
      signed: searchParams.get('signed') ? searchParams.get('signed') === '1' : undefined,
      sort_by: searchParams.get('sort_by') || undefined,
      sort_direction: (searchParams.get('sort_direction') as 'asc' | 'desc') || undefined,
    } as GetContractsParams;
  }, [userRecordId]);

  const [params, setParams] = useState<GetContractsParams>(initialParams);

  const { columns, contracts, loading, meta } = useContracts(params);

  // Mapping table column dataIndex to API sorting fields
  const columnSortMapping: { [key: string]: string } = {
    id: 'id',
    userRecordId: 'first_name',
    start: 'starts_at',
    end: 'ends_at',
    sentAt: 'sent_at',
    signedAt: 'sign_date',
    createdAt: 'date_created',
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let sort_by: GetContractsParams['sort_by'];
    let sort_direction: GetContractsParams['sort_direction'];
    if (!Array.isArray(sorter)) {
      sort_by = (columnSortMapping[sorter.field] as GetContractsParams['sort_by']) || undefined;
      if (sorter.order === 'ascend') {
        sort_direction = 'asc';
      } else if (sorter.order === 'descend') {
        sort_direction = 'desc';
      }
    }
    setParams((prev) => ({
      ...prev,
      page: pagination.current,
      per_page: pagination.pageSize,
      sort_by,
      sort_direction,
    }));
  };

  return (
    <TableView className={className}>
      <Header
        onSearchChange={(q: string) => setParams((prev) => ({ ...prev, q, page: 1 }))}
        onFilterChange={(filter) => setParams((prev) => ({ ...prev, ...filter, page: 1 }))}
        currentSearch={params.q}
        currentFilter={
          params.signed !== undefined
            ? params.signed
              ? 'signed'
              : 'unsigned'
            : params.active !== undefined
            ? params.active
              ? 'active'
              : 'inactive'
            : 'all'
        }
      />
      <Table
        dataSource={contracts}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: params.page,
          pageSize: params.per_page,
          total: meta?.total,
          position: ['bottomRight'],
          showSizeChanger: true,
        }}
        rowClassName={(contract) => (contract.sentAt ? '' : 'pending-row')}
        onChange={handleTableChange}
      />
    </TableView>
  );
};

export default styled(ContractList)``;
