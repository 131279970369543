import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getContractTemplate } from '../api';

export const useContractTemplate = (contractTemplateId: string) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const isEnabled = contractTemplateId !== 'new';

  const { data: contractTemplate = null, isLoading } = useQuery({
    queryKey: ['get-contract-template', activeDepartmentId, contractTemplateId],
    queryFn: () => getContractTemplate(contractTemplateId),
    enabled: isEnabled,
  });

  return {
    loading: isEnabled ? isLoading : false,
    contractTemplate,
  };
};
