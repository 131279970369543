import { V4WidgetResponse } from '../types/index';
import { getWidgets } from '../api/index';
import { useQuery } from '@tanstack/react-query';

export const useWidgets = (departmentId: string): {
  widgets: V4WidgetResponse[];
  loading: boolean;
} => {
  const { data: widgets = [], isLoading: loading } = useQuery({
    queryKey: ['get-widgets', departmentId],
    queryFn: () => getWidgets(),
  });

  return {
    loading,
    widgets: (widgets as V4WidgetResponse[]).filter((w) => w.slug !== '1'), // @todo - remove filter when old widgets are deleted from the DB
  };
};
