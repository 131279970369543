import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { handleError, isDescendant, isFeatureEnabled, lioWeekdays, valueForSearch } from '@/utils';
import { Affix, Button, Form, Input, Modal, Select, Switch, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import DayRightClick from './DayRightClick';

const { Option, OptGroup } = Select;

interface Props {
  className?: string;
  refSubHeader: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  shiftNotApprovedIds: string[];
  shiftsToApprove: string[];
}

const SubHeader: React.FC<Props> = ({
  className,
  refSubHeader,
  refPageContainer,
  shiftNotApprovedIds,
  shiftsToApprove,
}) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    picker,
    days,
    hours,
    filterOptions,
    selectedDate,
    taskScheduler,
    monthly_selected_elements,
    monthly_selecting_elements,
  } = useSelector(
    ({
      picker,
      days,
      hours,
      filterOptions,
      selectedDate,
      taskScheduler,
      monthly_selected_elements,
      monthly_selecting_elements,
    }: InitialStateType) => ({
      picker,
      selectedDate,
      days,
      hours,
      filterOptions,
      taskScheduler,
      monthly_selected_elements,
      monthly_selecting_elements,
    }),
    shallowEqual,
  );

  const selectedDateRef = useRef<moment.Moment>(selectedDate);
  selectedDateRef.current = selectedDate;

  const history = useHistory();
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { state: appContextState } = useContext(AppContext);
  const {
    activeSection,
    activeDepartment,
    departments,
    sections,
    skills,
    tasks,
    users,
    dayoffs,
    resources,
    userCategories,
    features,
  } = appContextState;
  const [affixed, setAffixed] = useState(false);
  const [columnWidth, setColumnWidth] = useState<number>(0);
  const { pathname } = useLocation();
  const month_view = pathname.startsWith('/app/hours/manage/month');
  const { state: authContextState } = useContext(AuthContext);
  const { userDetails } = authContextState;

  moment.tz.setDefault('Atlantic/Reykjavik');
  moment.updateLocale(i18n.language, {
    week: {
      dow: 1,
    },
  });

  const keyDownHandler = (e: any) => {
    const { keyCode } = e;
    if ([37, 39].includes(keyCode)) {
      if (
        !isDescendant(
          document.getElementsByClassName('app-hours-container')[0] as HTMLElement,
          document.activeElement as HTMLElement,
        )
      ) {
        onArrowClick(keyCode === 37 ? -1 : 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const resizeHandler = () => {
    if (window.innerWidth > 1024) {
      const width = (window.innerWidth - 240) / hours?.length || 1;
      setColumnWidth(width);
    } else {
      setColumnWidth((1024 - 240) / hours?.length || 1);
    }
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [hours?.length]);

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      if (element) {
        element.dispatchEvent(new Event('scroll'));
      }
    });
  };

  const handdleTagsOnChange = (value: string[]) => {
    hoursDispatch({
      type: 'SET_FILTER_OPTIONS',
      payload: value,
    });
    hoursDispatch({
      type: 'UPDATE_FILTERED_SHIFTS',
      payload: { department: activeDepartment },
    });
  };

  const onArrowClick = (value: number) => {
    const date = moment(selectedDateRef.current).add(value, picker);

    if (picker === 'day' && !(activeDepartment?.scheduleParams as any)?.[lioWeekdays[date.weekday()]]) {
      if (value > 0) {
        onArrowClick(value + 1);
      } else {
        onArrowClick(value - 1);
      }
      return;
    }
    const formattedDate = date.format('YYYY-MM-DD');
    history.push(`/app/hours/manage/${picker}/${formattedDate}`);
  };

  const [dayComments, setDayComments] = useState<any[]>([]);

  useEffect(() => {
    getDayComments();
  }, []);

  const getDayComments = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/schedule/day-comments`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setDayComments(data);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const [form] = useForm();

  const onManageComment = (values: any, date: string, comment: any) => {
    if (!comment) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/schedule/day-comments`,
          {
            ...values,
            day: date,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then((data) => {
          getDayComments();
          Modal.destroyAll();
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/schedule/day-comments/${comment.id}`,
          {
            ...values,
            day: date,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then((data) => {
          getDayComments();
          Modal.destroyAll();
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onDeleteComment = (comment: any) => {
    if (!comment) {
      Modal.destroyAll();
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/schedule/day-comments/${comment.id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then((data) => {
        getDayComments();
        Modal.destroyAll();
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const manageComment = (date: string, comment: any) => {
    if (comment) {
      form.setFieldsValue({
        comment: comment.comment,
        public: comment.public,
      });
    } else {
      form.setFieldsValue({
        comment: null,
        public: true,
      });
    }
    Modal.confirm({
      title: `${t('GLOBAL.DAY_COMMENT')} : ${moment(date).format('L')}`,
      closable: true,
      maskClosable: true,
      icon: null,
      content: (
        <Form
          form={form}
          onFinish={(values: any) => onManageComment(values, date, comment)}
          initialValues={{ public: true }}
        >
          <Form.Item name="comment" style={{ marginBottom: 10 }}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
            <label>{t('GLOBAL.PUBLIC_DAY_COMMENT')}</label>
            <Form.Item name="public" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
            <Button type="primary" danger onClick={() => onDeleteComment(comment)}>
              {comment ? t('GLOBAL.DELETE') : t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" onClick={() => form.submit()}>
              {comment ? t('GLOBAL.EDIT') : t('GLOBAL.ADD_DAY_COMMENT')}
            </Button>
          </div>
        </Form>
      ),
      okButtonProps: {
        hidden: true,
      },
      cancelButtonProps: {
        hidden: true,
      },
    });
  };

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
    >
      <div>
        <div ref={refSubHeader} className={`${className} ${picker} ${affixed ? 'affixed' : ''}`}>
          <div className="left">
            {!affixed && (
              <div className="search-col no-print">
                <Select
                  mode="multiple"
                  style={{ width: '100%', height: 30 }}
                  placeholder={t('GLOBAL.SEARCH')}
                  maxTagTextLength={3}
                  maxTagCount={2}
                  defaultValue={filterOptions}
                  suffixIcon={<i className="icon-search" />}
                  onChange={handdleTagsOnChange}
                  filterOption={(input, option) => {
                    if (option) {
                      if (!option.label) {
                        let children = option.children;
                        if (Array.isArray(children)) {
                          children = children.join();
                        }
                        return valueForSearch(children).includes(valueForSearch(input));
                      }
                    }
                    return false;
                  }}
                >
                  {!!users?.length && (
                    <OptGroup label={t('GLOBAL.USERS')}>
                      {users.map((user) => (
                        <Option key={`user.${user.recordId}`} value={`user.${user.recordId}`}>
                          {user.displayName}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                  {!!dayoffs?.length && (
                    <OptGroup label={t('GLOBAL.ABSENCES')}>
                      {dayoffs.map((dayoff) => (
                        <Option key={`dayoff.${dayoff.id}`} value={`dayoff.${dayoff.id}`}>
                          {dayoff.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              </div>
            )}
          </div>
          <div className="middle">
            {picker !== 'day' &&
              days.map((day, dayIndex) => {
                const startHour = activeDepartment?.scheduleParams?.default_shift_start_time || 0;
                const endHour = activeDepartment?.scheduleParams?.default_shift_end_time || 0;
                const { date } = day;
                const shiftDate = moment.unix(date).set({ hours: startHour });
                const start = shiftDate.unix();
                const end = shiftDate.set({ hours: endHour }).unix();
                const today = moment.unix(day.date).isSame(moment(), 'day');
                const holiday = !!day?.publicHoliday;
                const is_weekend = day.dayWeek === '6' || day.dayWeek === '7';
                const comment = dayComments.find(
                  (dayComment) => moment(dayComment.day).format('YYYY-MM-DD') === day.date_string,
                );

                return (
                  <DayRightClick key={day.date} dayIndex={dayIndex}>
                    <div
                      className={`period ${picker} ${holiday ? 'holiday' : ''} ${today ? 'today' : ''} ${
                        is_weekend && 'weekend'
                      } ${comment ? 'comment' : ''}`}
                    >
                      <div className="date">
                        <span className="date-time">{shiftDate.format('D')}</span>
                        <span className="date-day">{shiftDate.format('dd')}</span>
                        {(() => {
                          const comment = dayComments.find(
                            (dayComment) => moment(dayComment.day).format('YYYY-MM-DD') === day.date_string,
                          );

                          return (
                            <div onClick={() => manageComment(day.date_string, comment)} style={{ cursor: 'pointer' }}>
                              <Tooltip
                                overlay={
                                  comment ? (
                                    <div>
                                      {comment.public ? <i className="icon-globe" /> : <i className="icon-lock" />}
                                      {comment.comment}
                                    </div>
                                  ) : (
                                    t('GLOBAL.ADD_DAY_COMMENT')
                                  )
                                }
                              >
                                <i className={comment ? 'icon-commenting-o' : 'icon-plus'} />
                              </Tooltip>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  </DayRightClick>
                );
              })}
          </div>
          <div className="right" style={{ width: isFeatureEnabled(features, FEATURES.CLOCKING) ? 225 : 150 }}>
            <div className="period right-col">
              <span>{t('SCHEDULE.MONTHLY_VIEW.PLANNED_HOURS')}</span>
            </div>
            <div className="period right-col">
              <span>{t('SCHEDULE.MONTHLY_VIEW.DAYOFFS_BALANCE')}</span>
            </div>
            {isFeatureEnabled(features, FEATURES.CLOCKING) && userDetails?.params?.access?.clockings && (
              <div className="period right-col">
                <span>{t('SCHEDULE.MONTHLY_VIEW.CLOCKING')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Affix>
  );
};

const SubHeaderStyled = styled(React.memo(SubHeader))`
  display: flex;
  position: relative;
  height: 60px;
  z-index: 11;

  &.monthly_selected_elements {
    display: none;
  }

  &.month {
    &.affixed {
      max-width: 100%;
      overflow: hidden;
    }
  }

  &.affixed {
    height: 60px;
    z-index: 11;
  }

  .period {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top: 1px solid ${Colors.blueLight};
    background: #f7f9ff;
    border-right: 1px solid ${Colors.blueLight};
    border-bottom: 1px solid ${Colors.blueLight};
    flex: 1;
  }

  .weekend {
    background-color: #efefef;
  }

  > div {
    &.left {
      background: #f7f9ff;
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-top: 1px solid ${Colors.blueLight};
      border-right: 1px solid ${Colors.blueLight};
      width: 12%;
      padding: 10px;
      z-index: 3;
      border-bottom: 1px solid ${Colors.blueLight};

      @media print {
        width: 120px;
        font-size-adjust: 0.5;
      }

      .search-col {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .ant-select-multiple {
          white-space: nowrap;
          overflow: auto;
          height: 30px;
          width: 100%;
        }
      }
      .subheader-shortcuts {
        display: flex;
        justify-content: space-between;

        .left {
          button {
            background-color: white;
            box-shadow: 0 0 10px rgb(0 0 0 / 10%);
            margin-right: 5px;
          }
        }

        button {
          border-radius: 2px;
          padding: 2px 5px;
        }
      }
    }

    &.middle {
      display: flex;
      flex-grow: 1;

      > .period {
        &.counter-column {
          background-color: white;
          border: none;
          border-bottom: 1px solid ${Colors.blueLight};
        }

        &.comment {
          background-color: #00a65117;
        }

        &.today {
          background-color: #f8e5d6;

          &.comment {
            background-color: #00a65126;
          }
        }

        .holiday {
          color: grey;
          font-style: italic;
          display: block;
        }

        .date {
          font-weight: bold;
          width: 100%;
          text-align: center;
          z-index: 0;
          display: flex;
          flex-direction: column;
        }

        .date-day {
          text-transform: uppercase;
          color: #808895;
          font-weight: lighter;
        }

        &.out-of-bounds {
          background: ${Colors.greyLight} !important;
        }
      }
    }

    &.right {
      display: flex;

      .right-col {
        text-align: center;
        background-color: #f4fff6;
        font-size: 11px;
      }
    }
  }

  *::-moz-selection {
    background: transparent;
  }

  *::selection {
    background: transparent;
  }
`;

export default SubHeaderStyled;
