import api from '@/api';
import { V4Document, V4ManageDocumentPayload } from '@/pages/app/documents/documents/types/documents.types';
import { V4DocumentVariables } from '../../types/index.types';

export type GetDocumentsParams = {
  userRecordId?: string;
  q?: string;
  sort_by?: 'id' | 'title' | 'sent_at' | 'signature_required' | 'expiry_date' | 'created_at';
  sort_direction?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
};

export type GetDocumentsResponse = {
  data: V4Document[];
  meta: {
    total: number;
    total_pages: number;
  };
};

export const getDocuments = async (params: GetDocumentsParams) => {
  const queryParams = new URLSearchParams();

  if (params.userRecordId) queryParams.append('userRecordId', params.userRecordId);
  if (params.q) queryParams.append('q', params.q);
  if (params.sort_by) queryParams.append('sort_by', params.sort_by);
  if (params.sort_direction) queryParams.append('sort_direction', params.sort_direction);
  if (params.page) queryParams.append('page', String(params.page));
  if (params.per_page) queryParams.append('per_page', String(params.per_page));

  if (typeof window !== 'undefined') {
    window.history.replaceState(null, '', `?${queryParams.toString()}`);
  }

  const { response } = await api<GetDocumentsResponse>(`documents/general?${queryParams.toString()}`);
  return response;
};

export const getDocument = async (documentId?: string) => {
  const { response } = await api<{ data: V4Document }>(`documents/general/${documentId}`);
  return response.data;
};

export const sendDocument = async (documentId: string) => {
  const { response } = await api(`documents/general/${documentId}/send`, { method: 'POST' });
  return response;
};

export const duplicateDocument = async (documentId: string) => {
  const { response } = await api(`documents/general/duplicate`, {
    method: 'POST',
    body: JSON.stringify({ documentId }),
  });
  return response;
};

export const createDocument = async (payload: V4ManageDocumentPayload) => {
  const { response } = await api(`documents/general`, { method: 'POST', body: JSON.stringify(payload) });
  return response;
};

export const editDocument = async (documentId: string, payload: V4ManageDocumentPayload) => {
  const { response } = await api(`documents/general/${documentId}`, { method: 'PATCH', body: JSON.stringify(payload) });
  return response;
};

export const deleteDocument = async (documentId: string) => {
  const { response } = await api(`documents/general/${documentId}`, { method: 'DELETE' });
  return response;
};

export const getDocumentVariables = async () => {
  const { response } = await api<{ data: V4DocumentVariables }>('documents/general/variables');
  return response.data;
};
