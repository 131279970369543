import React, { FC } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { V4BirthdayResponse } from '../../types';

type BirthdaysProps = {
  birthdays?: V4BirthdayResponse[];
  className?: string;
};

const Birthdays: FC<BirthdaysProps> = ({ birthdays, className }) => {
  if (!birthdays || birthdays?.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Tooltip
        placement={'bottom'}
        overlay={
          <div>
            {birthdays.map((birthday) => (
              <span>
                {birthday.employee.fullName} {birthday.employee.birthdayAge} <br />
              </span>
            ))}
          </div>
        }
      >
        <span>🎂</span>
      </Tooltip>
    </div>
  );
};

export const BirthdaysStyled = styled(Birthdays)``;
