import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button, Divider, Form, Modal, Select } from 'antd';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { store as manageStore } from '../hours/manage/redux/store';

interface Props {
  className?: string;
}

const ModalAccountDisabled: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeDepartment, departments, loadingDepartments },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const history = useHistory();

  const myDepartments = departments?.filter((department) => department.role !== 'USER');

  const handleDepartmentChange = (value: string) => {
    if (value && activeDepartmentId !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  return (
    <Modal className={className} forceRender={true} closable={false} visible footer={null}>
      <h1 style={{ color: colors.red }}>
        <Trans
          i18nKey={
            activeDepartment?.accountType == 'BANKRUPT' ? 'ACCOUNT.MODAL_BANKRUPT.TITLE' : 'ACCOUNT.MODAL_BAILIFF.TITLE'
          }
        />
      </h1>
      <p style={{ fontSize: 14 }}>
        <Trans
          i18nKey={
            activeDepartment?.accountType == 'BANKRUPT'
              ? 'ACCOUNT.MODAL_BANKRUPT.CONTENT'
              : 'ACCOUNT.MODAL_BAILIFF.CONTENT'
          }
          components={{ b: <b />, br: <br /> }}
        />
      </p>
      <Form layout="vertical">
        <Divider />
        {myDepartments.length > 1 && (
          <Form.Item label={t('FORMS.ACCOUNT_PLACEHOLDER')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              disabled={loadingDepartments}
              loading={loadingDepartments}
              placeholder={t('FORMS.ACCOUNT_PLACEHOLDER')}
              onChange={handleDepartmentChange}
            >
              {myDepartments?.map((department) => {
                const { id, company } = department;
                return (
                  <Select.Option key={`dept_${id}`} value={`${id}`}>
                    {company}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Button danger size="large" style={{ width: '100%' }} onClick={handleLogout}>
          {t('GLOBAL.LOGOUT')}
        </Button>
      </Form>
    </Modal>
  );
};

export default styled(ModalAccountDisabled)``;
