import { Button, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onCreateNewDocument = () => {
    history.push('/app/documents/documents/templates/new');
  };

  return (
    <header className={className}>
      <div className="left">
        <h2>{t('general.document-templates')}</h2>
      </div>
      <div className="right">
        <Input allowClear placeholder={t('general.search')} suffix={<i className="icon-search"></i>} />
        <Button type="primary" onClick={onCreateNewDocument}>
          <i className="icon-plus" />
          {t('documents.documents.templates.create-document-template')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
