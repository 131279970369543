import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { getContract } from '../api';

export const useContract = (contractId: string) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const isEnabled = contractId !== 'new';

  const { data: contract = null, isLoading } = useQuery({
    queryKey: ['get-contract', activeDepartmentId, contractId],
    queryFn: () => getContract(contractId),
    enabled: isEnabled,
  });

  return {
    loading: isEnabled ? isLoading : false,
    contract,
  };
};
