import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4Contract } from '../types/contracts.types';

interface Props {
  className?: string;
  contract: V4Contract;
}

const DownloadContract: React.FC<Props> = ({ className, contract }) => {
  const { t } = useTranslation();

  const onDownloadContract = () => {
    window.open(contract.pdfUrl, '_blank');
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.download-contract')}>
        <Button type="ghost" className="action" onClick={onDownloadContract}>
          <i className="icon-download" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DownloadContract)``;
