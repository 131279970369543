import api from '@/api';
import {
  V4DocumentTemplate,
  V4ManageDocumentTemplatePayload,
} from '@/pages/app/documents/documents/templates/types/document-templates.types';

export const getDocumentTemplates = async () => {
  const { response } = await api<{ data: V4DocumentTemplate[] }>('documents/templates/general');
  return response.data;
};

export const getDocumentTemplate = async (documentTemplateId: string) => {
  const { response } = await api<{ data: V4DocumentTemplate }>(`documents/templates/general/${documentTemplateId}`);
  return response.data;
};

export const createDocumentTemplate = async (payload: V4ManageDocumentTemplatePayload) => {
  const { response } = await api<{ data: V4DocumentTemplate }>('documents/templates/general', {
    body: JSON.stringify(payload),
    method: 'POST',
  });
  return response.data;
};

export const editDocumentTemplate = async (documentTemplateId: string, payload: V4ManageDocumentTemplatePayload) => {
  const { response } = await api<{ data: V4DocumentTemplate }>(`documents/templates/general/${documentTemplateId}`, {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
  return response.data;
};

export const deleteDocumentTemplate = async (documentTemplateId: string) => {
  const { response } = await api(`documents/templates/general/${documentTemplateId}`, { method: 'DELETE' });
  return response;
};
