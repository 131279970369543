import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { CreateTemplatePayload } from '../types';
import { useTurnoverTemplateCreate } from '../hooks';

type CreateTemplateModalProps = {
  isVisible: boolean;
  onVisibilityChange: (newVisibility: boolean) => void;
  className?: string;
};

const CreateTemplateModal: FC<CreateTemplateModalProps> = ({ className, isVisible, onVisibilityChange }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { createTemplate, isLoading } = useTurnoverTemplateCreate();

  const onFinish = (values: CreateTemplatePayload) => {
    createTemplate(values);
    onVisibilityChange(false);
    form.resetFields()
  };

  return (
    <Modal
      className={className}
      title={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
      maskClosable
      destroyOnClose={true}
      visible={isVisible}
      onCancel={() => {
        form.resetFields();
        onVisibilityChange(false);
      }}
      onOk={() => form.submit()}
      okText={t('GLOBAL.CREATE')}
      cancelButtonProps={{ danger: true }}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')}
          rules={[{ required: true }]}
        >
          <Input
            autoFocus
            allowClear
            size="large"
            placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CreateTemplateModalStyled = styled(CreateTemplateModal)``;
