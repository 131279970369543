import api from '@/api';
import { IV4Dimona } from '@/types/dimona.model';

export const getDimonas = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: IV4Dimona[] }>(`dimonas?start=${startDate}&end=${endDate}`);
  return response.data;
};

export const getDimonasPending = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: IV4Dimona[] }>(`dimonas/pending?start=${startDate}&end=${endDate}`);
  return response.data;
};

export const getDimonasError = async (startDate: string, endDate: string) => {
  const { response } = await api<{ data: IV4Dimona[] }>(`dimonas/errors?start=${startDate}&end=${endDate}`);
  return response.data;
};
