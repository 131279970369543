import React, { FC } from 'react';
import styled from 'styled-components';

type PercentageWidgetProps = {
  color: string;
  percentage: number;
  className?: string;
};

const PercentageWidget: FC<PercentageWidgetProps> = ({ percentage, color, className }) => {
  return (
    <div className={className} style={{ color }}>
      <span>{percentage} %</span>
    </div>
  );
};

const PercentageWidgetStyled = styled(PercentageWidget)`
  height: calc(100% - 35px);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: bolder;
`;

export default PercentageWidgetStyled;
