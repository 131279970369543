import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError, isTrial } from '@/utils';
import { Button, Form, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CalloutStyled from '../../components/Callout';
import SettingsForm from '../../components/SettingsForm';
import ModalManageNotificationsForAllUsers from './components/ModalManageNotificationsForAllUsers';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const GeneralNotifications: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalManageNotificationsForAllUsersVisible, setModalManageNotificationsForAllUsersVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onManageNotificationsForAllUsers = () => {
    setModalManageNotificationsForAllUsersVisible(true);
  };

  const elements = [
    {
      title: t('SETTINGS.ACCOUNT.GENERAL.TITLES.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.GENERAL.NOTIFICATIONS.SMS_NOTIFICATIONS.LABEL'),
          description: t('SETTINGS.GENERAL.NOTIFICATIONS.SMS_NOTIFICATIONS.DESCRIPTION'),
          name: ['general', 'sms_active'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.sms_active} />,
          show: !isTrial(activeDepartment!),
          showCallout: true,
          callout: <CalloutStyled>{t('SETTINGS.GENERAL.NOTIFICATIONS.SMS_NOTIFICATIONS.CALLOUT')}</CalloutStyled>,
        },
        {
          label: t('SETTINGS.GENERAL.NOTIFICATIONS.ALL_USERS_NOTIFICATIONS.LABEL'),
          description: t('SETTINGS.GENERAL.NOTIFICATIONS.ALL_USERS_NOTIFICATIONS.DESCRIPTION'),
          name: null,
          style: { margin: 0 },
          element: <Button onClick={onManageNotificationsForAllUsers}>{t('GLOBAL.MANAGE')}</Button>,
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.GENERAL.NOTIFICATIONS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalManageNotificationsForAllUsers
        visible={modalManageNotificationsForAllUsersVisible}
        setVisible={setModalManageNotificationsForAllUsersVisible}
      />
    </div>
  );
};

export default styled(GeneralNotifications)``;
