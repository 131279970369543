import React from 'react';
import styled from 'styled-components';
import { V4Document } from '../types/documents.types';
import ApproveDocument from './approve-document';
import DeleteDocument from './delete-document';
import DetailsDocument from './details-document';
import DuplicateDocument from './duplicate-document';
import EditDocument from './edit-document';
import ResendDocument from './resend-document';

interface Props {
  className?: string;
  document: V4Document;
}

const DocumentActions: React.FC<Props> = ({ className, document }) => {
  return (
    <div className={className}>
      {!document.sentAt && <ApproveDocument documentId={document.id} />}
      {document.sentAt && <ResendDocument documentId={document.id} />}
      <EditDocument document={document} />
      <DetailsDocument document={document} />
      <DuplicateDocument document={document} />
      <DeleteDocument document={document} />
    </div>
  );
};

export default styled(DocumentActions)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
