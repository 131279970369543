import TableView from '@/layouts/TableView';
import { Table } from 'antd';
import 'moment-timezone';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Header from './components/header';
import { useDocumentTemplates } from './hooks/use-document-templates';

type Props = {
  className?: string;
};

const DocumentTemplatesList = ({ className }: Props) => {
  const { t } = useTranslation();

  const { columns, documentTemplates, loading } = useDocumentTemplates();

  return (
    <TableView className={className}>
      <Helmet>
        <title>{t('general.document-templates')} - Shyfter</title>
      </Helmet>
      <Header />
      <Table
        dataSource={documentTemplates}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{ position: ['bottomRight'] }}
        className="row-clickable"
      />
    </TableView>
  );
};

export default styled(DocumentTemplatesList)``;
