import React, { FC } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { weatherIconMapper } from '@/utils';
import { V4WeatherResponse } from '../../types';

type WeatherProps = {
  weather?: V4WeatherResponse;
  className?: string;
};

const Weather: FC<WeatherProps> = ({ weather, className }) => {
  if (!weather) {
    return null;
  }

  return (
    <div className={className}>
      <Tooltip placement={'bottom'} overlay={`${weather.description}: ${Math.round(weather.maximumTemperature)}°`}>
        {weather && <i className={weatherIconMapper(weather.icon)} />}
      </Tooltip>
    </div>
  );
};

export const WeatherStyled = styled(Weather)`
  .weather-icon {
    width: 25px;
  }
`;
