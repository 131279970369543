import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deleteDocumentTemplate } from '../api';
import { V4DocumentTemplate } from '../types/document-templates.types';

interface Props {
  className?: string;
  documentTemplate: V4DocumentTemplate;
}

const DeleteDocumentTemplate: React.FC<Props> = ({ className, documentTemplate }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['delete-document-template', documentTemplate.id],
    mutationFn: () => deleteDocumentTemplate(documentTemplate.id),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-document-templates'] }),
  });

  const onWantToDeleteDocumentTemplate = () => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('documents.documents.templates.delete-document-template-confirmation.title', {
        name: documentTemplate.name,
      }),
      icon: null,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('documents.documents.templates.delete-document-template-confirmation.content', {
              name: documentTemplate.name,
            }),
          }}
        ></span>
      ),
      maskClosable: true,
      okText: t('general.delete'),
      onOk: () => {
        mutate();
      },
    });
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.templates.delete-contract-template')}>
        <Button type="primary" danger className="action" onClick={onWantToDeleteDocumentTemplate} loading={loading}>
          <i className="icon-trash-empty" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DeleteDocumentTemplate)``;
