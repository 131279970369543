import { client } from '@/ReactQueryProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import { copyDailyTurnover, getDailyTurnover, updateDailyTurnover } from '../api';
import { CopyDailyTurnoverPayload, UpdateDailyTurnoverPayload, V4DailyTurnoverResponse } from '../types';

export const useDailyTurnover = (
  startDate: string,
  endDate: string,
  sectionId: string | null,
): {
  dailyTurnover?: V4DailyTurnoverResponse;
  loading: boolean;
} => {
  const { data: dailyTurnover, isLoading: loading } = useQuery({
    queryKey: ['get-daily-turnover', startDate, endDate, sectionId],
    queryFn: () => getDailyTurnover(startDate, endDate, sectionId),
  });

  return {
    loading,
    dailyTurnover,
  };
};

export const useUpdateDailyTurnover = (startDate: string, endDate: string, sectionId: string | null) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-daily-turnover'],
    mutationFn: (payload: UpdateDailyTurnoverPayload) => updateDailyTurnover(payload, startDate, endDate, sectionId),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-daily-turnover'] }),
  });

  return {
    updateTurnover: mutate,
    isLoading,
  };
};

export const useCopyDailyTurnover = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['copy-daily-turnover'],
    mutationFn: (payload: CopyDailyTurnoverPayload) => copyDailyTurnover(payload),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-daily-turnover'] }),
  });

  return {
    copyTurnover: mutate,
    isLoading,
  };
};
