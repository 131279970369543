import React from 'react';
import moment from 'moment/moment';
import i18n from '../../../../i18n';
import Tag from '../components/Tag';
import ClockingTime from '../components/ClockingTime';
import ClockingStatus from '../components/ClockingStatus';
import ShiftVerificationHoursColumn from '../components/ShiftVerificationHoursColumn';
import { Avatar, Button } from 'antd';
import { capitaliseEveryWord, convertUtcToTimezoneDateAndTime } from '@/utils';
import {
  GetUserCategoryCostFunction,
  GetContractRenewalFunction,
  GetSectionsCostFunction,
  GetPlannedHoursVsRealHours,
  GetNumberOfAbsencesPerDay,
  GetShiftsVerification,
  GetSicknessPerUserCategory,
  GetOpenAbsencesRequest,
  GetShiftsWithoutDimona,
  GetOpenShiftSwapRequests,
  GetScheduledShifts,
  GetUpcomingBirthdays,
  GetSicknessPercentagePerMonth,
  GetUpcomingFreeShifts,
  GetClockings,
  GetUnsignedDocumentsContracts,
  GetTurnover,
  GetDimonasWithoutShifts,
  GetTurnoverTwin, GetUpcomingAbsences,
} from '../types';

// --------- CHART DATA PARSERS
export const getUserCategoryCost: GetUserCategoryCostFunction = (data, primaryColor, secondaryColor) => {
  const { labels, bruttoData, costEmployer } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.name);
      acc.bruttoData.push(curr.costBrutto);
      acc.costEmployer.push(curr.costEmployer);

      return acc;
    },
    {
      labels: [] as string[],
      bruttoData: [] as number[],
      costEmployer: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.GROSS'),
        data: bruttoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
      {
        label: i18n.t('GLOBAL.EMPLOYER_COST'),
        data: costEmployer,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: secondaryColor,
      },
    ],
  };
};

export const getSectionsCost: GetSectionsCostFunction = (data, primaryColor, secondaryColor) => {
  const { labels, bruttoData, costEmployer } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.name);
      acc.bruttoData.push(curr.costBrutto);
      acc.costEmployer.push(curr.costEmployer);

      return acc;
    },
    {
      labels: [] as string[],
      bruttoData: [] as number[],
      costEmployer: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.GROSS'),
        data: bruttoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
      {
        label: i18n.t('GLOBAL.EMPLOYER_COST'),
        data: costEmployer,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: secondaryColor,
      },
    ],
  };
};

export const getPlannedHoursVsRealHours: GetPlannedHoursVsRealHours = (data, primaryColor, secondaryColor) => {
  const { labels, plannedHours, realHours } = data.reduce(
    (acc, curr) => {
      acc.labels.push(moment(curr.date).format('ddd DD'));
      acc.plannedHours.push(curr.plannedHours || 0);
      acc.realHours.push(curr.realHours || 0);

      return acc;
    },
    {
      labels: [] as string[],
      plannedHours: [] as number[],
      realHours: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.PLANNED_HOURS'),
        data: plannedHours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.REAL_HOURS'),
        data: realHours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: secondaryColor,
      },
    ],
  };
};

export const getNumberOfAbsencesPerDay: GetNumberOfAbsencesPerDay = (data, primaryColor) => {
  const { labels, absencesCount } = data.reduce(
    (acc, curr) => {
      acc.labels.push(moment(curr.date).format('ddd DD'));
      acc.absencesCount.push(curr.absencesCount);

      return acc;
    },
    {
      labels: [] as string[],
      absencesCount: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.ABSENCES'),
        data: absencesCount,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
    ],
  };
};

export const getSicknessPerUserCategory: GetSicknessPerUserCategory = (data, primaryColor) => {
  const { labels, hours } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.categoryName);
      acc.hours.push(curr.totalNumberOfHours);

      return acc;
    },
    {
      labels: [] as string[],
      hours: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.TOTAL_HOURS'),
        data: hours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
    ],
  };
};

export const getSicknessPercentagePerMonth: GetSicknessPercentagePerMonth = (data, primaryColor) => {
  const { labels, percentagePeopleSick } = data.reduce(
    (acc, curr) => {
      acc.labels.push(`${moment(curr.month, 'MM').format('MMM')} ${curr.year}`);
      acc.percentagePeopleSick.push(curr.percentagePeopleSick);
      acc.totalPlannedUsers.push(curr.totalPlannedUsers);

      return acc;
    },
    {
      labels: [] as string[],
      percentagePeopleSick: [] as number[],
      totalPlannedUsers: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: '%',
        data: percentagePeopleSick,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
    ],
  };
};

export const getTurnover: GetTurnover = (data, startDate, primaryColor, secondaryColor) => {
  const { currentValues } = data.current.real.reduce(
    (acc, curr) => ({ currentValues: [...acc.currentValues, curr.real] }),
    { currentValues: [] as number[] },
  );
  const { previousValues } = data.previous.real.reduce(
    (acc, curr) => ({ previousValues: [...acc.previousValues, curr.real] }),
    { previousValues: [] as number[] },
  );

  const firstDay = moment(startDate).startOf('week');
  const lastDay = moment(startDate).endOf('week');
  const labels = Array.from({ length: lastDay.diff(firstDay, 'day') + 1 }).map((_, index) =>
      moment(firstDay).add(index, 'day').format('D MMM'),
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.CURRENT_TURNOVER'),
        data: currentValues,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: primaryColor,
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.PREVIOUS_TURNOVER'),
        data: previousValues,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: secondaryColor,
      },
    ],
  };
};

export const getTurnoverTwin: GetTurnoverTwin = (data, startDate, primaryColor, secondaryColor) => {
  const { realValues } = data.current.real.reduce(
    (acc, curr) => ({ realValues: [...acc.realValues, curr.real || NaN] }),
    { realValues: [] as number[] },
  );
  const { provisionalValues } = data.current.provisional.reduce(
    (acc, curr) => ({ provisionalValues: [...acc.provisionalValues, curr.provisional || NaN] }),
    { provisionalValues: [] as number[] },
  );

  const firstDay = moment(startDate).startOf('week');
  const lastDay = moment(startDate).endOf('week');
  const labels = Array.from({ length: lastDay.diff(firstDay, 'day') + 1 }).map((_, index) =>
      moment(firstDay).add(index, 'day').format('D MMM'),
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t(i18n.t('GLOBAL.REAL')),
        data: realValues,
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        fill: false,
        tension: 0.4,
      },
      {
        label: i18n.t(i18n.t('GLOBAL.PROVISIONAL')),
        data: provisionalValues,
        backgroundColor: secondaryColor,
        borderColor: secondaryColor,
        fill: false,
        borderDash: [10,5],
        tension: 0.4,
      },
    ],
  };
};

// --------- TABLE DATA PARSERS
export const getContractRenewal: GetContractRenewalFunction = (data, onContractClick, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Contract n°',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onContractClick(id)}>
            {id}
          </Button>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name}</span>
          </Button>
        ),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
      },
    ],
    data: data.map(({ contract, employee }) => ({
      id: contract.id,
      user: { name: employee.fullName, id: employee.id },
      startDate: convertUtcToTimezoneDateAndTime(contract.startDate, activeDepartment, 'll'),
      endDate: convertUtcToTimezoneDateAndTime(contract.endDate, activeDepartment, 'll'),
    })),
  };
};

export const getShiftsVerification: GetShiftsVerification = (data, onDateClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (date) => (
            <Button type="link" onClick={() => onDateClick(moment(date).format('Y-MM-DD'))}>
              <i className={'icon-link'} />
            </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Result',
        dataIndex: 'result',
        render: ({ hours, status }) => (
          <ShiftVerificationHoursColumn hours={hours} status={status} />
        ),
      },
    ],
    data: data.map(({ date, plannedHours, targetHours, type }) => ({
      id: date,
      date: capitaliseEveryWord(moment(date).format('dddd D MMMM Y')),
      result: {
        hours: targetHours - plannedHours,
        status: type,
      },
    })),
  };
};

export const getOpenAbsencesRequest: GetOpenAbsencesRequest = (data, onRequestClick, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onRequestClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Absence Type',
        dataIndex: 'absenceType',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, absenceType, employee, isFullday }) => ({
      id: id,
      date: `${convertUtcToTimezoneDateAndTime(
        startDate,
        activeDepartment,
        isFullday ? 'DD/MM' : 'DD/MM HH:mm',
      )} - ${convertUtcToTimezoneDateAndTime(endDate, activeDepartment, isFullday ? 'DD/MM' : 'DD/MM HH:mm')}`,
      absenceType: absenceType.name,
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getShiftsWithoutDimona: GetShiftsWithoutDimona = (data, onDimonaClick, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onDimonaClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, employee }) => ({
      id: id,
      date: capitaliseEveryWord(
        `${convertUtcToTimezoneDateAndTime(
          startDate,
          activeDepartment,
          'dddd D MMMM Y : HH:mm',
        )} - ${convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'HH:mm')}`,
      ),
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getOpenShiftSwapRequests: GetOpenShiftSwapRequests = (data, onShiftSwapClick, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onShiftSwapClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, employee }) => ({
      id: id,
      startDate: convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'D MMM HH:mm'),
      endDate: convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'D MMM HH:mm'),
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getScheduledShifts: GetScheduledShifts = (data, onShiftClick, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onShiftClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
      {
        title: 'Section',
        dataIndex: 'section',
        render: ({ name, color }) => name && <Tag name={name} color={color} />,
      },
    ],
    data: data.map(({ id, startDate, endDate, employee, section }) => ({
      id: id,
      date: `${convertUtcToTimezoneDateAndTime(
        startDate,
        activeDepartment,
        'HH:mm',
      )} - ${convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'HH:mm')}`,
      user: { name: employee.fullName, id: employee.id },
      section: {
        name: section?.name,
        color: section?.color,
      },
    })),
  };
};

export const getUpcomingBirthdays: GetUpcomingBirthdays = (data, onEmployeeClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name, avatarUrl, initials }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <Avatar src={avatarUrl} size={27}>{initials}</Avatar>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
      {
        title: 'Age',
        dataIndex: 'birthdayAge',
      },
    ],
    data: data.map(({ employee }) => ({
      id: employee.id,
      date: capitaliseEveryWord(moment(employee.birthday).format('dddd D MMMM')),
      birthdayAge: employee.birthdayAge,
      user: {
        id: employee.id,
        name: employee.fullName,
        avatarUrl: employee.avatarUrl,
        initials: employee.initials,
      },
    })),
  };
};

export const getUpcomingFreeShifts: GetUpcomingFreeShifts = (data, onShiftClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'link',
        render: (date) => (
          <Button type="link" onClick={() => onShiftClick(date)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Section',
        dataIndex: 'section',
        render: ({ name, color }) => name && <Tag color={color} name={name} />,
      },
    ],
    data: data.map(({ startDate, endDate, count, section }) => ({
      id: `${startDate}_${endDate}_${count}`,
      link: moment(startDate).format('Y-MM-DD'),
      date: `${capitaliseEveryWord(moment(startDate).format('dddd D MMMM Y : HH:mm'))} - ${moment(endDate).format(
        'HH:mm',
      )}${count > 1 ? ` (${count})` : ''}`,
      section: {
        name: section?.name,
        color: section?.color,
      }
    })),
  };
};

export const getClockings: GetClockings = (data, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        render: ({ startDate, endDate }) => <ClockingTime startDate={startDate} endDate={endDate} />,
      },
      {
        title: 'Start status',
        dataIndex: 'startStatus',
        render: (status) => <ClockingStatus status={status} clockingType={'start'} />,
      },
      {
        title: 'End Status',
        dataIndex: 'endStatus',
        render: (status) => <ClockingStatus status={status} clockingType={'end'} />,
      },
    ],
    data: data.map(({ id, startDate, endDate, startStatus, endStatus, employee }) => ({
      id: id,
      startStatus,
      endStatus,
      time: {
        startDate,
        endDate,
      },
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};

export const getDimonasWithoutShifts: GetDimonasWithoutShifts = (data, onShiftClick, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (date) => (
            <Button type="link" onClick={() => onShiftClick(date)}>
              <i className={'icon-link'} />
            </Button>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Start date',
        dataIndex: 'startDate',
      },
      {
        title: 'End date',
        dataIndex: 'endDate',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
            <Button type="link" onClick={() => {}}>
              <span className={'nameCell'}>{name ?? id}</span>
            </Button>
        ),
      },
      {
        title: 'Worker type',
        dataIndex: 'workerType',
      },
    ],
    data: data.map(({ id, startDate, endDate, employee, workerType }) => ({
      id: id,
      workerType,
      startDate: convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'll'),
      endDate: convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'll'),
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};

export const getUnsignedDocumentsContracts: GetUnsignedDocumentsContracts = (
  data,
  onDocumentClick,
  onEmployeeClick,
) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'link',
        render: ({ id, type }) => (
          <Button type="link" onClick={() => onDocumentClick(id, type)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
      {
        title: 'Sent at',
        dataIndex: 'sentAt',
      },
    ],
    data: data.map(({ id, title, type, employee, sentAt }) => ({
      id: id,
      link: {
        id,
        type,
      },
      title,
      type: i18n.t(type === 'contract' ? 'GLOBAL.CONTRACT' : 'GLOBAL.DOCUMENT'),
      sentAt: sentAt && moment(sentAt).format('LL'),
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};

export const getUpcomingAbsences: GetUpcomingAbsences = (data, activeDepartment) => {
  return {
    columns: [
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => {}}>
            <span className={'nameCell'}>{name ?? id}</span>
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Hours',
        dataIndex: 'hours',
      },
      {
        title: 'Absence type',
        dataIndex: 'absenceType',
        render: (absenceType) => <Tag color={'#d95c62'} name={absenceType}/>,
      },
    ],
    data: data.map(({ id, startDate, endDate, employee, absenceType: { name } }) => ({
      id: id,
      absenceType: name,
      date: convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'DD-MM-YYYY'),
      hours: `${convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'HH:mm')} - ${convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'HH:mm')}`,
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};