import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { V4Contract } from '../types/contracts.types';

interface Props {
  className?: string;
  contract: V4Contract;
}

const EditContract: React.FC<Props> = ({ className, contract }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onEditContract = () => {
    history.push(`/app/documents/contracts/${contract.id}`);
  };

  return (
    <div className={className}>
      <Tooltip
        title={
          contract.sentAt ? t('documents.contracts.view-contract-details') : t('documents.contracts.edit-contract')
        }
      >
        <Button type="ghost" className="action" onClick={onEditContract}>
          <i className={contract.sentAt ? 'icon-eye' : 'icon-edit'} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(EditContract)``;
