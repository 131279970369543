import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { uniqueId } from 'lodash';
import { isFeatureEnabled } from '@/utils';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@/types/features.model';
import { ColumnsType } from 'antd/es/table/interface';
import { WEEKDAYS, createNumberRangeArray } from '@/utils';
import { useTurnoverTemplate, useTurnoverTemplateUpdate } from '../hooks';
import {
  Breadcrumb as AntdBreadcrumb,
  Button,
  Input,
  Form,
  InputNumber,
  Table,
  message,
  Spin,
  Checkbox,
  Select,
} from 'antd';

type InputTableProps = {
  templateId: string;
  sectionEnabled: boolean;
  settings: {
    start_hour: number;
    end_hour: number;
    dynamic_hour: boolean;
  };
  className?: string;
};

type TemplateData = {
  id: string;
  day: string;
  sum: number;
};

type FormValues = {
  name: string;
  is_default: boolean;
  section_id: string;
  data: { [$key: string]: number };
};

const InputTable: FC<InputTableProps> = ({ className, templateId, sectionEnabled, settings }) => {
  const {
    state: { sections, features, activeDepartment },
  } = useContext(AppContext);

  const [columns, setColumns] = useState<ColumnsType<TemplateData>>([]);
  const [data, setData] = useState<TemplateData[]>([]);

  const history = useHistory();
  const [form] = useForm();
  const { t } = useTranslation();
  const { turnoverTemplate, loading } = useTurnoverTemplate(templateId);
  const { updateTemplate } = useTurnoverTemplateUpdate(templateId, () => {
    message.success(t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_UPDATED'));
    history.push('/app/settings/insights/turnover-templates');
  });

  const hours = createNumberRangeArray(
    settings.dynamic_hour ? 0 : settings.start_hour,
    settings.dynamic_hour ? 23 : settings.end_hour,
  );

  useEffect(() => {
    const defaultData = WEEKDAYS.map((day) => ({
      id: day,
      day: t(`GLOBAL.${day.toUpperCase()}`),
      sum: 0,
    }));

    setData(defaultData);
  }, []);

  useEffect(() => {
    if (!turnoverTemplate || !turnoverTemplate.details) return;

    const tableColumns = [
      {
        key: 'day',
        dataIndex: 'day',
        fixed: 'left',
      },
      ...hours.map((hour) => ({
        className: 'input-cell',
        key: uniqueId(),
        title: `${hour <= 9 ? `0${hour}` : hour}${t('GLOBAL.HOURS_SHORT')}`,
        render(_: any, record: TemplateData) {
          const value = form.getFieldValue(['data', `${record.id}_${hour}`]);
          return (
            <Form.Item name={['data', `${record.id}_${hour}`]}>
              <InputNumber
                className={`percentage-input ${value > 0 ? 'hasValue' : ''}`}
                type={'number'}
                min={0}
                max={100}
              />
            </Form.Item>
          );
        },
      })),
    ];

    if(activeDepartment?.scheduleParams?.staff_quota_calculation_method === 'insights_based') {
      tableColumns.splice(1, 0, {
        key: 'total',
        dataIndex: 'total',
        title: t('GLOBAL.TOTAL'),
        fixed: 'left',
        width: 70,
        render(_, record) {
          return <div className={record.sum > 100 ? 'over' : ''}>{Math.round(record.sum * 100) / 100}%</div>;
        },
      });
    }

    setColumns(tableColumns);
  }, [turnoverTemplate]);

  // SET DEFAULT FIELD VALUES + CALCULATE DEFAULT SUMS
  useEffect(() => {
    if (!turnoverTemplate) return;

    form.setFieldsValue({
      ...turnoverTemplate,
    });

    if (turnoverTemplate.details) {
      const newSums: any = {};

      turnoverTemplate.details.forEach(({ day_in_week, hour, value }) => {
        const day = WEEKDAYS[parseInt(day_in_week) - 1];

        newSums[day] = (newSums[day] || 0) + value;
        form.setFieldsValue({
          data: {
            [`${day}_${hour}`]: value,
          },
        });
      });

      setData((prev) => prev.map(({ id, day }) => ({ id, day, sum: newSums[id] || 0 })));
    }
  }, [turnoverTemplate]);

  const handleTotal = (_: any, values: FormValues) => {
    const newSums = Object.entries(values.data).reduce((acc, [key, value]) => {
      const [day] = key.split('_');
      acc[day] = (acc[day] || 0) + (value || 0);
      return acc;
    }, {} as any);

    setData((prev) => prev.map(({ id, day }) => ({ id, day, sum: newSums[id] })));
  };

  const onFinish = (values: FormValues) => {
    const newValues = Object.entries(values.data).reduce((acc, [key, value]) => {
      const [day, hour] = key.split('_');
      acc[day] = {
        ...acc[day],
        [hour]: value || 0,
      };
      return acc;
    }, {} as any);

    updateTemplate({
      name: values.name,
      sectionId: values.section_id || '',
      isDefault: values.is_default,
      details: newValues,
    });
  };

  return (
    <div className={className}>
      <AntdBreadcrumb separator={'>'}>
        <AntdBreadcrumb.Item
          className={'clickable-breadcrumb'}
          onClick={() => history.push('/app/settings/insights/turnover-templates')}
        >
          {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATES')}
        </AntdBreadcrumb.Item>
        <AntdBreadcrumb.Item>{turnoverTemplate?.name}</AntdBreadcrumb.Item>
      </AntdBreadcrumb>
      <div className="container">
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={handleTotal}>
            <Form.Item className={'default_checkbox'} name="is_default" valuePropName={'checked'}>
              <Checkbox>{t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.APPLY_DEFAULT')}</Checkbox>
            </Form.Item>
            <div className={'inputs-wrapper'}>
              <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
                <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" />
              </Form.Item>
              {isFeatureEnabled(features, FEATURES.SECTIONS) && sectionEnabled && (
                <Form.Item name="section_id" label={t('GLOBAL.SECTION')} valuePropName={'value'}>
                  <Select
                    size={'large'}
                    allowClear={true}
                    options={sections.map((s) => ({ value: s.id, label: s.name })) as any}
                    placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.SELECT_PLACEHOLDER')}
                  />
                </Form.Item>
              )}
            </div>
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={false}
              className="turnover-template-table"
              scroll={{ x: 'max-content' }}
            />
            <Button
              className="submit-btn"
              htmlType="submit"
              size="large"
              type="primary"
              loading={loading}
              disabled={loading}
            >
              {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.SAVE_TEMPLATE')}
            </Button>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export const InputTableStyled = styled(InputTable)`
  .container {
    background-color: white;
    padding: 20px;
  }

  .default_checkbox {
    margin-bottom: 0;
    width: fit-content;
    margin-left: auto;

    .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .inputs-wrapper {
    display: flex;
    gap: 10px;

    .ant-row {
      flex: 1;
      margin-bottom: 30px;
    }

    .ant-select {
      color: #626a7c;
    }
  }

  .ant-breadcrumb {
    margin-bottom: 20px;

    .clickable-breadcrumb {
      cursor: pointer;

      &:hover {
        color: rgb(0, 0, 0, 0.85);
      }
    }
  }

  .turnover-template-table {
    margin-bottom: 20px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .over {
      color: red;
    }

    .percentage-input {
      min-width: 30px;
      width: 30px;
      height: 30px;
      padding: 0;
      color: lightgray;
      background: #efefef50;

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input {
        padding: 0;
        text-align: center;
      }

      &.hasValue {
        color: #00a651;
        background: #00a65130;
      }
    }

    .ant-table-cell {
      padding: 15px 2px;
    }
    
    th {
      text-align: center;
      font-size: 12px;
    }

    .submit-btn {
      margin: 15px 0;
    }
  }
`;
