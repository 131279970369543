import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Divider, Form, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { store as manageStore } from '../hours/manage/redux/store';
import CheckoutForm from '../settings/billing/payment-details/CheckoutForm';
import ModalResetTrial from './ModalResetTrial';

const { Option } = Select;
interface Props {
  className?: string;
  visible: boolean;
  payementInfo: {
    pricePerUser: number;
    totalUsers: number;
    currency: string;
    paymentMethods: any[];
  } | null;
  loadingPayementInfo: boolean;
}

const ModalTrialOver: React.FC<Props> = ({ className, visible, payementInfo, loadingPayementInfo }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartmentId, departments, loadingDepartments },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const [reseting, _] = useState<boolean>(false);
  const [showModalResetTrial, setShowModalResetTrial] = useState<boolean>(false);
  const history = useHistory();

  const [changingPaymentMethod, setChangingPaymentMethod] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const myDepartments = departments?.filter((department) => department.role !== 'USER');

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY!));
  }, []);

  const onCheckout = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        location.href = data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDepartmentChange = (value: string) => {
    if (value && activeDepartmentId !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  const onChangePayementMethod = () => {
    setChangingPaymentMethod(true);
    createPaymentIntent();
  };

  const createPaymentIntent = (force: boolean = false) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/billing/stripe/create-setup-intent`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loadingPayementInfo) {
    return <></>;
  }

  if (payementInfo && payementInfo.paymentMethods.length > 0) {
    return <></>;
  }

  return (
    <Modal className={className} forceRender={true} closable={false} visible={visible} footer={null}>
      {loadingPayementInfo && <Spin spinning={true} size="large" />}
      {payementInfo && payementInfo.paymentMethods.length == 0 && !loadingPayementInfo && (
        <React.Fragment>
          <h1>{t('TRIAL.MODAL_TRIAL_OVER.TITLE')}</h1>
          <Trans
            i18nKey="TRIAL.MODAL_TRIAL_OVER.SUBSCRIBE_TEXT"
            values={{
              totalPrice: new Intl.NumberFormat(language, {
                style: 'currency',
                currency: payementInfo.currency || 'EUR',
              }).format(payementInfo.pricePerUser * payementInfo.totalUsers),
              totalUsers: payementInfo.totalUsers,
              pricePerUser: new Intl.NumberFormat(language, {
                style: 'currency',
                currency: payementInfo.currency || 'EUR',
              }).format(payementInfo.pricePerUser),
            }}
            components={{ bold: <b />, green: <span style={{ color: colors.green }} />, br: <br /> }}
          />
          {!changingPaymentMethod ? (
            <Button
              loading={loading}
              type="primary"
              size="large"
              onClick={onChangePayementMethod}
              style={{ margin: '15px 0', alignSelf: 'center' }}
            >
              {t('TRIAL.MODAL_TRIAL_OVER.SUBSCRIBE_BTN')}
            </Button>
          ) : (
            <>
              {stripePromise && clientSecret ? (
                <>
                  <Button
                    type="link"
                    onClick={() => setChangingPaymentMethod(false)}
                    style={{ textAlign: 'left', paddingLeft: 20, marginRight: 'auto' }}
                  >
                    {'< '}
                    {t('GLOBAL.BACK')}
                  </Button>
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm
                      setupLoading={loading}
                      style={{ width: '100%', paddingTop: 10 }}
                      return_url="/app/dashboard?pm=true"
                    />
                  </Elements>
                </>
              ) : (
                <>
                  {loading ? (
                    <div
                      style={{
                        backgroundColor: '#FFF',
                        padding: 25,
                        borderRadius: 8,
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Spin spinning={loading} />
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
          {/* <p>{t(`TRIAL.MODAL_TRIAL_OVER.RESET_TEXT`)}</p>
          <Button
            loading={reseting}
            type="primary"
            size="large"
            onClick={() => setShowModalResetTrial(true)}
            style={{ width: 'auto', backgroundColor: '#FFCF97', borderColor: '#FFCF97', color: 'black' }}
          >
            {t(`TRIAL.MODAL_TRIAL_OVER.RESET_BTN`)}
          </Button> */}
          <Form layout="vertical">
            <Divider />
            {myDepartments.length > 1 && (
              <Form.Item label={t('FORMS.ACCOUNT_PLACEHOLDER')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  disabled={loadingDepartments}
                  loading={loadingDepartments}
                  placeholder={t('FORMS.ACCOUNT_PLACEHOLDER')}
                  onChange={handleDepartmentChange}
                >
                  {myDepartments?.map((department) => {
                    const { id, company } = department;
                    return (
                      <Option key={`dept_${id}`} value={`${id}`}>
                        {company}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Button danger size="large" style={{ width: '100%' }} onClick={handleLogout}>
              {t('GLOBAL.LOGOUT')}
            </Button>
          </Form>
          <ModalResetTrial
            visible={showModalResetTrial}
            departmentId={activeDepartmentId}
            onClose={() => setShowModalResetTrial(false)}
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

export default styled(ModalTrialOver)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    h1 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;
