import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocumentTemplates } from '../api';
import DocumentTemplateActions from '../components/document-template-actions';
import { V4DocumentTemplate } from '../types/document-templates.types';

export const useDocumentTemplates = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users },
  } = useContext(AppContext);

  const { data: documentTemplates = [], isLoading: loading } = useQuery({
    queryKey: ['get-document-templates', activeDepartmentId],
    queryFn: getDocumentTemplates,
  });

  const [columns, setColumns] = useState<ColumnsType<V4DocumentTemplate>>([]);

  useEffect(() => {
    setColumns([
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: (a: V4DocumentTemplate, b: V4DocumentTemplate) => a.id.localeCompare(b.id),
      },
      {
        title: t('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: V4DocumentTemplate, b: V4DocumentTemplate) => a.name.localeCompare(b.name),
      },
      {
        title: t('general.created-on'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a: V4DocumentTemplate, b: V4DocumentTemplate) => a.createdAt.localeCompare(b.createdAt),
        render: (_, template) => {
          return moment(template.createdAt).format('L HH:mm');
        },
      },
      {
        title: t('general.actions'),
        key: 'actions',
        render: (_, documentTemplate: V4DocumentTemplate) => {
          return <DocumentTemplateActions documentTemplate={documentTemplate} />;
        },
      },
    ]);
  }, [users]);

  return {
    columns,
    loading,
    documentTemplates,
  };
};
