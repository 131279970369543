import api from '@/api';
import {
  CreateTemplatePayload,
  UpdateTemplatePayload,
  UploadTemplatePayload,
  V4TurnoverTemplateResponse,
} from '../types';

export const getTurnoverTemplate = async (templateId: string) => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse }>(`turnover-quota-templates/${templateId}`);
  return response.data;
};

export const getTurnoverTemplates = async () => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse[] }>(`turnover-quota-templates`);
  return response.data;
};

export const createTurnoverTemplate = async (payload: CreateTemplatePayload) => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse }>(`turnover-quota-templates`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response.data;
};

export const updateTurnoverTemplate = async (
  templateId: string,
  payload: UpdateTemplatePayload,
) => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse }>(`turnover-quota-templates/${templateId}/details`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response.data;
};

export const uploadTurnoverTemplate = async (payload: UploadTemplatePayload) => {
  const formData = new FormData();

  formData.append('name', payload.name);
  formData.append('file', payload.file);

  const { response } = await api<{ data: V4TurnoverTemplateResponse }>(`turnover-quota-templates`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  });
  return response.data;
};

export const deleteTurnoverTemplate = async (templateId: string) => {
  const { response } = await api<{ data: V4TurnoverTemplateResponse }>(`turnover-quota-templates/${templateId}`, {
    method: 'DELETE',
  });
  return response.data;
};
