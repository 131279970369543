import api from '@/api';
import {
  V4ContractTemplate,
  V4ManageContractTemplatePayload,
} from '@/pages/app/documents/contracts/templates/types/contract-templates.types';

export const getContractTemplates = async () => {
  const { response } = await api<{ data: V4ContractTemplate[] }>('documents/templates/contract');
  return response.data;
};

export const getContractTemplate = async (contractTemplateId: string) => {
  const { response } = await api<{ data: V4ContractTemplate }>(`documents/templates/contract/${contractTemplateId}`);
  return response.data;
};

export const createContractTemplate = async (payload: V4ManageContractTemplatePayload) => {
  const { response } = await api<{ data: V4ContractTemplate }>('documents/templates/contract', {
    body: JSON.stringify(payload),
    method: 'POST',
  });
  return response.data;
};

export const editContractTemplate = async (contractTemplateId: string, payload: V4ManageContractTemplatePayload) => {
  const { response } = await api<{ data: V4ContractTemplate }>(`documents/templates/contract/${contractTemplateId}`, {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
  return response.data;
};

export const deleteContractTemplate = async (contractTemplateId: string) => {
  const { response } = await api(`documents/templates/contract/${contractTemplateId}`, { method: 'DELETE' });
  return response;
};
