import { client } from '@/ReactQueryProvider';
import { handleError } from '@/utils';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, InputNumber, message, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createFamilySituation, deleteFamilySituation, editFamilySituation } from '../api';
import { V4GetProfileResponse, V4ProfileFamilySituation } from '../types/profile.types';

interface Props {
  className?: string;
  profile: V4GetProfileResponse;
  form: FormInstance<any>;
}

const FamilySituations: React.FC<Props> = ({ className, profile, form }) => {
  const { t } = useTranslation();

  const [allFamilySituations, setAllFamilySituations] = useState<V4ProfileFamilySituation[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    if (!profile) return;
    setAllFamilySituations(profile.data.familySituations);
    setActiveKey(profile.data.familySituations.length > 0 ? [profile.data.familySituations[0].id] : []);
  }, [profile, form]);

  const onAddFamilySituation = () => {
    const newFamilySituation = {
      id: `new-${Date.now()}`,
      validFrom: moment().format('YYYY-MM-DD'),
      civilState: null,
      validUntil: null,
      handicapped: false,
      spouseHandicapped: false,
      spouseHasIncome: false,
      childrenDependant: 0,
      childrenHandicapped: 0,
      othersDependant: 0,
      othersHandicapped: 0,
      elderlyHandicapped: 0,
      elderlyCare: 0,
      elderlyDependant: 0,
    };

    setAllFamilySituations((prev) => [...prev, newFamilySituation] as any);

    form.setFieldsValue({
      familySituations: [
        ...(allFamilySituations || []).map((familySituation) => ({
          ...familySituation,
          validFrom: moment(newFamilySituation.validFrom),
        })),
        {
          ...newFamilySituation,
          validFrom: moment(newFamilySituation.validFrom),
        },
      ],
    });

    setActiveKey([newFamilySituation.id]);
  };

  const onSaveFamilySituation = async (familySituationId: string) => {
    const { familySituations } = form.getFieldsValue();
    if (!profile) return;

    const familySituation = familySituations
      .filter((familySitatuation: any) => !!familySitatuation)
      .find((familySituation: any) => familySituation.id == familySituationId);
    if (!familySituation) return;

    try {
      await createFamilySituation(profile.data.id, {
        ...familySituation,
        validFrom: moment(familySituation.validFrom).format('YYYY-MM-DD'),
      });
      message.success(t('users.profile.family-situations.family-situation-created'));
    } catch (error) {
      handleError(error);
    }

    client.invalidateQueries({ queryKey: ['get-profile', profile.data.id] });
  };

  const onEditFamilySituation = async (familySituationId: string) => {
    const { familySituations } = form.getFieldsValue();
    if (!profile) return;

    const familySituation = familySituations
      .filter((familySitatuation: any) => !!familySitatuation)
      .find((familySituation: any) => familySituation.id == familySituationId);
    if (!familySituation) return;

    try {
      await editFamilySituation(profile.data.id, familySituationId, {
        ...familySituation,
        validFrom: moment(familySituation.validFrom).format('YYYY-MM-DD'),
      });
      message.success(t('users.profile.family-situations.family-situation-edited'));
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteFamilySituation = async (e: React.MouseEvent<HTMLElement, MouseEvent>, familySituationId: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (!profile) return;

    const { familySituations } = form.getFieldsValue();
    const familySituation = familySituations.find((familySituation: any) => familySituation.id == familySituationId);
    if (!familySituation) return;

    if (familySituation.id.startsWith('new-')) {
      setAllFamilySituations((prev) => prev.filter((familySituation) => familySituation.id !== familySituationId));
      return;
    }

    try {
      await deleteFamilySituation(profile.data.id, familySituationId);
      message.success(t('users.profile.family-situations.family-situation-deleted'));
    } catch (error) {
      handleError(error);
    }

    client.invalidateQueries({ queryKey: ['get-profile', profile.data.id] });
  };

  return (
    <div className={className}>
      <h4>{t('general.family-situation.family-situations')}</h4>
      <div className="content">
        {allFamilySituations.length > 0 && (
          <Collapse
            accordion
            activeKey={activeKey}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            onChange={(key) => setActiveKey(key as string[])}
          >
            {allFamilySituations.map((familySituation, i) => {
              const expired = familySituation.validUntil
                ? moment(familySituation.validUntil).isBefore(moment())
                : false;
              const civilState = profile.meta.civilState.find(
                (civilState) => civilState.id == familySituation.civilState,
              );
              const newFamilySituation = familySituation.id.startsWith('new-');

              return (
                <Collapse.Panel
                  header={`${civilState && civilState.label ? `${civilState.label} -` : ''} ${
                    familySituation.validUntil
                      ? t('general.from-date-to-date', {
                          start: familySituation.validFrom,
                          end: familySituation.validUntil,
                        })
                      : familySituation.validFrom
                      ? t('general.since-date', { date: familySituation.validFrom })
                      : t('general.family-situation.new-family-situation')
                  }`}
                  key={familySituation.id}
                  className="site-collapse-custom-panel"
                  extra={
                    familySituation.external && !familySituation.external.canDelete ? undefined : (
                      <Button type="primary" danger onClick={(e) => onDeleteFamilySituation(e, familySituation.id)}>
                        <i className="icon-trash-empty" />
                      </Button>
                    )
                  }
                >
                  <Form.Item name={['familySituations', i, 'id']} hidden />
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.valid-from')} name={['familySituations', i, 'validFrom']}>
                        <DatePicker size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.civil-state')}
                        name={['familySituations', i, 'civilState']}
                      >
                        <Select size="large" disabled={expired}>
                          {profile.meta.civilState.map((civilState) => (
                            <Select.Option value={civilState.id}>{civilState.label}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item valuePropName="checked" name={['familySituations', i, 'handicapped']}>
                        <Checkbox disabled={expired}>{t('general.family-situation.worker-handicapped')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item valuePropName="checked" name={['familySituations', i, 'spouseHandicapped']}>
                        <Checkbox disabled={expired}>{t('general.family-situation.spouse-handicapped')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item valuePropName="checked" name={['familySituations', i, 'spouseHasIncome']}>
                        <Checkbox disabled={expired}>{t('general.family-situation.spouse-income')}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-children')}
                        name={['familySituations', i, 'childrenDependant']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-handicapped-children')}
                        name={['familySituations', i, 'childrenHandicapped']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-other-dependents')}
                        name={['familySituations', i, 'othersDependant']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={6} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-other-handicapped')}
                        name={['familySituations', i, 'othersHandicapped']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-elderly-handicapped')}
                        name={['familySituations', i, 'elderlyHandicapped']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-elderly-care')}
                        name={['familySituations', i, 'elderlyCare']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.family-situation.number-other-dependents')}
                        name={['familySituations', i, 'elderlyDependant']}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} disabled={expired} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!expired && (
                    <Button
                      type="primary"
                      onClick={() =>
                        newFamilySituation
                          ? onSaveFamilySituation(familySituation.id)
                          : onEditFamilySituation(familySituation.id)
                      }
                    >
                      {newFamilySituation ? t('general.save') : t('general.save-changes')}
                    </Button>
                  )}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
        <Button style={{ marginTop: 25 }} type="primary" onClick={onAddFamilySituation}>
          <i className="icon-plus" /> {t('general.family-situation.new-family-situation')}
        </Button>
      </div>
    </div>
  );
};

export default styled(FamilySituations)``;
