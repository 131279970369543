import { useQuery } from '@tanstack/react-query';
import { getDocumentSettings } from '../api';

export const useDocumentSettings = () => {
  const { data: documentSettings, isLoading: loading } = useQuery({
    queryKey: ['get-document-settings'],
    queryFn: getDocumentSettings,
    initialData: null,
  });

  return {
    loading,
    documentSettings,
  };
};
