import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SignaturesList from '../signatures';
import Contract from './[contractId]';
import ContractsList from './components/ContractsList';
import ContractTemplatesList from './templates';
import ContractTemplate from './templates/[templateId]';

const ContractsRoutes: React.FC = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <ContainerView>
      <Helmet>
        <title>{t('GLOBAL.CONTRACTS')} - Shyfter</title>
      </Helmet>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
        <Route path={`${path}/all`}>
          <ContractsList />
        </Route>
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/templates/:templateId`}>
            <ContractTemplate />
          </Route>
        )}
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/templates`}>
            <ContractTemplatesList />
          </Route>
        )}
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/signatures`}>
            <SignaturesList />
          </Route>
        )}
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/:contractId`}>
            <Contract />
          </Route>
        )}
      </Switch>
    </ContainerView>
  );
};

export default ContractsRoutes;
