import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { duplicateDocument } from '../api';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  document: V4Document;
}

const DuplicateDocument: React.FC<Props> = ({ className, document }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['duplicate-document', document.id],
    mutationFn: () => duplicateDocument(document.id),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-documents'] }),
  });

  const onWantToDuplicateDocument = () => {
    Modal.confirm({
      className: 'modal-constraints',
      title: t('documents.documents.duplicate-document-confirmation.title', { name: document.title }),
      icon: null,
      content: t('documents.documents.duplicate-document-confirmation.content', { name: document.title }),
      maskClosable: true,
      okText: t('general.duplicate'),
      onOk: () => {
        mutate();
      },
    });
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.duplicate-document')}>
        <Button type="ghost" className="action" onClick={onWantToDuplicateDocument} loading={loading}>
          <i className="icon-clone" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(DuplicateDocument)``;
