import React, { FC } from 'react';
import colors from '@/styles/colors';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

type ApplyTurnoverTemplateProps = {
  className?: string;
  onTurnoverTemplateVisibilityChange: (visibility: boolean) => void;
};

const ApplyTurnoverTemplate: FC<ApplyTurnoverTemplateProps> = ({ className, onTurnoverTemplateVisibilityChange }) => {
  const { t } = useTranslation();

  return (
    <Menu.Item className={className} onClick={() => onTurnoverTemplateVisibilityChange(true)}>
      <div className="container">
        <span>
          <i className="icon-money" /> {t('SCHEDULE.APPLY_TURNOVER_TEMPLATE')}
        </span>
      </div>
    </Menu.Item>
  );
};

const ApplyTurnoverTemplateStyled = styled(ApplyTurnoverTemplate)`
  margin: 0 5px;

  .container {
    display: flex;
    flex-direction: column;

    .description {
      font-size: 11px;
      color: ${colors.grey};
      margin-left: 21.5px;
      margin-top: -5px;
    }
  }

  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;

export default ApplyTurnoverTemplateStyled;
