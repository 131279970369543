import AppContext from '@/pages/app/context';
import { capitalize, convertUtcToTimezoneDateAndTime, weatherIconMapper } from '@/utils';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { V4WeatherForecastResponse } from '../../types';

type WeatherWidgetProps = {
  weatherData: V4WeatherForecastResponse;
  color: string;
  className?: string;
};

const WeatherWidget: FC<WeatherWidgetProps> = ({ weatherData, color, className }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <div className={className}>
      {Object.entries(weatherData).map(([_, value]) => (
        <div className={'weather-wrapper'}>
          <span>{capitalize(convertUtcToTimezoneDateAndTime(value.date, activeDepartment, 'dddd'))}</span>
          <i className={weatherIconMapper(value.icon)} style={{ color: color }} />
          <span className={'temperature'}>{Math.round(value.maximumTemperature)}°</span>
        </div>
      ))}
    </div>
  );
};

const WeatherWidgetStyled = styled(WeatherWidget)`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;

  .weather-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    i {
      font-size: 23px;
    }

    .temperature {
      font-size: 17px;
      font-weight: 600;
      color: #c4c4c4;
    }
  }
`;

export default WeatherWidgetStyled;
