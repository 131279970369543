import { Button, Result } from 'antd';
import React, { Component } from 'react';

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="Error"
          subTitle="Sorry, something went wrong."
          extra={
            <div>
              <Button
                type="primary"
                onClick={() => {
                  location.reload();
                }}
              >
                REFRESH
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  location.reload();
                }}
                style={{ marginLeft: 10 }}
                danger
              >
                LOG OUT
              </Button>
            </div>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
