import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { capitalize, convertUtcToTimezoneDateAndTime } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getContracts, GetContractsParams } from '../api';
import ContractActions from '../components/contract-actions';
import DownloadContract from '../components/download-contract';
import { V4Contract } from '../types/contracts.types';

export const useContracts = (params: GetContractsParams) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, users, loadingUsers, inactiveUsers, loadingInactiveUsers },
  } = useContext(AppContext);
  const history = useHistory();

  const { data, isLoading: loading } = useQuery({
    queryKey: ['get-contracts', activeDepartmentId, params],
    queryFn: () => getContracts(params),
  });

  const [columns, setColumns] = useState<ColumnsType<V4Contract>>([]);

  useEffect(() => {
    setColumns([
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        className: 'small-row',
        sorter: true,
        render: (_, contract) => {
          return (
            <div className="flex-col">
              <span>{contract.id}</span>
              {contract.renewedFromId && (
                <Tooltip overlay={t('documents.contracts.renewed-from-contract')} placement="right">
                  <span
                    style={{ fontSize: 10, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`/app/documents/contracts/${contract.renewedFromId}`);
                    }}
                  >
                    <i className="icon-arrows-cw" />
                    {contract.renewedFromId}
                  </span>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: t('general.name'),
        dataIndex: 'userRecordId',
        key: 'userRecordId',
        className: 'small-row',
        sorter: true,
        render: (text: string, contract: V4Contract) => {
          const user = [...users, ...inactiveUsers].find((user) => user.recordId === contract.userRecordId);
          if (!user) return null;
          return (
            <Button
              className="p-0 text-left"
              type="link"
              onClick={() => history.push(`/app/team/collaborators/${user.recordId}`)}
            >
              <div className="flex items-center gap-10">
                <Avatar src={user.picture}>{user.initials}</Avatar>
                <div>
                  <p className="m-0">
                    {user.firstname} {user.lastname}
                  </p>
                  {user.category && user.category.name && (
                    <p className="m-0" style={{ color: colors.grey, fontWeight: 'normal', fontSize: 10 }}>
                      {capitalize(user.category.name)}
                    </p>
                  )}
                </div>
              </div>
            </Button>
          );
        },
      },
      {
        title: t('general.start'),
        dataIndex: 'start',
        key: 'start',
        align: 'center',
        className: 'small-row',
        sorter: true,
        render: (start: string) => convertUtcToTimezoneDateAndTime(start, activeDepartment, 'L'),
      },
      {
        title: t('general.end'),
        dataIndex: 'end',
        key: 'end',
        align: 'center',
        className: 'small-row',
        sorter: true,
        render: (end: string) => (end ? convertUtcToTimezoneDateAndTime(end, activeDepartment, 'L') : '-'),
      },
      {
        title: (
          <Tooltip overlay={t('documents.contracts.auto-renew')}>
            <i className="icon-arrows-cw" />
          </Tooltip>
        ),
        dataIndex: 'autorenew',
        key: 'autorenew',
        align: 'center',
        className: 'small-row',
        showSorterTooltip: false,
        sorter: true,
        render: (autorenew: boolean) => (autorenew ? '✅' : '-'),
      },
      {
        title: t('general.sent-on'),
        dataIndex: 'sentAt',
        key: 'sentAt',
        align: 'center',
        sorter: true,
        render: (sentAt: string | null) => (sentAt ? convertUtcToTimezoneDateAndTime(sentAt, activeDepartment) : '-'),
      },
      {
        title: t('general.signed-on'),
        dataIndex: 'signedAt',
        key: 'signedAt',
        align: 'center',
        sorter: true,
        render: (signedAt: string | null) =>
          signedAt ? convertUtcToTimezoneDateAndTime(signedAt, activeDepartment) : '-',
      },
      {
        title: t('general.created-on'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        className: 'small-row',
        sorter: true,
        render: (createdAt: string) => convertUtcToTimezoneDateAndTime(createdAt, activeDepartment),
      },
      {
        title: t('general.actions'),
        key: 'actions',
        className: 'small-row',
        render: (_, contract: V4Contract) => {
          if (params.userRecordId) {
            return <DownloadContract contract={contract} />;
          }
          return <ContractActions contract={contract} />;
        },
      },
    ]);
  }, [users, inactiveUsers, activeDepartment, params.userRecordId]);

  return {
    columns,
    loading: loading || loadingUsers || loadingInactiveUsers,
    contracts: data?.data || [],
    meta: data?.meta,
  };
};
