import { client } from '@/ReactQueryProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getBirthdays,
  getComments,
  getWeather,
  getDailyTurnover,
  getScheduleTurnover,
  getTurnoverTemplates,
  updateDailyTurnover,
  updateScheduleTemplate,
  createComment,
  updateComment,
  deleteComment,
} from '../api';
import {
  UpdateDailyTurnoverPayload,
  UpdateScheduleTemplatePayload,
  CreateScheduleCommentPayload,
  UpdateScheduleCommentPayload,
  V4DailyTurnoverResponse,
  V4ScheduleTurnoverResponse,
  V4TurnoverTemplateResponse,
  V4BirthdayResponse,
  V4CommentResponse,
  V4WeatherResponse,
} from '../types';

export const useTurnoverTemplates = (
  enabled: boolean,
): {
  turnoverTemplates?: V4TurnoverTemplateResponse[];
  loading: boolean;
} => {
  const { data: turnoverTemplates, isLoading: loading } = useQuery({
    queryKey: ['get-turnover-templates'],
    queryFn: () => getTurnoverTemplates(),
    enabled,
  });

  return {
    loading,
    turnoverTemplates,
  };
};

export const useScheduleTurnover = (
  startDate: string,
  endDate: string,
  sectionId?: string,
): {
  scheduleTurnover?: V4ScheduleTurnoverResponse;
  loading: boolean;
} => {
  const { data: scheduleTurnover, isLoading: loading } = useQuery({
    queryKey: ['get-schedule-turnover-template', startDate, endDate, sectionId],
    queryFn: () => getScheduleTurnover(startDate, endDate, sectionId),
  });

  return {
    loading,
    scheduleTurnover,
  };
};

export const useDailyTurnover = (
  startDate: string,
  endDate: string,
  enabled: boolean,
  sectionId?: string,
): {
  dailyTurnover?: V4DailyTurnoverResponse;
  loading: boolean;
} => {
  const { data: dailyTurnover, isLoading: loading } = useQuery({
    queryKey: ['get-daily-turnover', startDate, endDate, sectionId],
    queryFn: () => getDailyTurnover(startDate, endDate, sectionId),
    enabled,
  });

  return {
    loading,
    dailyTurnover,
  };
};

export const useUpdateScheduleTurnover = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-schedule-turnover'],
    mutationFn: (payload: UpdateScheduleTemplatePayload) => updateScheduleTemplate(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-turnover-template'] });
      onSuccess();
    },
  });

  return {
    updateScheduleTurnover: mutate,
    isLoading,
  };
};

export const useUpdateDailyTurnover = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-daily-turnover'],
    mutationFn: (payload: UpdateDailyTurnoverPayload) => updateDailyTurnover(payload),
    onSuccess,
  });

  return {
    updateDailyTurnover: mutate,
    isLoading,
  };
};

export const useWeather = (): {
  weather?: V4WeatherResponse[];
  loading: boolean;
} => {
  const { data: weather, isLoading: loading } = useQuery({
    queryKey: ['get-weather'],
    queryFn: () => getWeather(),
  });

  return {
    weather,
    loading,
  };
};

export const useScheduleComments = (
  startDate: string,
  endDate: string,
): {
  comments?: V4CommentResponse[];
  loading: boolean;
} => {
  const { data: comments, isLoading: loading } = useQuery({
    queryKey: ['get-schedule-comments', startDate, endDate],
    queryFn: () => getComments(startDate, endDate),
  });

  return {
    comments,
    loading,
  };
};

export const useBirthdays = (
  startDate: string,
  endDate: string,
): {
  birthdays?: V4BirthdayResponse[];
  loading: boolean;
} => {
  const { data: birthdays, isLoading: loading } = useQuery({
    queryKey: ['get-birthdays', startDate, endDate],
    queryFn: () => getBirthdays(startDate, endDate),
  });

  return {
    birthdays,
    loading,
  };
};

export const useCreateScheduleComment = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['create-schedule-comment'],
    mutationFn: (payload: CreateScheduleCommentPayload) => createComment(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    createScheduleComment: mutate,
    isLoading,
  };
};

export const useUpdateScheduleComment = (commentId: string, onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-schedule-comment'],
    mutationFn: (payload: UpdateScheduleCommentPayload) => updateComment(commentId, payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    updateScheduleComment: mutate,
    isLoading,
  };
};

export const useDeleteScheduleComment = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['delete-schedule-comment'],
    mutationFn: (commentId: string) => deleteComment(commentId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-schedule-comments'] });
      onSuccess();
    },
  });

  return {
    deleteScheduleComment: mutate,
    isLoading,
  };
};
