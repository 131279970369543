import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IUserParams } from '@/types/user-params.model';
import { IUserRole, USER_ROLES } from '@/types/user-role.model';
import { IUser } from '@/types/user.model';
import { isClockingAccount, isFeatureEnabled } from '@/utils';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Option = Select.Option;

interface Props {
  className?: string;
  department?: IDepartment;
  user?: IUser;
  params?: IUserParams;
  form: FormInstance;
  setActiveRole: React.Dispatch<React.SetStateAction<string | null>>;
}

const AccessLevel: React.FC<Props> = ({ className, department, user, form, params, setActiveRole }) => {
  const {
    state: { features },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
  const [loadingUserRoles, setLoadingUserRoles] = useState<boolean>(false);
  const [hasReports, setHasReports] = useState<boolean>(false);
  const { id: departmentId, role } = department || {};

  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    if (params?.access?.reports === false || params?.access?.reports === true) {
      setHasReports(params?.access?.reports);
    }
  }, [params]);

  useEffect(() => {
    if (departmentId) {
      const cancelTokenSource = Axios.CancelToken.source();
      setLoadingUserRoles(true);
      setUserRoles([]);
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/user-roles/`, {
        params: {
          departmentId: departmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          setUserRoles(response.data.userRoles);
          setLoadingUserRoles(false);
          if (userDetails?.id === user?.mainId) {
            Axios.get(`${process.env.REACT_APP_API_URL}/v3/departments`)
              .then((response) => {
                const departments = response.data.departments as IDepartment[];
                appContextDispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: departments,
                });
              })
              .catch((error) => {
                appContextDispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: [],
                });
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          setLoadingUserRoles(false);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [departmentId]);

  const onRoleChange = (role: string) => {
    setActiveRole(role);
    const access = { ...form.getFieldValue('access') };
    switch (role) {
      case USER_ROLES.ADMIN:
        access.shifts = true;
        access.clockings = true;
        access.dimona = true;
        access.requests = true;
        access.reports = true;
        access.timesheets = true;
        access.documents = true;
        access.manage_productivity = true;
        access.show_prices_info = true;
        break;
      case USER_ROLES.PLANNING:
        access.shifts = true;
        access.clockings = true;
        access.dimona = true;
        access.requests = true;
        access.reports = false;
        access.timesheets = false;
        access.documents = false;
        access.manage_productivity = false;
        access.show_prices_info = true;
        break;
      case USER_ROLES.HR:
        access.shifts = true;
        access.clockings = false;
        access.dimona = true;
        access.requests = false;
        access.reports = true;
        access.timesheets = true;
        access.documents = true;
        access.manage_productivity = false;
        access.show_prices_info = true;
        break;
      default:
        access.shifts = false;
        access.clockings = false;
        access.dimona = false;
        access.requests = false;
        access.reports = false;
        access.timesheets = false;
        access.documents = false;
        access.show_prices_info = false;
        break;
    }
    form.setFieldsValue({ access });
  };

  const accessItems = [
    ...(isFeatureEnabled(features, FEATURES.SCHEDULE)
      ? [{ title: t('USERS.USER.SETTINGS.SHIFT_MANAGEMENT'), name: ['access', 'shifts'] }]
      : []),
    ...(isFeatureEnabled(features, FEATURES.DIMONA)
      ? [{ title: t('USERS.USER.SETTINGS.DIMONA_MANAGEMENT'), name: ['access', 'dimona'] }]
      : []),
    ...(isFeatureEnabled(features, FEATURES.CLOCKING)
      ? [{ title: t('USERS.USER.SETTINGS.CLOCKING_MANAGEMENT'), name: ['access', 'clockings'] }]
      : []),
    ...(isFeatureEnabled(features, FEATURES.SCHEDULE)
      ? [{ title: t('USERS.USER.SETTINGS.SHIFT_REQUEST_MANAGEMENT'), name: ['access', 'requests'] }]
      : []),
    { title: t('USERS.USER.SETTINGS.REPORT_MANAGEMENT'), name: ['access', 'reports'] },
    ...(isFeatureEnabled(features, FEATURES.CONTRACTS)
      ? [{ title: t('USERS.USER.SETTINGS.DOCUMENT_MANAGEMENT'), name: ['access', 'documents'] }]
      : []),
    ...((isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
    !isClockingAccount(features) &&
    hasReports
      ? [{ title: t('USERS.USER.SETTINGS.TIMESHEET_MANAGEMENT'), name: ['access', 'timesheets'] }]
      : []),
    { title: t('USERS.USER.SETTINGS.SHOW_PRICES'), name: ['access', 'show_prices_info'] },
    ...(isFeatureEnabled(features, FEATURES.PRODUCTIVITY)
      ? [{ title: t('USERS.USER.SETTINGS.PRODUCTIVITY'), name: ['access', 'manage_productivity'] }]
      : []),
  ];

  const onChange = (e: any) => {
    setHasReports(e.target.checked);
  };

  return (
    <div className={className}>
      <h4>{t('GLOBAL.ACCESS_LEVEL')}</h4>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.params?.account_type !== curValues.params?.account_type}
        style={{ margin: 0 }}
      >
        {() => {
          const activeRole = form.getFieldValue(['params', 'account_type']);
          return (
            <Form.Item name={['params', 'account_type']}>
              <Select
                onChange={onRoleChange}
                getPopupContainer={(trigger) => trigger}
                placeholder={t('FORMS.ROLE_PLACEHOLDER')}
                loading={loadingUserRoles}
                disabled={
                  loadingUserRoles ||
                  (activeRole === USER_ROLES.ADMIN && role !== 'ADMIN') ||
                  userDetails?.id === user?.mainId
                }
                className="select"
              >
                {userRoles.map((x) => (
                  <Option key={`role_${x.id}`} value={x.id!} disabled={x.id === USER_ROLES.ADMIN && role !== 'ADMIN'}>
                    {t(`USERS.ROLES.${x.name}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.params?.account_type !== curValues.params?.account_type}
        style={{ margin: 0 }}
      >
        {() => {
          const activeRole = form.getFieldValue(['params', 'account_type']);
          const disabled = role !== 'ADMIN';
          if (activeRole !== USER_ROLES.USER) {
            return (
              <React.Fragment>
                <Row className="margin">
                  {accessItems?.map(({ name, title }) => {
                    return (
                      <Col key={name.join('.')} className="checkboxes">
                        <Form.Item name={name} className="one-line" valuePropName="checked" style={{ margin: 0 }}>
                          <Checkbox
                            disabled={disabled || (activeRole === '1' && name[1] === 'manage_productivity')}
                            onChange={name[1] == 'reports' ? onChange : () => {}}
                          >
                            {title}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
            );
          }
        }}
      </Form.Item>
    </div>
  );
};

const AccessLevelStyled = styled(AccessLevel)`
  .ant-form-item-control-input {
    min-height: auto;
  }

  .select {
    width: 300px;
  }

  .checkboxes {
    width: 50%;
  }

  @media screen and (max-width: 900px) {
    .select {
      width: 100%;
    }

    .checkboxes  {
      width: 100%;
    }
  }
`;

export default AccessLevelStyled;
