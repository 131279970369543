import { useMutation } from '@tanstack/react-query';
import { updateDailyTurnovers } from '../api/index';
import { V4DailyTurnoversUpdatePayload } from '../types/index';

export const useDailyTurnoversUpdate = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-daily-turnovers'],
    mutationFn: (payload: V4DailyTurnoversUpdatePayload) => updateDailyTurnovers(payload),
    onSuccess,
  });

  return {
    updateDailyTurnovers: mutate,
    isLoading,
  };
};
