import { client } from '@/ReactQueryProvider';
import { updateWidgetsColor } from '../api/index';
import { useMutation } from '@tanstack/react-query';
import { V4WidgetSColorUpdatePayload } from '../types/index';

export const useWidgetsColorUpdate = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-widgets-color'],
    mutationFn: (payload: V4WidgetSColorUpdatePayload) => updateWidgetsColor(payload),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-widgets'] }),
  });

  return {
    updateWidgetsColor: mutate,
    isLoading,
  };
};
