import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Document from './[documentId]';
import DocumentsList from './components/documents-list';
import DocumentTemplatesList from './templates';
import DocumentTemplate from './templates/[templateId]';

const AppDocuments: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();

  return (
    <ContainerView>
      <Helmet>
        <title>{t('GLOBAL.DOCUMENTS')} - Shyfter</title>
      </Helmet>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
        <Route path={`${path}/all`}>
          <DocumentsList />
        </Route>
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/templates/:templateId`}>
            <DocumentTemplate />
          </Route>
        )}
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/templates`}>
            <DocumentTemplatesList />
          </Route>
        )}
        {activeDepartment?.accountType !== 'RGPD' && (
          <Route path={`${path}/:documentId`}>
            <Document />
          </Route>
        )}
      </Switch>
    </ContainerView>
  );
};

export default AppDocuments;
