import api from '@/api';
import { CopyDailyTurnoverPayload, UpdateDailyTurnoverPayload, V4DailyTurnoverResponse } from '../types';

export const getDailyTurnover = async (startDate: string, endDate: string, sectionId: string | null) => {
  const { response } = await api<{ data: V4DailyTurnoverResponse }>(
    `insights?startDate=${startDate}&endDate=${endDate}${sectionId ? `&sectionId=${sectionId}` : ''}`,
  );
  return response.data;
};

export const updateDailyTurnover = async (
  payload: UpdateDailyTurnoverPayload,
  startDate: string,
  endDate: string,
  sectionId: string | null,
) => {
  const { response } = await api<{ data: V4DailyTurnoverResponse }>(
    `insights?startDate=${startDate}&endDate=${endDate}${sectionId ? `&sectionId=${sectionId}` : ''}`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  return response.data;
};

export const copyDailyTurnover = async (payload: CopyDailyTurnoverPayload) => {
    const { response } = await api<{ data: V4DailyTurnoverResponse }>(`insights/daily-turnover/copy`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  return response.data;
};
