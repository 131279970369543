import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, message, Modal } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalTrial: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/trials/create-users`,
        {
          users: values.users,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
            params: {
              departmentId: activeDepartmentId,
            },
          })
          .then((response) => {
            const { data } = response;
            dispatch({
              type: 'SET_USERS',
              payload: data.users,
            });

            history.push('/app/team/collaborators/all');
            message.success(t('TRIAL.MODAL_USERS.USERS_CREATED'));
            localStorage.setItem(`trials.${activeDepartmentId}.modal-closed`, 'true');
            setVisible(false);
          });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUseDemoData = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/trials/use-demo-data`,
        {},
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
            params: {
              departmentId: activeDepartmentId,
            },
          })
          .then((response) => {
            const { data } = response;
            dispatch({
              type: 'SET_USERS',
              payload: data.users,
            });

            history.push('/app/team/collaborators/all');
            message.success(t('TRIAL.MODAL_USERS.USERS_CREATED'));
            localStorage.setItem(`trials.${activeDepartmentId}.modal-closed`, 'true');
            setVisible(false);
          });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setVisible(false);
    localStorage.setItem(`trials.${activeDepartmentId}.modal-closed`, 'true');
  };

  return (
    <Modal className={className} onCancel={onClose} visible={visible} footer={null} width="50%" onOk={onClose}>
      <h1>{t('TRIAL.MODAL_USERS.TITLE')}</h1>
      <p>{t('TRIAL.MODAL_USERS.DESCRIPTION')}</p>

      {/* Form */}
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ users: [{}] }}>
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              <List
                bordered
                dataSource={fields}
                renderItem={(field, index) => (
                  <List.Item actions={[<MinusCircleOutlined key="remove" onClick={() => remove(field.name)} />]}>
                    <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                      <Form.Item
                        name={[field.name, 'firstName']}
                        rules={[{ required: true, message: '' }]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder={t('GLOBAL.FIRSTNAME')} />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'lastName']}
                        rules={[{ required: true, message: '' }]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder={t('GLOBAL.LASTNAME')} />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'email']}
                        rules={[{ required: true, message: '' }]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder={t('GLOBAL.EMAIL')} type="email" />
                      </Form.Item>
                    </div>
                  </List.Item>
                )}
              />
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                {t('TRIAL.MODAL_USERS.ADD_USER')}
              </Button>
            </>
          )}
        </Form.List>

        <div style={{ marginTop: 25 }}>
          <Button
            type="primary"
            size="large"
            style={{ backgroundColor: colors.purple, borderColor: colors.purple, marginRight: 10 }}
            onClick={onUseDemoData}
            htmlType="button"
            loading={loading}
          >
            {t('TRIAL.MODAL_USERS.USE_DEMO')}
          </Button>
          <Button type="primary" size="large" htmlType="submit" loading={loading}>
            {t('TRIAL.MODAL_USERS.CREATE_USERS')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalTrial)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    h1 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;
