/* eslint-disable @typescript-eslint/no-empty-function */
import { IBiSort } from '@/types/bi/sort.model';
import { IBiUser } from '@/types/bi/user.model';
import { ICost } from '@/types/cost.model';
import { ICustomHrCode } from '@/types/custom-hr-code.model';
import { IDayoffType } from '@/types/dayoff-type.model';
import { IDayoff } from '@/types/dayoff.model';
import { IDepartment } from '@/types/department.model';
import { IFeature } from '@/types/features.model';
import { IField } from '@/types/field.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import { IHrPartner } from '@/types/hr-partner.model';
import { IHrField } from '@/types/hr/hr-field.model';
import { IPcCategory } from '@/types/hr/pc-category.model';
import { IAyWorkerType } from '@/types/hr/worker-type.model';
import { IQuotaCustomTemplate } from '@/types/insights/quota-custom-template.model';
import { IQuotaCustom } from '@/types/insights/quota-custom.model';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { OpsFilter } from '@/types/ops-filter.model';
import { IPackage } from '@/types/package.model';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import { IResource } from '@/types/resource.model';
import { ISection } from '@/types/section.model';
import { IShortcut } from '@/types/shortcut.model';
import { ISkill } from '@/types/skill.model';
import { ITask } from '@/types/task.model';
import { IUserStatus } from '@/types/user-status.model';
import { IUser } from '@/types/user.model';
import { IWeeklyView } from '@/types/weekly-view.model';
import moment, { Moment } from 'moment';
import React, { createContext } from 'react';
import { ActionsType } from './actions';

export type InitialStateType = {
  loadingFeatures: boolean;
  loadingSettings: boolean;
  features: IFeature[];
  loadingMarketplaceApps: boolean;
  marketplaceApps: IMarketplaceApp[];
  activeSection: string | undefined;
  activeDepartment: IDepartment | undefined;
  activeDepartmentId: string | undefined;
  weeklyViews: IWeeklyView[];
  loadingWeeklyViews: boolean;
  sections: ISection[];
  loadingSections: boolean;
  departments: IDepartment[];
  loadingDepartments: boolean;
  skills: ISkill[];
  loadingSkills: boolean;
  costs: ICost[];
  loadingCosts: boolean;
  tasks: ITask[];
  loadingTasks: boolean;
  resources: IResource[];
  loadingResources: boolean;
  users: IUser[];
  loadingUsers: boolean;
  inactiveUsers: IUser[];
  loadingInactiveUsers: boolean;
  usersFields: IField[];
  loadingUserCategories: boolean;
  userCategories: IUserStatus[];
  dayoffs: IDayoff[];
  dayoffTypes: IDayoffType[];
  loadingDayoffs: boolean;
  packages: IPackage[];
  loadingPackages: boolean;
  shortcuts: IShortcut[];
  loadingShortcuts: boolean;
  customHrCodes: ICustomHrCode[];
  loadingCustomHrCodes: boolean;
  showHelpVideo: string | null;
  badges: IHeaderBadges;
  hrPartners: IHrPartner[];
  loadingHrPartners: boolean;
  opsFilter: OpsFilter | null;
  loadingHrFields: boolean;
  hrFields: IHrField[];
  loadingPcCategories: boolean;
  pcCategories: IPcCategory[];
  loadingAyWorkerTypes: boolean;
  ayWorkerTypes: IAyWorkerType[];
  loadingQuotaTemplates: boolean;
  quotaTemplates: IQuotaTemplate[];
  loadingQuotaCustom: boolean;
  quotaCustom: IQuotaCustom[];
  loadingQuotaCustomTemplates: boolean;
  quotaCustomTemplates: IQuotaCustomTemplate[];
  groupAdminStart: Moment;
  groupAdminUsers: string[];
  groupAdminSort: IBiSort | null;
  groupAdminSections: string[];
  groupAdminStatus: string[];
  groupAdminSkills: string[];
  groupAdminAttributes: string[];
  groupAdminActiveUser: IBiUser | null;
  loadingPayPeriods: boolean;
  payPeriods: IPayPeriod[];
};

export const getInitialState = (): InitialStateType => ({
  loadingFeatures: false,
  loadingSettings: false,
  features: [],
  loadingMarketplaceApps: false,
  marketplaceApps: [],
  activeSection: localStorage.getItem('activeSection') || undefined,
  activeDepartment: undefined,
  activeDepartmentId: localStorage.getItem('activeDepartment') || undefined,
  weeklyViews: [],
  loadingWeeklyViews: false,
  sections: [],
  loadingSections: false,
  departments: [],
  loadingDepartments: false,
  skills: [],
  loadingSkills: false,
  costs: [],
  loadingCosts: false,
  tasks: [],
  loadingTasks: false,
  resources: [],
  loadingResources: false,
  users: [],
  loadingUsers: false,
  inactiveUsers: [],
  loadingInactiveUsers: false,
  userCategories: [],
  loadingUserCategories: false,
  dayoffs: [],
  dayoffTypes: [],
  loadingDayoffs: false,
  usersFields: [],
  packages: [],
  loadingPackages: false,
  shortcuts: [],
  loadingShortcuts: false,
  customHrCodes: [],
  loadingCustomHrCodes: false,
  showHelpVideo: null,
  badges: {},
  hrPartners: [],
  loadingHrPartners: false,
  opsFilter: null,
  loadingHrFields: false,
  hrFields: [],
  loadingPcCategories: false,
  pcCategories: [],
  loadingAyWorkerTypes: false,
  ayWorkerTypes: [],
  loadingQuotaTemplates: false,
  quotaTemplates: [],
  loadingQuotaCustom: false,
  quotaCustom: [],
  loadingQuotaCustomTemplates: false,
  quotaCustomTemplates: [],
  groupAdminStart: moment(),
  groupAdminUsers: [],
  groupAdminSort: null,
  groupAdminSections: [],
  groupAdminStatus: [],
  groupAdminSkills: [],
  groupAdminAttributes: [],
  groupAdminActiveUser: null,
  loadingPayPeriods: false,
  payPeriods: [],
});

export default createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<ActionsType>;
}>({
  state: getInitialState(),
  dispatch: () => null,
});
