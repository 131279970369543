import api from '@/api';
import { V4GetProfileHrContractsResponse, V4GetProfileResponse, V4GetSettingsResponse } from '../types/profile.types';

export const getProfile = async (userRecordId: string) => {
  const { response } = await api<V4GetProfileResponse>(`profiles/${userRecordId}`);
  return response;
};

export const createFamilySituation = async (userRecordId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/family-situations`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response;
};

export const editFamilySituation = async (userRecordId: string, familySituationId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/family-situations/${familySituationId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
  return response;
};

export const deleteFamilySituation = async (userRecordId: string, familySituationId: string) => {
  const { response } = await api(`profiles/${userRecordId}/family-situations/${familySituationId}`, {
    method: 'DELETE',
  });
  return response;
};

export const createAddress = async (userRecordId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/addresses`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response;
};

export const editAddress = async (userRecordId: string, addressId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/addresses/${addressId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
  return response;
};

export const deleteAddress = async (userRecordId: string, addressId: string) => {
  const { response } = await api(`profiles/${userRecordId}/addresses/${addressId}`, {
    method: 'DELETE',
  });
  return response;
};

export const getProfileHrContracts = async (userRecordId: string | undefined) => {
  const { response } = await api<V4GetProfileHrContractsResponse>(`profiles/${userRecordId}/hr-contracts`);
  return response;
};

export const createProfileHrContract = async (userRecordId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/hr-contracts`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response;
};

export const editProfileHrContract = async (userRecordId: string, profileHrContractId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/hr-contracts/${profileHrContractId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
  return response;
};

export const deleteProfileHrContract = async (userRecordId: string, profileHrContractId: string, payload: any) => {
  const { response } = await api(`profiles/${userRecordId}/hr-contracts/${profileHrContractId}`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
  });
  return response;
};

export const getSettings = async () => {
  const { response } = await api<V4GetSettingsResponse>(`settings/attentia`);
  return response;
};

export const editSettings = async (payload: any) => {
  const { response } = await api(`settings/attentia`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
  return response;
};
