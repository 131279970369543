import React, { useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber, Modal } from 'antd';
import { useUpdateDailyTurnover } from '@/pages/app/hours/manage/hooks';

interface Props {
  className?: string;
  sectionId?: string;
  timestamp: number | null;
  value?: number | null;
  provisional?: number | null;
  onClose: () => void;
  onSave: () => void;
}

const ModalProvisional: React.FC<Props> = ({ className, sectionId, timestamp, value, provisional, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const { updateDailyTurnover, isLoading } = useUpdateDailyTurnover(() => {
    onClose();
    onSave();
  })

  useEffect(() => {
    form.resetFields();
    if (timestamp) {
      form.setFieldsValue({
        [`${timestamp!}_real`]: value,
        [`${timestamp!}_provisional`]: provisional,
      });
    }
  }, [timestamp, value, provisional]);

  const onFinish = (values: any) => {
    updateDailyTurnover({
      date: moment.unix(timestamp!).format('YYYY-MM-DD'),
      real: values[`${timestamp!}_real`],
      provisional: values[`${timestamp!}_provisional`],
      sectionId,
    })
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!timestamp}
      footer={null}
      onCancel={() => onClose()}
      title={t('SCHEDULE.MODAL_CHANGE_TURNOVER.TITLE', {
        date: moment.unix(timestamp!).format('DD MMMM'),
      })}
    >
      <Form layout="vertical" size="large" form={form} onFinish={onFinish} requiredMark={false}>
        {timestamp && (
          <div className={'inputs-wrapper'}>
            <Form.Item name={`${timestamp}_provisional`} label={t(`GLOBAL.PROVISIONAL`)}>
              <InputNumber />
            </Form.Item>
            {moment.unix(timestamp).startOf('day').unix() <= moment().startOf('day').unix() && (
              <Form.Item name={`${timestamp}_real`} label={t(`GLOBAL.REAL`)}>
                <InputNumber />
              </Form.Item>
            )}
          </div>
        )}

        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose} style={{ width: 180, marginRight: 20 }}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: 180 }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalProvisional)`
  .ant-modal-body {
    .inputs-wrapper {
      display: flex;
      gap: 20px;
      
      .ant-row {
        flex: 1;
      }
      
      .ant-input-number-lg {
        width: 100%;
      }
    }
    
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      
      button {
        flex: 1;
      }
    }
  }
`;
