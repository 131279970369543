import { getWidget } from '../api/index';
import { widgetsWithNoFilters } from '../utils';
import { useQuery } from '@tanstack/react-query';
import { V4WidgetDataResponse } from '../types/index';

export const useWidget = (
  slug: string,
  startDate: string,
  endDate: string,
  sectionId?: string,
): {
  widget: V4WidgetDataResponse;
  loading: boolean;
} => {
  const { data: widget, isLoading: loading } = useQuery({
    queryKey: ['get-widget', slug, startDate, endDate, sectionId],
    queryFn: () => getWidget(slug, startDate, endDate, sectionId),
    keepPreviousData: widgetsWithNoFilters.includes(slug),
  });

  return {
    loading,
    widget: widget as V4WidgetDataResponse,
  };
};
