import api from '@/api';
import { UpdateInsightSettingsPayload, V4UpdateInsightSettingsResponse } from '../types';

export const updateInsightSettings = async (payload: UpdateInsightSettingsPayload) => {
  const { response } = await api<{ data: V4UpdateInsightSettingsResponse }>(`insights/settings`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  return response.data;
};
