import TableView from '@/layouts/TableView';
import colors from '@/styles/colors';
import { Table } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DrawerSignature from './components/drawer-signature';
import Header from './components/header';
import { useSignatures } from './hooks/use-signatures';

interface Props {
  className?: string;
}

const SignaturesList: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { columns, signatures, loading } = useSignatures();

  const [drawerSignatureVisible, setDrawerSignatureVisible] = useState(false);

  return (
    <TableView className={className}>
      <Helmet>
        <title>{t('general.signatures')} - Shyfter</title>
      </Helmet>
      <Header setDrawerSignatureVisible={setDrawerSignatureVisible} />
      <Table
        dataSource={signatures}
        loading={loading}
        columns={columns}
        rowKey="id"
        pagination={{ position: ['bottomRight'] }}
      />
      <DrawerSignature visible={drawerSignatureVisible} setVisible={setDrawerSignatureVisible} signature={null} />
    </TableView>
  );
};

export default styled(SignaturesList)`
  .default-badge {
    background-color: ${colors.blueLightPastel};
  }
`;
