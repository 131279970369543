import { client } from '@/ReactQueryProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CreateTemplatePayload,
  UpdateTemplatePayload,
  UploadTemplatePayload,
  V4TurnoverTemplateResponse
} from '../types';
import {
  getTurnoverTemplate,
  getTurnoverTemplates,
  createTurnoverTemplate,
  uploadTurnoverTemplate,
  deleteTurnoverTemplate,
  updateTurnoverTemplate,
} from '../api';

export const useTurnoverTemplate = (templateId: string): {
  turnoverTemplate?: V4TurnoverTemplateResponse;
  loading: boolean;
} => {
  const { data: turnoverTemplate, isLoading: loading } = useQuery({
    queryKey: ['get-turnover-template'],
    queryFn: () => getTurnoverTemplate(templateId),
  });

  return {
    loading,
    turnoverTemplate,
  };
};

export const useTurnoverTemplates = (): {
  turnoverTemplates?: V4TurnoverTemplateResponse[];
  loading: boolean;
} => {
  const { data: turnoverTemplates, isLoading: loading } = useQuery({
    queryKey: ['get-turnover-templates'],
    queryFn: () => getTurnoverTemplates(),
  });

  return {
    loading,
    turnoverTemplates,
  };
};

export const useTurnoverTemplateCreate = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['create-turnover-template'],
    mutationFn: (payload: CreateTemplatePayload) => createTurnoverTemplate(payload),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-turnover-templates'] }),
  });

  return {
    createTemplate: mutate,
    isLoading,
  };
};

export const useTurnoverTemplateUpdate = (templateId: string, onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-turnover-template'],
    mutationFn: (payload: UpdateTemplatePayload) => updateTurnoverTemplate(templateId, payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-turnover-template', templateId] });
      onSuccess();
    },
  });

  return {
    updateTemplate: mutate,
    isLoading,
  };
};

export const useTurnoverTemplateUpload = (onSuccess: () => void) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['upload-turnover-template'],
    mutationFn: (payload: UploadTemplatePayload) => uploadTurnoverTemplate(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['get-turnover-templates'] });
      onSuccess();
    },
  });

  return {
    uploadTemplate: mutate,
    isLoading,
  };
};

export const useTurnoverTemplateDelete = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['delete-turnover-template'],
    mutationFn: (templateId: string) => deleteTurnoverTemplate(templateId),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-turnover-templates'] }),
  });

  return {
    deleteTemplate: mutate,
    isLoading,
  };
};
