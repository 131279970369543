import { useQuery } from '@tanstack/react-query';
import { getSettings } from '../../../../team/collaborators/collaborator/overview/profile/api';

export const useSettings = () => {
  const { data: settings = null, isLoading: loading } = useQuery({
    queryKey: ['get-attentia-settings'],
    queryFn: () => getSettings(),
  });

  return {
    settings,
    loading,
  };
};
