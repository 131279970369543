import React from 'react';
import styled from 'styled-components';
import { V4ContractTemplate } from '../types/contract-templates.types';
import DeleteContractTemplate from './delete-contract-template';
import DownloadContractTemplate from './download-contract-template';
import EditContractTemplate from './edit-contract-template';

interface Props {
  className?: string;
  contractTemplate: V4ContractTemplate;
}

const ContractTemplateActions: React.FC<Props> = ({ className, contractTemplate }) => {
  return (
    <div className={className}>
      <EditContractTemplate contractTemplateId={contractTemplate.id} />
      <DownloadContractTemplate file={contractTemplate.file} />
      <DeleteContractTemplate contractTemplate={contractTemplate} />
    </div>
  );
};

export default styled(ContractTemplateActions)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
