import AppContext from '@/pages/app/context';
import { convertUtcToTimezoneDateAndTime } from '@/utils';
import { FilePdfFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { V4DocumentUserDocument } from '../types/documents.types';

export const useDocumentDetails = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  const [columns, setColumns] = useState<ColumnsType<V4DocumentUserDocument>>([]);

  const onDownloadUserDocument = (userDocument: V4DocumentUserDocument) => {
    window.open(userDocument.file, '_blank');
  };

  useEffect(() => {
    setColumns([
      {
        title: t('general.name'),
        dataIndex: ['employee', 'fullName'],
        key: 'employee.fullName',
      },
      {
        title: t('general.sent-on'),
        key: 'sentAt',
        dataIndex: 'sentAt',
        render: (date: string) => {
          return date ? convertUtcToTimezoneDateAndTime(date, activeDepartment) : '-';
        },
      },
      {
        title: t('general.signed-on'),
        key: 'signedAt',
        dataIndex: 'signedAt',
        render: (date: string) => {
          return date ? convertUtcToTimezoneDateAndTime(date, activeDepartment) : '-';
        },
      },
      {
        title: 'PDF',
        key: 'pdf',
        dataIndex: 'pdf',
        render: (_, userDocument: V4DocumentUserDocument) => {
          return (
            <Button type="ghost" onClick={() => onDownloadUserDocument(userDocument)}>
              <FilePdfFilled />
            </Button>
          );
        },
      },
    ]);
  }, []);

  return {
    columns,
  };
};
