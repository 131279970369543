import { Layouts } from '../types/index';
import { generateShades } from '@/utils';

export const availableColors: { [$key: string]: string[] } = {
  '#00A651': ['#00A651', ...generateShades('#00A651')],
  '#478978': ['#478978', ...generateShades('#478978')],
  '#4A6FA5': ['#4A6FA5', ...generateShades('#4A6FA5')],
  '#AE3243': ['#AE3243', ...generateShades('#AE3243')],
  '#828287': ['#828287', ...generateShades('#828287')],
};

export const widgetTranslationsBySlug: { [key: string]: string } = {
  'number-of-planned-shifts': 'DASHBOARD.WIDGETS.NUMBER_PLANNED_SHIFTS',
  'number-of-hours-worked': 'DASHBOARD.WIDGETS.NUMBER_HOURS_WORKED',
  'absence-percentage': 'DASHBOARD.WIDGETS.ABSENCE_PERCENTAGE',
  'user-categories-cost': 'DASHBOARD.WIDGETS.USER_CATEGORIES_COST',
  'sections-cost': 'DASHBOARD.WIDGETS.SECTIONS_COST',
  'planned-hours-vs-real-hours': 'DASHBOARD.WIDGETS.PLANNED_REAL_HOURS',
  'number-of-absences-per-day': 'DASHBOARD.WIDGETS.ABSENCES_PER_DAY',
  'contracts-up-for-renewal': 'DASHBOARD.WIDGETS.CONTRACTS_UP_FOR_RENEWAL',
  'shifts-verification': 'DASHBOARD.WIDGETS.SHIFTS_VERIFICATION',
  'geo-clockings': 'DASHBOARD.WIDGETS.GEOLOC',
  turnover: 'DASHBOARD.WIDGETS.TURNOVER',
  'sickness-per-user-category': 'DASHBOARD.WIDGETS.SICKNESS_USER_CATEGORY',
  'sickness-percentage-per-month': 'DASHBOARD.WIDGETS.SICKNESS_PERCENTAGE_MONTH',
  'open-absence-requests': 'DASHBOARD.WIDGETS.OPEN_ABSENCE_REQUESTS',
  'dimonas-without-shifts': 'DASHBOARD.WIDGETS.DIMONAS_WITHOUT_SHIFTS',
  'shifts-without-dimona': 'DASHBOARD.WIDGETS.SHIFTS_WITHOUT_DIMONA',
  'open-shift-swap-requests': 'DASHBOARD.WIDGETS.SWAP_SHIFT',
  'scheduled-today': 'DASHBOARD.WIDGETS.SCHEDULE_TODAY',
  'scheduled-tomorrow': 'DASHBOARD.WIDGETS.SCHEDULE_TOMORROW',
  'upcoming-birthdays': 'DASHBOARD.WIDGETS.UPCOMING_BIRTHDAYS',
  'upcoming-free-shifts': 'DASHBOARD.WIDGETS.OPENSHIFTS',
  'clockings-today': 'DASHBOARD.WIDGETS.CLOCKINGS_TODAY',
  'clockings-yesterday': 'DASHBOARD.WIDGETS.CLOCKINGS_YESTERDAY',
  'unsigned-documents-contracts': 'DASHBOARD.WIDGETS.UNSIGNED_DOCUMENTS_CONTRACTS',
  'turnover-twin': 'DASHBOARD.WIDGETS.TURNOVER_TWIN',
  'number-of-free-shifts': 'DASHBOARD.WIDGETS.FREE_SHIFTS',
  'total-turnover': 'GLOBAL.TURNOVER',
  'total-cost': 'GLOBAL.COST',
  productivity: 'DASHBOARD.HEADER.PRODUCTIVITY',
  'weather-forecast': 'DASHBOARD.WIDGETS.WEATHER_TITLE',
  'upcoming-absences': 'DASHBOARD.WIDGETS.UPCOMING_ABSENCES',
};

export const clockingDashboardWidgets = ['geo-clockings', 'clockings-today', 'clockings-yesterday'];

export const widgetsWithNoFilters = [
  'sickness-per-user-category',
  'sickness-percentage-per-month',
  'open-absence-requests',
  'dimonas-without-shifts',
  'shifts-without-dimona',
  'open-shift-swap-requests',
  'scheduled-today',
  'scheduled-tomorrow',
  'upcoming-birthdays',
  'upcoming-free-shifts',
  'clockings-today',
  'clockings-yesterday',
  'unsigned-documents-contracts',
];

export const defaultDashboardSettings: { [key: string]: Layouts } = {
  'number-of-planned-shifts': {
    lg: {
      x: 0,
      y: 0,
      w: 1,
      h: 1,
    },
    md: {
      x: 0,
      y: 0,
      w: 1,
      h: 1,
    },
    s: {
      x: 0,
      y: 0,
      w: 1,
      h: 1,
    },
  },
  'absence-percentage': {
    lg: {
      x: 1,
      y: 0,
      w: 1,
      h: 1,
    },
    md: {
      x: 1,
      y: 0,
      w: 2,
      h: 1,
    },
    s: {
      x: 0,
      y: 1,
      w: 1,
      h: 1,
    },
  },
  'number-of-hours-worked': {
    lg: {
      x: 2,
      y: 0,
      w: 1,
      h: 1,
    },
    md: {
      x: 2,
      y: 1,
      w: 2,
      h: 1,
    },
    s: {
      x: 0,
      y: 2,
      w: 1,
      h: 1,
    },
  },
  'total-cost': {
    lg: {
      x: 3,
      y: 0,
      w: 1,
      h: 1,
    },
    md: {
      x: 0,
      y: 1,
      w: 1,
      h: 1,
    },
    s: {
      x: 0,
      y: 3,
      w: 1,
      h: 1,
    },
  },
  'upcoming-birthdays': {
    lg: {
      x: 4,
      y: 0,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 2,
      w: 3,
      h: 2,
    },
    s: {
      x: 0,
      y: 4,
      w: 1,
      h: 2,
    },
  },
  'scheduled-today': {
    lg: {
      x: 0,
      y: 1,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 4,
      w: 3,
      h: 2,
    },
    s: {
      x: 0,
      y: 6,
      w: 1,
      h: 2,
    },
  },
  'scheduled-tomorrow': {
    lg: {
      x: 2,
      y: 3,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 6,
      w: 3,
      h: 2,
    },
    s: {
      x: 0,
      y: 8,
      w: 1,
      h: 2,
    },
  },
  'shifts-verification': {
    lg: {
      x: 4,
      y: 2,
      w: 2,
      h: 3,
    },
    md: {
      x: 0,
      y: 8,
      w: 3,
      h: 3,
    },
    s: {
      x: 0,
      y: 10,
      w: 1,
      h: 3,
    },
  },
  'user-categories-cost': {
    lg: {
      x: 0,
      y: 3,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 11,
      w: 3,
      h: 2,
    },
    s: {
      x: 0,
      y: 13,
      w: 1,
      h: 2,
    },
  },
  'number-of-absences-per-day': {
    lg: {
      x: 2,
      y: 3,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 13,
      w: 3,
      h: 2,
    },
    s: {
      x: 0,
      y: 15,
      w: 1,
      h: 2,
    },
  },
};

export const defaultClockingDashboardSettings: { [key: string]: Layouts } = {
  'geo-clockings': {
    lg: {
      x: 0,
      y: 0,
      w: 4,
      h: 5,
    },
    md: {
      x: 0,
      y: 0,
      w: 3,
      h: 3,
    },
    s: {
      x: 0,
      y: 0,
      w: 1,
      h: 3,
    },
  },
  'clockings-today': {
    lg: {
      x: 4,
      y: 0,
      w: 2,
      h: 3,
    },
    md: {
      x: 0,
      y: 3,
      w: 3,
      h: 3,
    },
    s: {
      x: 0,
      y: 3,
      w: 1,
      h: 3,
    },
  },
  'clockings-yesterday': {
    lg: {
      x: 4,
      y: 3,
      w: 2,
      h: 2,
    },
    md: {
      x: 0,
      y: 5,
      w: 3,
      h: 3,
    },
    s: {
      x: 0,
      y: 6,
      w: 1,
      h: 3,
    },
  },
};
