import { Button, Input, Select } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GetContractsParams } from '../api';
import { V4ContractFilter } from '../types/contracts.types';

interface Props {
  className?: string;
  onFilterChange: (filter: Partial<GetContractsParams>) => void;
  onSearchChange: (q: string) => void;
  currentSearch?: string;
  currentFilter?: V4ContractFilter;
}

const Header: React.FC<Props> = ({
  className,
  onFilterChange,
  onSearchChange,
  currentSearch = '',
  currentFilter = 'all',
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: userRecordId } = useParams<any>();

  const onCreateNewContract = () => {
    history.push('/app/documents/contracts/new', { userRecordId });
  };

  // Local state for immediate search input updates
  const [localSearch, setLocalSearch] = useState(currentSearch);

  useEffect(() => {
    setLocalSearch(currentSearch);
  }, [currentSearch]);

  const debouncedSearch = useMemo(() => debounce(onSearchChange, 500), [onSearchChange]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value;
    setLocalSearch(q);
    debouncedSearch(q);
  };

  const onFilter = (value: V4ContractFilter) => {
    let active: boolean | undefined;
    let signed: boolean | undefined;

    switch (value) {
      case 'active':
        active = true;
        break;
      case 'inactive':
        active = false;
        break;
      case 'signed':
        signed = true;
        break;
      case 'unsigned':
        signed = false;
        break;
      case 'all':
      default:
        active = undefined;
        signed = undefined;
        break;
    }

    onFilterChange({ active, signed });
  };

  return (
    <header className={className}>
      <div>
        <h2>{t('general.contracts')}</h2>
      </div>
      <div className="right">
        <Select
          placeholder={t('documents.contracts.filters.contract-status')}
          dropdownMatchSelectWidth={false}
          onChange={onFilter}
          value={currentFilter}
        >
          <Select.Option value="all">{t('documents.contracts.filters.all')}</Select.Option>
          <Select.Option value="active">{t('documents.contracts.filters.active')}</Select.Option>
          <Select.Option value="inactive">{t('documents.contracts.filters.inactive')}</Select.Option>
          <Select.Option value="signed">{t('documents.contracts.filters.signed')}</Select.Option>
          <Select.Option value="unsigned">{t('documents.contracts.filters.unsigned')}</Select.Option>
        </Select>
        <Input
          allowClear
          placeholder={t('general.search')}
          suffix={<i className="icon-search" />}
          onChange={onSearch}
          value={localSearch}
        />
        <Button type="primary" onClick={onCreateNewContract}>
          <i className="icon-plus" />
          {t('documents.contracts.create-contract')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
