import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { convertUtcToTimezoneDateAndTime } from '@/utils';

type ClockingTimeProps = {
  startDate: string;
  endDate: string;
  className?: string;
};

const ClockingTime: FC<ClockingTimeProps> = ({ startDate, endDate, className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <span className={className}>
      <p>{convertUtcToTimezoneDateAndTime(startDate, activeDepartment, 'HH:mm')}</p>-
      <p className={!endDate ? 'ongoing' : ''}>
        {endDate ? convertUtcToTimezoneDateAndTime(endDate, activeDepartment, 'HH:mm') : t('GLOBAL.IN_PROGRESS')}
      </p>
    </span>
  );
};

const ClockingTimeStyled = styled(ClockingTime)`
  display: flex;
  gap: 5px;

  p {
    margin: 0;
  }

  .ongoing {
    color: rgb(0, 166, 81);
  }
`;

export default ClockingTimeStyled;
