import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { Button, message, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sendContract } from '../api';
import { V4Contract } from '../types/contracts.types';

interface Props {
  className?: string;
  contractId: V4Contract['id'];
}

const ResendContract: React.FC<Props> = ({ className, contractId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading: loading } = useMutation({
    mutationKey: ['send-contract', { contractId }],
    mutationFn: () => sendContract(contractId),
    onSuccess: () => {
      message.success(t('documents.contracts.contract-resent'));
      client.invalidateQueries({ queryKey: ['get-contracts'] });
    },
  });

  return (
    <div className={className}>
      <Tooltip title={t('documents.contracts.resend-contract')}>
        <Button type="ghost" className="action" onClick={() => mutate()} loading={loading}>
          <i className="icon-paper-plane-empty" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(ResendContract)``;
