import React, { FC, useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import AppContext from '@/pages/app/context';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber } from 'antd';
import { useUpdateDailyTurnover } from '../hooks';
import { UpdateDailyTurnoverPayload, V4DailyTurnoverResponse } from '../types';

type TurnoverFormProps = {
  loading: boolean;
  startDate: Moment;
  sectionsAllowed: boolean;
  sectionId: string | null;
  dailyTurnover?: V4DailyTurnoverResponse;
};

export const TurnoverForm: FC<TurnoverFormProps> = ({ dailyTurnover, sectionsAllowed, startDate, loading, sectionId }) => {
  const [form] = useForm();
  const [formHasChanged, setFormHasChanged] = useState(false);

  const { t } = useTranslation();
  const { updateTurnover, isLoading: updateLoading } = useUpdateDailyTurnover(
    startDate.format('YYYY-MM-DD'),
    startDate.clone().endOf('month').format('YYYY-MM-DD'),
    sectionId,
  );

  useEffect(() => {
    if (!dailyTurnover) {
      return;
    }

    const provisionalData: { [$key: string]: number } = {};
    const realData: { [$key: string]: number } = {};

    Object.keys(dailyTurnover.provisional).forEach((date) => {
      provisionalData[date] = dailyTurnover.provisional[date];
      realData[date] = dailyTurnover.real[date];
    });

    form.setFieldsValue({
      provisional: provisionalData,
      real: realData,
    });

    setFormHasChanged(false);
  }, [dailyTurnover]);

  const onFinish = (values: UpdateDailyTurnoverPayload) => {
    updateTurnover(values);
  };

  if (loading || !dailyTurnover) {
    return null;
  }

  return (
    <Form form={form} onFinish={onFinish} onValuesChange={() => setFormHasChanged(true)} size="large">
      {dailyTurnover &&
        Object.keys(dailyTurnover.provisional).map((date) => {
          const day = moment(date);
          return (
            <div key={date} className="day">
              <span className="L">{day.format('L')}</span>
              <span className="weekday">{day.format('dddd')}</span>
              <Form.Item name={['provisional', date]}>
                <InputNumber type="number" disabled={sectionsAllowed && sectionId === null} />
              </Form.Item>
              <Form.Item name={['real', date]}>
                <InputNumber type="number" disabled={sectionsAllowed && sectionId === null} />
              </Form.Item>
            </div>
          );
        })}
      <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
        <Button type="primary" htmlType="submit" loading={updateLoading} style={{ width: '100%' }}>
          {t('GLOBAL.SAVE')}
        </Button>
      </Form.Item>
    </Form>
  );
};
