import React, { FC, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import colors from '@/styles/colors';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { isFeatureEnabled } from '@/utils';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ISection } from '@/types/section.model';
import { FEATURES } from '@/types/features.model';
import { Button, Form, InputNumber, Modal } from 'antd';
import { useDailyTurnovers, useDailyTurnoversUpdate } from '../hooks';

type ModalYesterdayTurnoverProps = {
  className?: string;
  visible: boolean;
  onClose: () => void;
};

type AllowedSections =
  | ISection
  | {
      id?: string | null;
      name?: string | null;
    };

const ModalYesterdayTurnover: FC<ModalYesterdayTurnoverProps> = ({ className, visible, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();
  const {
    state: { sections, features, activeDepartment },
  } = useContext(AppContext);
  const [completeCount, setCompleteCount] = useState(0);

  const date = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const { dailyTurnovers, loading } = useDailyTurnovers(date, date);
  const { updateDailyTurnovers, isLoading } = useDailyTurnoversUpdate(() => {
    history.go(0);
    onClose();
  });

  const isSectionAllowed =
    isFeatureEnabled(features, FEATURES.SECTIONS) && activeDepartment?.scheduleParams?.productivity_by_section_enabled;
  const allowedSections: AllowedSections[] = isSectionAllowed ? sections : [{ id: 'general', name: '' }];

  const onFinish = (values: any) => {
    updateDailyTurnovers({
      date,
      turnover: Object.keys(values).map((k) => ({
        sectionId: k === 'general' ? null : k,
        real: values[k] || 0,
      })),
    });
  };

  const onHide = () => {
    localStorage.setItem(`${activeDepartment?.id}_hideTurnover`, moment().format('YYYY-MM-DD'));
    onClose();
  };

  const handleIncompleteFieldsCount = () => {
    const newIncompleteCount = Object.keys(form.getFieldsValue()).filter((k) => form.getFieldValue(k) > 0).length;
    setCompleteCount(newIncompleteCount);
  };

  useEffect(() => {
    if (loading || !dailyTurnovers) {
      return;
    }

    form.setFieldsValue(
      allowedSections.reduce((acc, curr) => {
        const dailyTurnover = dailyTurnovers.find((t) => t.sectionId === curr.id);

        if (!dailyTurnover) {
          return acc;
        }

        acc[`${curr.id}`] = dailyTurnover.insights[date].value || null;
        return acc;
      }, {} as any),
    );
    handleIncompleteFieldsCount();
  }, [loading, dailyTurnovers]);

  if (
    loading ||
    !dailyTurnovers ||
    (dailyTurnovers.length > 0 && dailyTurnovers.every((t) => t.insights[date]?.value > 0))
  ) {
    return null;
  }

  return (
    <Modal
      className={className}
      footer={null}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      width="fit-content"
      onCancel={() => onClose()}
      title={
        <div>
          {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.TITLE')} <br />
          <span className={'subTitle'}>
            {isSectionAllowed && t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.DESCRIPTION')}
          </span>
        </div>
      }
    >
      <Form
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        onChange={() => handleIncompleteFieldsCount()}
        className={`${allowedSections.length === 1 && 'single-field-form'}`}
      >
        <div className={`count-wrapper ${completeCount === allowedSections.length && 'complete'}`}>
          {completeCount} / {allowedSections.length} {t('GLOBAL.COMPLETED')}
        </div>
        <div className={'form-fields-wrapper'}>
          {allowedSections.map((section) => (
            <Form.Item label={t(`${section.name}`)} name={`${section.id}`}>
              <InputNumber type="number" />
            </Form.Item>
          ))}
        </div>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
          <div className="links">
            <Button size="small" type="link" onClick={onHide}>
              <i className={'icon-check-empty'} />
              {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.NOT_SHOW_AGAIN')}
            </Button>
            <Button size="small" type="link" onClick={() => history.push('/app/settings/insights/daily-turnover')}>
              {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.SEE_HISTORY')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const ModalYesterdayTurnoverStyled = styled(ModalYesterdayTurnover)`
  .subTitle {
    font-size: 12px;
    color: gray;
  }

  form {
    width: 100%;
    
    .form-fields-wrapper {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    .ant-form-item {
      margin-bottom: 10px;
      gap: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      label {
        padding: 0;
      }
      
      .ant-form-item-control {
        max-width: fit-content;
      }
    }

    .ant-input-number {
      width: 150px;

      .ant-input-number-input-wrap {
        input {
          text-align: center;
        }
      }
    }

    &.single-field-form {
      .form-fields-wrapper {
        grid-template-columns: 1fr;
      }
      
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }

      .ant-input-number {
        width: 200px;
      }

      .count-wrapper {
        display: none;
      }
      
      .ant-form-item-control {
        max-width: 100%;
      } 
    }
  }

  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;

    .count-wrapper {
      width: fit-content;
      display: flex;
      font-size: 13px;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      margin-bottom: 20px;
      background-color: #d95c62;

      &.complete {
        background-color: #3aa119;
      }
    }

    .actions-container {
      margin-top: 20px;

      .actions {
        display: flex;
        gap: 20px;

        button {
          flex: 1;
        }
      }

      .links {
        display: flex;
        justify-content: center;
        padding-top: 10px;

        button {
          span {
            font-weight: normal;
            text-decoration: underline;
          }

          &:last-child {
            color: ${colors.grey};
          }
        }
      }
    }
  }
`;

export default ModalYesterdayTurnoverStyled;
