import Error404 from '@/components/Error404';
import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ContractsRoutes from './contracts';
import AppDocumentDimonaPage from './dimona';
import DocumentsRoutes from './documents';
import WeeklyReports from './weekly-reports';

interface Props {
  department?: IDepartment;
}

const AppDocumentsPage: React.FC<Props> = ({ department }) => {
  const { access } = department || {};
  const { path, url } = useRouteMatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);

  if (!!isFeatureEnabled(features, FEATURES.CONTRACTS) && !!access?.documents) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={`${path}`} render={() => <Redirect to={`${url}/documents`} />} />

          <Route path={`${path}/documents`}>
            <DocumentsRoutes />
          </Route>
          <Route path={`${path}/contracts`}>
            <ContractsRoutes />
          </Route>

          {activeDepartment?.accountType !== 'RGPD' && (
            <Route path={`${path}/weekly-reports/:reportId`}>
              <WeeklyReports />
            </Route>
          )}
          {activeDepartment?.accountType !== 'RGPD' && isFeatureEnabled(features, FEATURES.DIMONA) && access?.dimona && (
            <Route path={`${path}/dimona`}>
              <AppDocumentDimonaPage />
            </Route>
          )}
          <Route component={() => <Error404 />} />
        </Switch>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet>
          <title>{t('GLOBAL.DOCUMENTS')} - Shyfter</title>
        </Helmet>
        <Switch>
          {activeDepartment?.accountType !== 'RGPD' && (
            <Route path={`${path}/weekly-reports/:reportId`}>
              <WeeklyReports />
            </Route>
          )}
          {activeDepartment?.accountType !== 'RGPD' && isFeatureEnabled(features, FEATURES.DIMONA) && access?.dimona && (
            <Route path={`${path}/dimona`}>
              <AppDocumentDimonaPage />
            </Route>
          )}
          <Route component={() => <Error404 />} />
        </Switch>
      </React.Fragment>
    );
  }
};

export default AppDocumentsPage;
