import React, { FC, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import TableWidget from './widgets/TableWidget';
import WeatherWidget from './widgets/WeatherWidget';
import BarChartWidget from './widgets/BarChartWidget';
import LocationWidget from './widgets/LocationWidget';
import LineChartWidget from './widgets/LineChartWidget';
import PlainTextWidget from './widgets/PlainTextWidget';
import PercentageWidget from './widgets/PercentageWidget';
import moment, { Moment } from 'moment';
import { Button, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWidget, useWidgetUpdate } from '../hooks';
import { formatPrice, minutesToHoursAndOrMinutes } from '@/utils';
import {
  getClockings,
  getContractRenewal,
  getDimonasWithoutShifts,
  getNumberOfAbsencesPerDay,
  getOpenAbsencesRequest,
  getOpenShiftSwapRequests,
  getPlannedHoursVsRealHours,
  getScheduledShifts,
  getSectionsCost,
  getShiftsVerification,
  getShiftsWithoutDimona,
  getSicknessPercentagePerMonth,
  getSicknessPerUserCategory,
  getTurnover,
  getTurnoverTwin,
  getUnsignedDocumentsContracts,
  getUpcomingBirthdays,
  getUpcomingFreeShifts,
  getUserCategoryCost,
  getUpcomingAbsences,
  widgetTranslationsBySlug,
  availableColors
} from '../utils';
import {
  V4AbsencePercentageResponse,
  V4ClockingsTodayResponse,
  V4ClockingsYesterdayResponse,
  V4ContractsUpForRenewalResponse,
  V4DimonasWithoutShiftsResponse,
  V4GeoClockingsResponse,
  V4NumberOfAbsencesPerDayResponse,
  V4NumberOfFreeShiftsResponse,
  V4NumberOfHoursWorkedResponse,
  V4NumberOfPlannedShiftsResponse,
  V4OpenAbsencesRequestResponse,
  V4OpenShiftSwapRequestsResponse,
  V4PlannedHoursVsRealHoursResponse,
  V4ProductivityResponse,
  V4ScheduledTodayResponse,
  V4ScheduledTomorrowResponse,
  V4SectionsCostResponse,
  V4ShiftsVerificationResponse,
  V4ShiftsWithoutDimonaResponse,
  V4SicknessPercentagePerMonthResponse,
  V4SicknessPerUserCategoryResponse,
  V4TotalCostResponse,
  V4TotalTurnoverResponse,
  V4TurnoverResponse,
  V4UnsignedDocumentsContractsResponse,
  V4UpcomingAbsencesResponse,
  V4UpcomingBirthdaysResponse,
  V4UpcomingFreeShiftsResponse,
  V4UserCategoriesCostResponse,
  V4WeatherForecastResponse,
} from '../types';

type GridItemProps = {
  slug: string;
  color: string;
  className?: string;
  editing: boolean;
  startDate: Moment;
  endDate: Moment;
};

const GridItem: FC<GridItemProps> = ({ slug, color, className, editing, startDate, endDate }) => {
  const {
    state: { activeDepartment, activeSection },
  } = useContext(AppContext);

  const [color1, color2, color3, color4] = availableColors[
    Object.keys(availableColors).includes(color) ? color : '#00A651'
  ];

  const history = useHistory();
  const { t } = useTranslation();
  const { updateWidget } = useWidgetUpdate();
  const { widget, loading } = useWidget(
    slug,
    moment(startDate).format('Y-MM-DD'),
    moment(endDate).format('Y-MM-DD'),
    activeSection,
  );

  const handleRemoveWidget = useCallback((e) => {
    e.stopPropagation();
    updateWidget({
      slug,
      payload: { isVisible: false },
    });
  }, []);

  const widgetComponent = useMemo(() => {
    if (!widget) {
      return null;
    }

    switch (slug) {
      case 'number-of-planned-shifts':
        return (
          <PlainTextWidget value={(widget as V4NumberOfPlannedShiftsResponse).totalPlannedShifts} color={color1} />
        );

      case 'number-of-hours-worked':
        return (
          <PlainTextWidget
            value={minutesToHoursAndOrMinutes((widget as V4NumberOfHoursWorkedResponse).totalHoursWorked * 60)}
            color={color1}
          />
        );

      case 'number-of-free-shifts':
        return <PlainTextWidget value={(widget as V4NumberOfFreeShiftsResponse).totalFreeShifts} color={color1} />;

      case 'total-turnover':
        return <PlainTextWidget value={formatPrice((widget as V4TotalTurnoverResponse).total)} color={color1} />;

      case 'total-cost':
        return <PlainTextWidget value={formatPrice((widget as V4TotalCostResponse).totalCost)} color={color1} />;

      case 'productivity':
        return <PlainTextWidget value={(widget as V4ProductivityResponse).productivity} color={color1} />;

      case 'absence-percentage':
        const { absencePercentage } = widget as V4AbsencePercentageResponse;
        return <PercentageWidget percentage={absencePercentage} color={color1} />;

      case 'user-categories-cost':
        return <BarChartWidget data={getUserCategoryCost(widget as V4UserCategoriesCostResponse, color1, color4)} />;

      case 'sections-cost':
        return <BarChartWidget data={getSectionsCost(widget as V4SectionsCostResponse, color1, color4)} />;

      case 'planned-hours-vs-real-hours':
        return (
          <BarChartWidget
            data={getPlannedHoursVsRealHours(widget as V4PlannedHoursVsRealHoursResponse, color1, color4)}
          />
        );

      case 'number-of-absences-per-day':
        return (
          <BarChartWidget
            showLegend={false}
            data={getNumberOfAbsencesPerDay(widget as V4NumberOfAbsencesPerDayResponse, color1)}
          />
        );

      case 'sickness-per-user-category':
        return (
          <BarChartWidget
            showLegend={false}
            data={getSicknessPerUserCategory(widget as V4SicknessPerUserCategoryResponse, color1)}
          />
        );

      case 'sickness-percentage-per-month':
        return (
          <BarChartWidget
            showLegend={false}
            data={getSicknessPercentagePerMonth(widget as V4SicknessPercentagePerMonthResponse, color1)}
          />
        );

      case 'turnover':
        return <BarChartWidget data={getTurnover(widget as V4TurnoverResponse, startDate, color1, color4)} />;

      case 'turnover-twin':
        return <LineChartWidget data={getTurnoverTwin(widget as V4TurnoverResponse, startDate, color1, color4)} />;

      case 'contracts-up-for-renewal':
        return (
          <TableWidget
            {...getContractRenewal(
              widget as V4ContractsUpForRenewalResponse,
              (id) => history.push(`/app/documents/contracts/${id}`),
              (id) => history.push(`/app/team/collaborators/${id}`),
              activeDepartment,
            )}
          />
        );

      case 'shifts-verification':
        return (
          <TableWidget
            {...getShiftsVerification(widget as V4ShiftsVerificationResponse, (date) =>
              history.push(`/app/hours/manage/day/${date}`),
            )}
          />
        );

      case 'open-absence-requests':
        return (
          <TableWidget
            {...getOpenAbsencesRequest(
              widget as V4OpenAbsencesRequestResponse,
              (id) => history.push(`/app/hours/vacation-requests`),
              (id) => history.push(`/app/team/collaborators/${id}`),
              activeDepartment,
            )}
          />
        );

      case 'shifts-without-dimona':
        return (
          <TableWidget
            pagination={{ position: ['bottomRight'], defaultPageSize: 25 }}
            {...getShiftsWithoutDimona(
              widget as V4ShiftsWithoutDimonaResponse,
              (id) => history.push(`/app/documents/dimona/statement`),
              (id) => history.push(`/app/team/collaborators/${id}`),
              activeDepartment,
            )}
          />
        );

      case 'open-shift-swap-requests':
        return (
          <TableWidget
            {...getOpenShiftSwapRequests(
              widget as V4OpenShiftSwapRequestsResponse,
              (id) => history.push(`/app/hours/swap-shift`),
              (id) => history.push(`/app/team/collaborators/${id}`),
            )}
          />
        );

      case 'scheduled-today':
        return (
          <TableWidget
            {...getScheduledShifts(
              widget as V4ScheduledTodayResponse,
              (id) => history.push(`/app/hours`),
              (id) => history.push(`/app/team/collaborators/${id}`),
              activeDepartment,
            )}
          />
        );

      case 'scheduled-tomorrow':
        return (
          <TableWidget
            {...getScheduledShifts(
              widget as V4ScheduledTomorrowResponse,
              (id) => history.push(`/app/hours`),
              (id) => history.push(`/app/team/collaborators/${id}`),
            )}
          />
        );

      case 'upcoming-birthdays':
        return (
          <TableWidget
            {...getUpcomingBirthdays(
              widget as V4UpcomingBirthdaysResponse,
              (id) => history.push(`/app/team/collaborators/${id}`),
              activeDepartment,
            )}
          />
        );

      case 'upcoming-free-shifts':
        return (
          <TableWidget
            {...getUpcomingFreeShifts(widget as V4UpcomingFreeShiftsResponse, (date) =>
              history.push(`/app/hours/manage/week/${date}`),
            )}
          />
        );

      case 'clockings-today':
      case 'clockings-yesterday':
        return (
          <TableWidget
            {...getClockings(widget as V4ClockingsTodayResponse | V4ClockingsYesterdayResponse, (id) =>
              history.push(`/app/team/collaborators/${id}`),
            )}
          />
        );

      case 'unsigned-documents-contracts':
        return (
          <TableWidget
            pagination={{ position: ['bottomRight'], defaultPageSize: 25 }}
            {...getUnsignedDocumentsContracts(
              widget as V4UnsignedDocumentsContractsResponse,
              (id, type) => history.push(`/app/documents/${type}s`),
              (id) => history.push(`/app/team/collaborators/${id}`),
            )}
          />
        );

      case 'dimonas-without-shifts':
        return (
          <TableWidget
            {...getDimonasWithoutShifts(widget as V4DimonasWithoutShiftsResponse, (id) =>
              history.push(`/app/documents/dimona/statement`),
            )}
          />
        );

      case 'upcoming-absences':
        return <TableWidget {...getUpcomingAbsences(widget as V4UpcomingAbsencesResponse, activeDepartment)} />;

      case 'geo-clockings':
        return <LocationWidget editing={editing} data={widget as V4GeoClockingsResponse} />;

      case 'weather-forecast':
        return <WeatherWidget weatherData={widget as V4WeatherForecastResponse} color={color1} />;

      default:
        return <div>Widget not found</div>;
    }
  }, [editing, widget, startDate, color]);

  return (
    <div className={className}>
      <Spin spinning={loading}>
        <div className={'header'}>
          <div className={'title'}>{t(widgetTranslationsBySlug[slug])}</div>
          {editing && (
            <Button
              type={'text'}
              className={'remove-button'}
              onTouchStart={(e) => handleRemoveWidget(e)}
              onMouseDown={(e) => handleRemoveWidget(e)}
            >
              {t('GLOBAL.REMOVE')}
            </Button>
          )}
        </div>
        {widgetComponent}
      </Spin>
    </div>
  );
};

const GridItemStyled = styled(GridItem)`
  height: 100%;
  padding: 15px;
  overflow: auto;
  border-radius: 4px;
  background-color: white;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      font-size: 0.7rem;
      font-weight: 100;
      color: lightslategrey;
    }

    .remove-button {
      height: 20px;
      padding: 0 5px;

      span {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
`;

export default GridItemStyled;
