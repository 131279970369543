import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table/interface';
import { Button, Popconfirm, Table, Tag } from 'antd';
import { V4TurnoverTemplateResponse } from '../types';
import { CreateTemplateModal, UploadTemplateModal } from '../components';
import { useTurnoverTemplateDelete, useTurnoverTemplates } from '../hooks';

type ListTableProps = {
  className?: string;
};

const ListTable: FC<ListTableProps> = ({ className }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const {
    state: { sections },
  } = useContext(AppContext);

  const { turnoverTemplates, loading } = useTurnoverTemplates();
  const { deleteTemplate, isLoading: deleteLoading } = useTurnoverTemplateDelete();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [columns, setColumns] = useState<ColumnsType<V4TurnoverTemplateResponse>>([]);

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        width: '100%',
      },
      {
        key: 'extra',
        render: (_, record: V4TurnoverTemplateResponse) => {
          const section = sections.find((s) => s.id === record.section_id);
          return (
            <div className="template-actions-wrapper">
              {record.is_default && <Tag color={'blue'}>{t('GLOBAL.DEFAULT')}</Tag>}
              {section && <Tag color={section.background}>{section.name}</Tag>}
              <Popconfirm
                title={t('GLOBAL.ARE_YOU_SURE?')}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  deleteTemplate(record.id);
                }}
                onCancel={(e) => e?.stopPropagation()}
              >
                <Button type="primary" danger disabled={deleteLoading} onClick={(e) => e.stopPropagation()}>
                  <i className="icon-trash-empty" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ]);
  }, [turnoverTemplates]);

  const onTemplateGo = (templateId: string) => {
    return {
      onClick: () => {
        history.push(`/app/settings/insights/turnover-templates/${templateId}`);
      },
    };
  };

  if (loading || !turnoverTemplates) {
    return null;
  }

  return (
    <div className={className}>
      <div className={'header-wrapper'}>
        <Button className="add-btn" type="primary" onClick={() => setCreateModalVisible(true)}>
          <i className="icon-plus" /> {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
        </Button>
        <Button type="ghost" onClick={() => setUploadModalVisible(true)}>
          <i className="icon-download" /> {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.IMPORT_FROM_FILE')}
        </Button>
      </div>
      <Table
        rowKey="id"
        dataSource={turnoverTemplates}
        columns={columns}
        loading={loading}
        pagination={false}
        showHeader={false}
        onRow={({ id }) => onTemplateGo(id)}
      />
      <CreateTemplateModal
        isVisible={createModalVisible}
        onVisibilityChange={(newVisibility) => setCreateModalVisible(newVisibility)}
      />
      <UploadTemplateModal
        isVisible={uploadModalVisible}
        onVisibilityChange={(newVisibility) => setUploadModalVisible(newVisibility)}
      />
    </div>
  );
};

export const ListTableStyled = styled(ListTable)`
  background-color: white;
  padding: 20px;

  .header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    i {
      margin-right: 5px;
    }
  }

  td {
    cursor: pointer;
    padding: 10px !important;
  }

  .template-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
