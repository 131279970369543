import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { V4Document } from '../types/documents.types';

interface Props {
  className?: string;
  document: V4Document;
}

const EditDocument: React.FC<Props> = ({ className, document }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onEditDocument = () => {
    history.push(`/app/documents/documents/${document.id}`);
  };

  return (
    <div className={className}>
      <Tooltip title={t('documents.documents.edit-document')}>
        <Button type="ghost" className="action" onClick={onEditDocument}>
          <i className="icon-edit" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(EditDocument)``;
