import React, {FC, useContext} from 'react';
import AppContext from '@/pages/app/context';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

type SectionSelectorProps = {
  sectionId: string | null;
  onTabChange: (sectionId: string) => void;
}

export const SectionSelector: FC<SectionSelectorProps> = ({ sectionId, onTabChange }) => {
  const { t } = useTranslation();
  const {
    state: { sections },
  } = useContext(AppContext);

  return (
    <Tabs onChange={onTabChange}>
      <Tabs.TabPane key={null} tab={t('GLOBAL.GENERAL')}></Tabs.TabPane>
      {sections.map((section) => (
        <Tabs.TabPane
          key={section.id}
          tab={
            <div>
              <span className="name" style={{ color: sectionId === section?.id ? section?.background : 'inherit' }}>
                {section?.name ?? t('GLOBAL.ALL')}
              </span>
            </div>
          }
        />
      ))}
    </Tabs>
  );
};
