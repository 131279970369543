import RangePickerExtraFooter from '@/pages/app/components/RangePickerExtraFooter';
import colors from '@/styles/colors';
import { handleError, REGIME_TYPES } from '@/utils';
import { CaretRightOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, DatePicker, Form, Input, InputNumber, List, message, Row, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createProfileHrContract, deleteProfileHrContract, editProfileHrContract } from '../api';
import { V4GetProfileHrContractsResponse, V4GetProfileResponse, V4ProfileHrContract } from '../types/profile.types';

interface Props {
  className?: string;
  profile: V4GetProfileResponse;
  form: FormInstance<any>;
  profileHrContracts: V4GetProfileHrContractsResponse | null;
}

const HrContracts: React.FC<Props> = ({ className, profile, profileHrContracts, form }) => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<[Moment, Moment]>([
    moment().startOf('month').subtract(1, 'month'),
    moment().endOf('month').add(1, 'month'),
  ]);
  const [allProfileHrContracts, setAllProfileHrContracts] = useState<V4ProfileHrContract[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [selectedWageCode, setSelectedWageCode] = useState<
    | {
        id: string;
        label: string;
        type: string;
      }
    | undefined
  >();
  const [wageCodeTypes, setWageCodeTypes] = useState<
    {
      id: string;
      label: string;
      type: string;
    }[]
  >([]);

  useEffect(() => {
    if (profileHrContracts) {
      setWageCodeTypes(profileHrContracts.fields.wageCodeTypes);
    }
  }, [profileHrContracts]);

  useEffect(() => {
    if (!profileHrContracts) return;
    setAllProfileHrContracts(
      profileHrContracts.data.filter((contract) =>
        contract.endDate
          ? moment(contract.startDate).isSameOrAfter(dates[0]) && moment(contract.endDate).isSameOrBefore(dates[1])
          : moment(contract.startDate).isBetween(dates[0], dates[1]),
      ),
    );
    setActiveKey(profileHrContracts.data.length > 0 ? [profileHrContracts.data[0].id] : []);
  }, [profileHrContracts, form, dates]);

  const onAddProfileHrContract = () => {
    const lastContract = allProfileHrContracts
      .filter((contract) => contract.startDate)
      .reduce((latestContract: any, currentContract) => {
        if (!latestContract || moment(currentContract.startDate).isAfter(moment(latestContract.startDate))) {
          return currentContract;
        }
        return latestContract;
      }, null);

    let newProfileHrContract = {
      id: `new-${Date.now()}`,
      startDate: moment().format('YYYY-MM-DD'),
    };

    if (lastContract) {
      newProfileHrContract = {
        ...lastContract,
        id: `new-${Date.now()}`,
      };
    }

    setSelectedWageCode(undefined);

    setAllProfileHrContracts(
      (prev) =>
        [
          ...prev,
          {
            ...newProfileHrContract,
            startDate: moment(newProfileHrContract.startDate),
          },
        ] as any,
    );

    form.setFieldsValue({
      profileHrContracts: [
        ...(allProfileHrContracts || []).map((profileHrContract) => ({
          ...profileHrContract,
          startDate: moment(newProfileHrContract.startDate),
          hireDate: moment(profileHrContract.hireDate),
          endDate: moment(profileHrContract.endDate),
          seniorityDate: moment(profileHrContract.seniorityDate),
          careerStartDate: moment(profileHrContract.careerStartDate),
        })),
        {
          ...newProfileHrContract,
          startDate: moment(newProfileHrContract.startDate),
          hireDate: (newProfileHrContract as any).hireDate ? moment((newProfileHrContract as any).hireDate) : null,
          endDate: (newProfileHrContract as any).endDate ? moment((newProfileHrContract as any).endDate) : null,
          seniorityDate: (newProfileHrContract as any).seniorityDate
            ? moment((newProfileHrContract as any).seniorityDate)
            : null,
          careerStartDate: (newProfileHrContract as any).careerStartDate
            ? moment((newProfileHrContract as any).careerStartDate)
            : null,
        },
      ],
    });

    setActiveKey([newProfileHrContract.id]);
  };

  const onSaveProfileHrContract = async (profileHrContractId: string) => {
    const { profileHrContracts } = form.getFieldsValue();
    const profileHrContract = profileHrContracts
      .filter((contract: any) => contract != undefined)
      .filter((contract: any) => !!contract.id)
      .find((profileHrContract: any) => profileHrContract.id == profileHrContractId);
    if (!profileHrContract) return;

    try {
      await createProfileHrContract(profile.data.id, {
        ...profileHrContract,
        startDate: moment(profileHrContract.startDate).format('YYYY-MM-DD'),
        endDate: profileHrContract.endDate ? moment(profileHrContract.endDate).format('YYYY-MM-DD') : undefined,
        hireDate: profileHrContract.hireDate ? moment(profileHrContract.hireDate).format('YYYY-MM-DD') : undefined,
        seniorityDate: profileHrContract.seniorityDate
          ? moment(profileHrContract.seniorityDate).format('YYYY-MM-DD')
          : undefined,
        careerStartDate: profileHrContract.careerStartDate
          ? moment(profileHrContract.careerStartDate).format('YYYY-MM-DD')
          : undefined,
        id: undefined,
      });
      message.success(t('users.profile.hr-contracts.hr-contract-created'));
    } catch (error) {
      handleError(error);
    }
  };

  const onEditProfileHrContract = async (profileHrContractId: string) => {
    if (!profile) return;

    const { profileHrContracts } = form.getFieldsValue();
    const profileHrContract = profileHrContracts
      .filter((contract: any) => contract != undefined)
      .filter((contract: any) => !!contract.id)
      .find((profileHrContract: any) => profileHrContract.id == profileHrContractId);
    if (!profileHrContract) return;

    try {
      await editProfileHrContract(profile.data.id, profileHrContractId, {
        ...profileHrContract,
        startDate: moment(profileHrContract.startDate).format('YYYY-MM-DD'),
        endDate: profileHrContract.endDate ? moment(profileHrContract.endDate).format('YYYY-MM-DD') : undefined,
        hireDate: profileHrContract.hireDate ? moment(profileHrContract.hireDate).format('YYYY-MM-DD') : undefined,
        seniorityDate: profileHrContract.seniorityDate
          ? moment(profileHrContract.seniorityDate).format('YYYY-MM-DD')
          : undefined,
        careerStartDate: profileHrContract.careerStartDate
          ? moment(profileHrContract.careerStartDate).format('YYYY-MM-DD')
          : undefined,
        id: undefined,
      });
      message.success(t('users.profile.hr-contracts.hr-contract-edited'));
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteProfileHrContract = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    profileHrContractId: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!profile) return;

    const { profileHrContracts } = form.getFieldsValue();
    const profileHrContract = profileHrContracts
      .filter((contract: any) => contract != undefined)
      .filter((contract: any) => !!contract.id)
      .find((profileHrContract: any) => profileHrContract.id == profileHrContractId);
    if (!profileHrContract) return;

    if (profileHrContract.id.startsWith('new-')) {
      setAllProfileHrContracts((prev) =>
        prev.filter((profileHrContract) => profileHrContract.id !== profileHrContractId),
      );
      return;
    }

    try {
      await deleteProfileHrContract(profile.data.id, profileHrContractId, { force: true });
      message.success(t('users.profile.hr-contracts.hr-contract-deleted'));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className={className}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h4>{t('users.profile.hr-contracts.title')}</h4>
        <DatePicker.RangePicker
          allowClear={false}
          getPopupContainer={(trigger) => trigger}
          format={'L'}
          value={dates}
          onChange={(dates: any) => setDates(dates)}
          renderExtraFooter={() => <RangePickerExtraFooter setRangePickerValues={setDates} lastYear={false} />}
        />
      </div>
      {profileHrContracts && profileHrContracts.missingProfileFields.length > 0 && (
        <div style={{ backgroundColor: colors.redLight, padding: 20, marginBottom: 20, borderRadius: 10 }}>
          <h3 style={{ color: colors.red, textDecoration: 'underline' }}>
            {t('users.profile.hr-contracts.missing-fields')}
          </h3>
          <ul className="missing-fields">
            {profileHrContracts.missingProfileFields.map((field) => (
              <li>{field}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="content">
        {allProfileHrContracts.length > 0 && (
          <Collapse
            accordion
            activeKey={activeKey}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            onChange={(key) => setActiveKey(key as string[])}
          >
            {allProfileHrContracts.map((profileHrContract, i) => {
              const newProfileHrContract = profileHrContract.id.startsWith('new-');

              return (
                <Collapse.Panel
                  header={
                    profileHrContract.endDate
                      ? t('general.from-date-to-date', {
                          start: profileHrContract.startDate,
                          end: profileHrContract.endDate,
                        })
                      : t('general.since-date', { date: profileHrContract.startDate })
                  }
                  key={profileHrContract.id}
                  id={profileHrContract.id}
                  className="site-collapse-custom-panel"
                  extra={
                    <div>
                      {profileHrContract?.external?.exporting && (
                        <Tooltip overlay={t('GLOBAL.EXPORTING')}>
                          <i className="icon-arrows-cw" style={{ marginRight: 10 }} />
                        </Tooltip>
                      )}
                      <Button type="primary" danger onClick={(e) => onDeleteProfileHrContract(e, profileHrContract.id)}>
                        <i className="icon-trash-empty" />
                      </Button>
                    </div>
                  }
                >
                  <Form.Item name={['profileHrContracts', i, 'id']} hidden />
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('general.start-date')}
                        name={['profileHrContracts', i, 'startDate']}
                        rules={[{ required: true }]}
                      >
                        <DatePicker size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('general.end-date')} name={['profileHrContracts', i, 'endDate']}>
                        <DatePicker size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('documents.contracts.fields.days-week')}
                        name={['profileHrContracts', i, 'daysWeek']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          style={{ width: '100%' }}
                          step="0.0001"
                          parser={(value) => {
                            return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('documents.contracts.fields.hire-date')}
                        name={['profileHrContracts', i, 'hireDate']}
                      >
                        <DatePicker size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('documents.contracts.fields.seniority-date')}
                        name={['profileHrContracts', i, 'seniorityDate']}
                      >
                        <DatePicker size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('documents.contracts.fields.career-start-date')}
                        name={['profileHrContracts', i, 'careerStartDate']}
                      >
                        <DatePicker size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item label={t('documents.contracts.fields.role')} name={['profileHrContracts', i, 'role']}>
                        <Input
                          style={{ width: '100%' }}
                          size="large"
                          placeholder={t('documents.contracts.fields.role')}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={t('documents.contracts.fields.working-time-plan')}
                        name={['profileHrContracts', i, 'regime']}
                      >
                        <Select
                          allowClear
                          getPopupContainer={(trigger) => trigger}
                          placeholder={t('documents.contracts.fields.working-time-plan')}
                          optionFilterProp="children"
                          size="large"
                        >
                          {REGIME_TYPES.map((type) => {
                            return (
                              <Select.Option key={`regime_${type}`} value={type!}>
                                {t(`CONTRACTS.REGIME_TYPES.${type}`)}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={
                          <div>
                            <span>{t('documents.contracts.fields.effective-hours-week')}</span>
                            <Tooltip overlay="">
                              <span>
                                <i className="icon-info-circled" />
                              </span>
                            </Tooltip>
                          </div>
                        }
                        name={['profileHrContracts', i, 'effectiveHoursPerWeek']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          style={{ width: '100%' }}
                          step="1"
                          parser={(value) => {
                            return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    <Col lg={8} md={12} style={{ width: '100%' }}>
                      <Form.Item
                        label={
                          <div>
                            <span>{t('documents.contracts.fields.standard-hours-week')}</span>
                            <Tooltip overlay="">
                              <span>
                                <i className="icon-info-circled" />
                              </span>
                            </Tooltip>
                          </div>
                        }
                        name={['profileHrContracts', i, 'standardHoursPerWeek']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          style={{ width: '100%' }}
                          step="1"
                          parser={(value) => {
                            return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.List name={['profileHrContracts', i, 'wageCompositions']}>
                    {(fields, { add, remove }) => (
                      <>
                        <h4>Wage compositions</h4>
                        <List
                          bordered
                          dataSource={fields}
                          renderItem={(field, index) => (
                            <List.Item
                              actions={[<MinusCircleOutlined key="remove" onClick={() => remove(field.name)} />]}
                            >
                              <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                <Col lg={12} md={12}>
                                  <Form.Item
                                    label="Wage code"
                                    name={[field.name, 'id']}
                                    rules={[{ required: true }]}
                                    style={{ flex: 1 }}
                                  >
                                    <Select
                                      placeholder="Wage code"
                                      size="large"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                                      }
                                    >
                                      {Object.entries(
                                        (profileHrContracts?.fields.wageCodes || [])
                                          .filter((code) => code.active)
                                          .reduce((groups, wageCode) => {
                                            const group = wageCode.group || 'Other';
                                            if (!groups[group]) groups[group] = [];
                                            groups[group].push(wageCode);
                                            return groups;
                                          }, {} as any),
                                      ).map(([group, wageCodes]) => (
                                        <Select.OptGroup key={group} label={group}>
                                          {(wageCodes as any).map((wageCode: any) => (
                                            <Select.Option
                                              key={wageCode.id}
                                              value={wageCode.id}
                                              label={wageCode.label}
                                              disabled={!wageCode.active}
                                            >
                                              {wageCode.label}
                                            </Select.Option>
                                          ))}
                                        </Select.OptGroup>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col lg={12} md={12}>
                                  {/* Conditionally render the second input when wage code is selected */}
                                  <Form.Item shouldUpdate>
                                    {({ getFieldValue }) => {
                                      const selectedWageCodeId = getFieldValue([
                                        'profileHrContracts',
                                        i,
                                        'wageCompositions',
                                        field.name,
                                        'id',
                                      ]);

                                      const selectedWageCode = profileHrContracts?.fields.wageCodes.find(
                                        (code) => code.id === selectedWageCodeId,
                                      );

                                      if (!selectedWageCode) return;

                                      const wageCodeType = wageCodeTypes.find(
                                        (wageCodeType) => wageCodeType.id == selectedWageCode.type,
                                      );

                                      return wageCodeType ? (
                                        <Form.Item
                                          label={wageCodeType.label}
                                          style={{ flex: 1 }}
                                          name={[field.name, 'value']}
                                          rules={[{ required: true }]}
                                        >
                                          {wageCodeType.type === 'float' ? (
                                            <InputNumber
                                              type="number"
                                              min={0}
                                              step="0.0001"
                                              size="large"
                                              parser={(value) =>
                                                parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '')
                                              }
                                              style={{ width: '100%' }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Form.Item>
                                      ) : null;
                                    }}
                                  </Form.Item>
                                </Col>
                              </div>
                            </List.Item>
                          )}
                        />
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={{ marginTop: 10, marginBottom: 25 }}
                        >
                          {t('GLOBAL.ADD_WAGE_COMPOSITION')}
                        </Button>
                      </>
                    )}
                  </Form.List>
                  <br />
                  <Button
                    type="primary"
                    onClick={() =>
                      newProfileHrContract
                        ? onSaveProfileHrContract(profileHrContract.id)
                        : onEditProfileHrContract(profileHrContract.id)
                    }
                  >
                    {newProfileHrContract ? t('general.save') : t('general.save-changes')}
                  </Button>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
        <Button style={{ marginTop: 25 }} type="primary" onClick={onAddProfileHrContract}>
          <i className="icon-plus" /> {t('users.profile.hr-contracts.new-hr-contract')}
        </Button>
      </div>
    </div>
  );
};

export default styled(HrContracts)`
  .ant-list-item {
    .ant-form-item {
      margin: 0;
    }
  }
`;
