import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IAddress } from '@/types/address.model';
import { ISection } from '@/types/section.model';
import { getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, Modal } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomColorPicker from '../CustomColorPicker';
import PlaceAutocomplete from '../PlaceAutocomplete';
import ValidationMessages from '../ValidationMessages';

interface Props {
  className?: string;
  visible: boolean;
  section?: ISection | null;
  onClose: () => void;
  onSave: (result: ISection) => void;
}

const DrawerSection: React.FC<Props> = ({ className, visible, section, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [address, setAddress] = useState<IAddress | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      ...initialValues,
    };

    if (section) {
      const values = _(section).pick(['name', 'background', 'price', 'address', 'ay_location_id']).value();
      fieldsValue = {
        ...fieldsValue,
        ...values,
        address: section.address?.address,
      };

      if (!fieldsValue.background) {
        fieldsValue.background = '#ffffff';
      }
      setAddress(values.address ? values.address : null);
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [section]);

  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);

  const initialValues: any = {
    background: '#03a9f4',
  };

  const onAddressChange = (address: IAddress) => {
    form.setFieldsValue({
      ...address,
      address: address.fullAddress,
    });
    setAddress(address);
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let userStatusResponse;

    try {
      if (section && !_.isEmpty(section)) {
        userStatusResponse = await axios.patch(
          `${process.env.REACT_APP_API_URL}/v3/sections/${section.id!}`,
          {
            ...values,
            address,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        );
      } else {
        userStatusResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/sections`,
          {
            departmentId: activeDepartmentId,
            ...values,
            address,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        );
      }

      if (userStatusResponse) {
        onSave({
          ...userStatusResponse?.data.section,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error: any) {
      if (error && error.response && error.response.data && error.response.data.errors) {
        const messages = Object.values(error.response.data.errors).map((array: any) => array.join(','));
        if (messages.length > 0) {
          Modal.confirm({
            className: 'modal-danger',
            icon: null,
            title: t('GLOBAL.ERROR(S)'),
            content: <ValidationMessages messages={messages} />,
            cancelText: t('GLOBAL.CANCEL'),
            okText: t('GLOBAL.OK'),
          });
        }
      }
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={section?.id ? t('SECTIONS.SECTION_MODIFICATION') : t('SECTIONS.ADDING_SECTION')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('SECTIONS.SECTION_NAME_PLACEHOLDER')} id="section-name" />
        </Form.Item>
        <Form.Item label={t('GLOBAL.ADDRESS')} name="address">
          <PlaceAutocomplete onAddressChange={onAddressChange} size="large" />
        </Form.Item>
        {activeDepartment?.ay_core && (
          <Form.Item name="ay_location_id" label={t('GLOBAL.AY_ID')}>
            <Input placeholder={t('GLOBAL.AY_ID')} />
          </Form.Item>
        )}
        <Form.Item
          label={t('GLOBAL.COLOR')}
          shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
        >
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="section-background" />
                </Form.Item>
                <CustomColorPicker form={form} color={background} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerSection;
