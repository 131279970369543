import React, { FC, useMemo } from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import WidgetToggle from './WidgetToggle';
import DateSelector from '../../components/DateSelector';
import { Moment } from 'moment';
import { Popover, Switch } from 'antd';
import { V4WidgetResponse } from '../types';
import { useTranslation } from 'react-i18next';
import { clockingDashboardWidgets } from '../utils';

import ColorPicker from '@/pages/app/dashboard/components/color-picker/ColorPicker';

type HeaderProps = {
  widgets: V4WidgetResponse[];
  editing: boolean;
  clockingOnly: boolean;
  className?: string;
  timeFrame: {
    startDate: Moment;
    endDate: Moment;
  };
  handleDatePickerChange: (date: Moment) => void;
  handleEditingChange: (checked: boolean) => void;
  selectedColor: string;
};

const Header: FC<HeaderProps> = ({
  widgets,
  editing,
  timeFrame,
  className,
  clockingOnly,
  selectedColor,
  handleEditingChange,
  handleDatePickerChange,
}) => {
  const { t } = useTranslation();
  const widgetToggles = useMemo(() => {
    return widgets
      .filter((w) => (clockingOnly ? clockingDashboardWidgets.includes(w.slug) : true))
      .map(({ slug, configuration }) => <WidgetToggle key={slug} slug={slug} {...configuration} />);
  }, [widgets]);

  return (
    <div className={className}>
      <span className={'title'}>{t('GLOBAL.DASHBOARD')}</span>
      <DateSelector
        picker="week"
        className={'date-selector'}
        onDatePickerChange={(date) => date && handleDatePickerChange(date)}
        onArrowClick={(value) => value && handleDatePickerChange(moment(timeFrame.startDate).add(value, 'week'))}
        {...timeFrame}
      />
      {editing && <ColorPicker selectedColor={selectedColor} />}
      <Popover
        trigger={['click']}
        placement={'bottomLeft'}
        content={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '5px',
            }}
          >
            {widgetToggles}
          </div>
        )}
      >
        {editing && (
          <div className={'toggle-visibility'}>
            <i className="icon-settings" />
          </div>
        )}
      </Popover>
      <Switch checked={editing} onChange={handleEditingChange} unCheckedChildren={t('GLOBAL.EDIT')} />
    </div>
  );
};

const HeaderStyled = styled(Header)`
  margin: 20px 10px 5px;
  display: flex;
  align-items: center;

  .title {
    font-size: 20px;
    margin-right: 10px;
  }

  .date-selector {
    margin-right: auto;
  }

  .toggle-visibility {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid rgb(226, 230, 239);
    margin-right: 10px;
  }
`;

export default HeaderStyled;
