import DrawerActions from '@/components/drawer-actions';
import { useWindowSize } from '@/hooks/use-window-size';
import AppContext from '@/pages/app/context';
import { client } from '@/ReactQueryProvider';
import colors from '@/styles/colors';
import { appendToFilename, dataURItoBlob } from '@/utils';
import { InboxOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Drawer, Form, Image, Input, message, Radio, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import { uploadFileToSignedUrl } from '../../api';
import { createSignature, updateSignature } from '../api';
import { CreateSignaturePayload, UpdateSignaturePayload, V4Signature } from '../types/signatures.types';

interface Props {
  className?: string;
  signature: V4Signature | null;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerSignature: React.FC<Props> = ({ className, signature, visible, setVisible }) => {
  const { t } = useTranslation();
  const { drawerWidth } = useWindowSize();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const canvasRef = useRef<any>(null);

  const onSuccess = () => {
    client.invalidateQueries({ queryKey: ['get-signatures'] });
    setVisible(false);
  };

  const { mutateAsync: createSignatureMutation, isLoading: loadingCreate } = useMutation({
    mutationKey: ['create-signature'],
    mutationFn: (payload: CreateSignaturePayload) => createSignature(payload),
    onSuccess,
  });

  const { mutateAsync: updateSignatureMutation, isLoading: loadingUpdate } = useMutation({
    mutationKey: ['update-signature'],
    mutationFn: (payload: UpdateSignaturePayload) => updateSignature(payload),
    onSuccess,
  });

  const [form] = Form.useForm();
  const [empty, setEmpty] = useState<boolean>(true);
  const [file, setFile] = useState<RcFile | null>(null);
  const [signatureImage, setSignatureImage] = useState<string>('');

  useEffect(() => {
    if (!visible) return;
    form.resetFields();
    if (signature) {
      form.setFieldsValue({ ...signature });
      setSignatureImage(signature ? signature.url : '');
    }
  }, [visible, signature]);

  const onFinish = async (values: any) => {
    const blob = canvasRef.current ? dataURItoBlob(canvasRef.current.toDataURL()) : null;
    const filename = `signature_${activeDepartmentId}_${moment().unix()}.png`;
    let data = null;

    if (blob) {
      data = await uploadFileToSignedUrl(
        [{ filename: filename, filetype: 'image/png' }],
        [new File([blob], filename, { type: 'image/png' })],
        false,
      );
    } else {
      if (file) {
        data = await uploadFileToSignedUrl(
          [{ filename: appendToFilename(file.name, `${activeDepartmentId}_${moment().unix()}`), filetype: file.type }],
          [file],
        );
      }
    }

    if (signature) {
      await updateSignatureMutation({
        ...values,
        id: signature.id,
        signaturePath: data?.filenames[0],
      });
      message.success(t('documents.signatures.signature-updated'));
    } else {
      await createSignatureMutation({
        ...values,
        default: values.default || false,
        signaturePath: data?.filenames[0],
      });
      message.success(t('documents.signatures.signature-created'));
    }
  };

  const onSignatureBegin = () => {
    setEmpty(false);
  };

  const onClearSignature = () => {
    canvasRef.current?.clear();
    setEmpty(true);
    setSignatureImage('');
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return false;
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Drawer
      className={className}
      visible={visible}
      onClose={onClose}
      title={signature ? t('documents.signatures.edit-signature') : t('documents.signatures.create-signature')}
      width={drawerWidth}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{ type: 'draw' }}
      >
        <Form.Item name="name" label={t('documents.signatures.signatory')} rules={[{ required: true }]}>
          <Input placeholder={t('documents.signatures.signatory')} />
        </Form.Item>
        <Form.Item name="title" label={t('documents.signatures.signatory-function')} rules={[{ required: true }]}>
          <Input placeholder={t('documents.signatures.signatory-function')} />
        </Form.Item>
        <Form.Item name="default" valuePropName="checked">
          <Checkbox>{t('documents.signatures.default-signature')}</Checkbox>
        </Form.Item>
        {signatureImage && (
          <React.Fragment>
            <Image src={signatureImage} />
            <Button block type="ghost" onClick={onClearSignature}>
              {t('documents.signatures.clear-signature')}
            </Button>
          </React.Fragment>
        )}
        {!signatureImage && (
          <React.Fragment>
            <Form.Item name="type">
              <Radio.Group>
                <Radio value="draw">{t('documents.signatures.types.draw')}</Radio>
                <Radio value="file">{t('documents.signatures.types.file')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate={(prev, curr) => prev.type !== curr.type}>
              {() => {
                const type = form.getFieldValue('type');
                if (type === 'draw') {
                  return (
                    <div className="signature-container">
                      <div className="signature">
                        <SignaturePad options={{ onBegin: onSignatureBegin }} redrawOnResize={true} ref={canvasRef} />
                        {empty && <span>{t('documents.signatures.sign-here')}</span>}
                      </div>
                      <Button disabled={empty} type="ghost" onClick={onClearSignature}>
                        {t('documents.signatures.clear-signature')}
                      </Button>
                    </div>
                  );
                }

                return (
                  <Upload.Dragger beforeUpload={beforeUpload} maxCount={1} multiple={false} accept="image/*">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('UPLOAD.DRAGGER.TEXT')}</p>
                  </Upload.Dragger>
                );
              }}
            </Form.Item>
          </React.Fragment>
        )}
        <DrawerActions onClose={onClose} loading={loadingCreate || loadingUpdate} />
      </Form>
    </Drawer>
  );
};

export default styled(DrawerSignature)`
  .signature-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .signature {
      background: ${colors.blueExtraLight};
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        position: absolute;
        font-size: 28px;
        color: ${colors.grey};
        pointer-events: none;
      }
    }
  }
`;
