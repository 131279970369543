import React, { FC } from 'react';
import styled from 'styled-components';
import {useWidgetsColorUpdate} from "@/pages/app/dashboard/hooks/use-widgets-color-update";

type ColorPickerOptionProps = {
  colors: string[]; // hex format
  isSelected: boolean;
  className?: string;
};

const ColorPickerOption: FC<ColorPickerOptionProps> = ({ colors, isSelected, className }) => {
  const [color1, color2, color3, color4] = colors;
  const { updateWidgetsColor } = useWidgetsColorUpdate();

 // @todo - fix double select
  return (
    <div
      className={className}
      style={{ borderColor: color1, borderStyle: isSelected ? 'solid' : 'hidden' }}
      onClick={() => updateWidgetsColor({ hexColor: color1 })}
    >
      <div className={'contentWrapper'}>
        <div className={'colorSquareWrapper'}>
          <span className={'colorBlock1'} style={{ backgroundColor: color1 }} />
          <span className={'colorBlock2'} style={{ backgroundColor: color2 }} />
          <span className={'colorBlock3'} style={{ backgroundColor: color3 }} />
          <span className={'colorBlock4'} style={{ backgroundColor: color4 }} />
        </div>
      </div>
    </div>
  );
};

const ColorPickerOptionStyled = styled(ColorPickerOption)`
  border-radius: 8px;
  border-width: 2px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  .contentWrapper {
    width: 25px;
    height: 25px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    margin: 4px;
  }

  .colorSquareWrapper {
    top: -50%;
    left: -50%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 50px;
    height: 50px;
    transform: rotate(45deg);

    span {
      height: 25px;
      width: 25px;
    }
  }
`;

export default ColorPickerOptionStyled;
