import AppContext from '@/pages/app/context';
import { convertUtcToTimezoneDateAndTime } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocuments, GetDocumentsParams } from '../api';
import DocumentActions from '../components/document-actions';
import DownloadUserDocument from '../components/download-user-document';
import { V4Document } from '../types/documents.types';

export const useDocuments = (params: GetDocumentsParams) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users, userCategories, skills, activeDepartment, inactiveUsers },
  } = useContext(AppContext);

  const { data, isLoading: loading } = useQuery({
    queryKey: ['get-documents', activeDepartmentId, params],
    queryFn: () => getDocuments(params),
  });

  const [columns, setColumns] = useState<ColumnsType<V4Document>>([]);

  useEffect(() => {
    setColumns([
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
      },
      {
        title: t('general.title'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
      },
      {
        title: t('GLOBAL.RECIPIENTS'),
        dataIndex: 'recipients',
        key: 'recipients',
        render: (_, document) => {
          const recipients = document.recipients.reduce(
            (result: { recipients: string[] }, recipient) => {
              switch (recipient.type) {
                case 'users':
                  result.recipients = recipient.ids.map(
                    (id) => [...users, ...inactiveUsers].find((user) => user.recordId === id)?.displayName || '',
                  );
                  break;
                case 'status':
                  result.recipients = recipient.ids.map(
                    (id) => userCategories.find((status) => status.id === id)?.name || '',
                  );
                  break;
                case 'skills':
                  result.recipients = recipient.ids.map((id) => skills.find((status) => status.id === id)?.name || '');
                  break;
                default:
                  break;
              }
              return result;
            },
            { recipients: [] },
          );
          if (recipients.recipients.length == 0) {
            return t('GLOBAL.ALL');
          }
          return Object.values(recipients).flat().join(', ');
        },
      },
      {
        title: t('general.sent-on'),
        dataIndex: 'sentAt',
        key: 'sentAt',
        sorter: true,
        render: (date: string) => {
          return date ? convertUtcToTimezoneDateAndTime(date, activeDepartment) : '-';
        },
      },
      {
        title: t('documents.documents.signature-required'),
        dataIndex: 'signatureRequired',
        key: 'signatureRequired',
        align: 'center',
        sorter: true,
        render: (signatureRequired: boolean) => {
          if (signatureRequired) return '✅';
          return '-';
        },
      },
      {
        title: t('GLOBAL.EXPIRATION_DATE'),
        key: 'expiryDate',
        dataIndex: 'expiryDate',
        sorter: true,
        render: (date: string) => {
          return date ? convertUtcToTimezoneDateAndTime(date, activeDepartment, 'L') : '';
        },
      },
      {
        title: t('general.created-on'),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: (date: string) => {
          return convertUtcToTimezoneDateAndTime(date, activeDepartment);
        },
      },
      {
        title: t('general.actions'),
        key: 'actions',
        render: (_, document: V4Document) => {
          if (params.userRecordId) {
            return <DownloadUserDocument document={document} userRecordId={params.userRecordId} />;
          }
          return <DocumentActions document={document} />;
        },
      },
    ]);
  }, [params.userRecordId, users, userCategories, skills, inactiveUsers]);

  return {
    columns,
    loading,
    documents: data?.data || [],
    meta: data?.meta,
  };
};
