import React from 'react';
import styled from 'styled-components';
import { V4Contract } from '../types/contracts.types';
import ApproveContract from './approve-contract';
import DeleteContract from './delete-contract';
import DownloadContract from './download-contract';
import DuplicateContract from './duplicate-contract';
import EditContract from './edit-contract';
import ResendContract from './resend-contract';

interface Props {
  className?: string;
  contract: V4Contract;
}

const ContractActions: React.FC<Props> = ({ className, contract }) => {
  return (
    <div className={className}>
      {!contract.sentAt && <ApproveContract contractId={contract.id} />}
      {contract.sentAt && !contract.signedAt && <ResendContract contractId={contract.id} />}
      <EditContract contract={contract} />
      <DuplicateContract contract={contract} />
      <DownloadContract contract={contract} />
      <DeleteContract contract={contract} />
    </div>
  );
};

export default styled(ContractActions)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
