import { usePusher } from '@/components/PusherProvider';
import ScrollToTop from '@/components/ScrollToTop';
import AuthContext from '@/context';
import { useWindowSize } from '@/hooks/use-window-size';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import {
  appendToFilename,
  getHtmlWithCodes,
  getHtmlWithVariables,
  handleError,
  TINYMCE_CONTENT_STYLE,
  TINYMCE_PLUGINS,
  TINYMCE_TOOLBAR,
  variablesObjectToArray,
} from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Col, Form, Input, message, Row, Select, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/es/upload/interface';
import moment from 'moment';
import { Channel } from 'pusher-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { uploadFileToSignedUrl } from '../../../api';
import ModalVariablesUsage from '../../../components/modal-variables-usage';
import { useSignatures } from '../../../signatures/hooks/use-signatures';
import { V4DocumentVariables } from '../../../types/index.types';
import { useDocumentVariables } from '../../hooks/use-document-variables';
import { createDocumentTemplate, editDocumentTemplate } from '../api';
import { useDocumentTemplate } from '../hooks/use-document-template';
import { V4ManageDocumentTemplatePayload } from '../types/document-templates.types';
import Header from './header';

const { Option } = Select;

interface Props {
  className?: string;
}

const DocumentTemplate: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const { windowSize } = useWindowSize();
  const { templateId } = useParams<any>();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);

  const { documentTemplate: activeDocumentTemplate, loading: loadingDocumentTemplate } = useDocumentTemplate(
    templateId,
  );
  const { signatures, loading: loadingSignatures } = useSignatures();
  const { documentVariables } = useDocumentVariables();

  const [form] = useForm();
  const [modalVariablesUsageVisible, setModalVariablesUsageVisible] = useState(false);
  const [htmlContent, setHtmlContent] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const editorRef = useRef<any>(null);
  const placeholdersRef = useRef<V4DocumentVariables | null>(null);
  const previewRef = useRef<any>(null);
  const pusher = usePusher();

  const [uploadedFileData, setUploadedFileData] = useState<{
    documentFiles: string[];
  } | null>(null);
  const [previewPdf, setPreviewPdf] = useState<string | null>(null);

  const newTemplate = templateId == 'new';

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusher && activeDepartmentId && userDetails) {
      channel = pusher.channel(`private-App.User.${userDetails.id}.Department.${activeDepartmentId}`);

      if (!channel) return;

      const callbacks = channel.callbacks;

      if (!callbacks.get('document_file_preview_generated')) {
        channel.bind('document_file_preview_generated', async (message: any) => {
          setPreviewPdf(message.pdfUrl);
        });
      }
    }

    return () => {
      if (channel) {
        channel.unbind();
      }
    };
  }, [pusher, activeDepartmentId, userDetails]);

  useEffect(() => {
    if (!file) return;
    const upload = async () => {
      const data = await uploadFileToSignedUrl(
        [{ filename: appendToFilename(file.name, `${activeDepartmentId}_${moment().unix()}`), filetype: file.type }],
        [file],
        true,
      );
      setUploadedFileData(data);
    };
    upload();
  }, [file]);

  // Add variables to the editor "variabled" selector
  useEffect(() => {
    if (documentVariables) {
      placeholdersRef.current = documentVariables;
    }
  }, [documentVariables]);

  useEffect(() => {
    if (!activeDocumentTemplate) return;

    form.setFieldsValue({
      ...activeDocumentTemplate,
    });

    if (activeDocumentTemplate.fileName && activeDocumentTemplate.type == 'upload') {
      setFileName(activeDocumentTemplate.fileName);
    }

    if (activeDocumentTemplate.type == 'html' && activeDocumentTemplate.html) {
      setHtmlContent(getHtmlWithCodes(activeDocumentTemplate.html, documentVariables));
    }

    if (activeDocumentTemplate.file) {
      setPreviewPdf(activeDocumentTemplate.file);
    }
  }, [location.pathname, activeDocumentTemplate, documentVariables]);

  const onFinish = async (values: any) => {
    setLoading(true);

    let payload: V4ManageDocumentTemplatePayload;

    if (file && uploadedFileData) {
      payload = {
        name: values.name,
        type: 'upload',
        fields: values.fields,
        file: uploadedFileData.documentFiles[0],
      };
    } else if (activeDocumentTemplate?.documentFileId) {
      payload = {
        name: values.name,
        type: 'upload',
        fields: values.fields,
        file: activeDocumentTemplate.documentFileId,
      };
    } else {
      payload = {
        name: values.name,
        type: 'html',
        fields: values.fields,
        html: getHtmlWithVariables(editorRef.current.getContent()),
      };
    }

    try {
      const documentTemplate = newTemplate
        ? await createDocumentTemplate(payload)
        : await editDocumentTemplate(templateId, payload);

      message.success(
        t(
          newTemplate
            ? 'documents.documents.templates.document-template-created'
            : 'documents.documents.templates.document-template-edited',
          { name: documentTemplate.name },
        ),
      );

      history.push('/app/documents/documents/templates');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    setPreviewPdf(null);
    return false;
  };

  const onClearTemplate = () => {
    setFile(null);
  };

  const onVariablesUsage = () => {
    setModalVariablesUsageVisible(true);
  };

  return (
    <div className={className}>
      <ModalVariablesUsage
        visible={modalVariablesUsageVisible}
        setVisible={setModalVariablesUsageVisible}
        variables={documentVariables}
      />
      <TableView>
        <Header form={form} loading={loading} />
        <ScrollToTop />
        <Spin spinning={loading || loadingDocumentTemplate}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            className="template-container"
          >
            <React.Fragment>
              <Row gutter={[20, 30]}>
                <Col span={24}>
                  <div className="card-shadow">
                    <Form.Item
                      name="name"
                      label={t('documents.documents.templates.template-name')}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}>
                      <div className="upload-file-container">
                        <div className="left">
                          <Upload
                            beforeUpload={beforeUpload}
                            accept=".docx"
                            maxCount={1}
                            showUploadList={false}
                            className="browse-btn-container"
                          >
                            <div className="content">
                              <button type="button" className="browse-btn">
                                {file ? file.name : fileName ? fileName : t('GLOBAL.BROWSE')}
                              </button>
                            </div>
                          </Upload>
                          {file || fileName ? <i className="icon-plus" onClick={onClearTemplate} /> : null}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <button type="button" style={{ textDecoration: 'underline' }} onClick={onVariablesUsage}>
                          {t('DOCUMENTS.VARIABLES.TITLE')}
                        </button>
                      </div>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
            {!file && !fileName ? (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          variablesObjectToArray(placeholdersRef.current)?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            ) : (
              <>
                {previewPdf ? (
                  <iframe src={previewPdf} width="100%" height="800px" />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin spinning={!previewPdf} />
                    <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                  </div>
                )}
              </>
            )}
            <div className="actions active">
              <Button loading={loading} type="primary" htmlType="submit">
                {t('GLOBAL.SAVE')}
              </Button>
            </div>
          </Form>
        </Spin>
      </TableView>
    </div>
  );
};

export default styled(DocumentTemplate)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  label {
    width: 100%;
  }

  .slider-arrow {
    margin: 0px 10px;
  }

  .ant-row {
    margin-bottom: 20px;
  }
  .template-container {
    padding: 0 25px 25px;
    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }
  .tox-tinymce {
    margin-bottom: 40px;
  }
  .card-shadow {
    padding: 0;
    margin-bottom: 40px;
  }

  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;
      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
      .icon-arrows-cw {
        padding: 0 15px;
        cursor: pointer;
      }
    }
    .browse-btn-container {
      flex: 1;
      .ant-upload-select {
        width: 100%;
        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
`;
