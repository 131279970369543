import AppContext from '@/pages/app/context';
import { editSettings } from '@/pages/app/team/collaborators/collaborator/overview/profile/api';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Form, InputNumber, message, Radio, Select, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import { useSettings } from './hooks/use-settings';

interface Props {
  className?: string;
}

const HrAttentia: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { features, activeDepartment },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { settings, loading: loadingSettings } = useSettings();

  useEffect(() => {
    onReset();
  }, [settings]);

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('settings.attentia.week-days.label'),
          description: t('settings.attentia.week-days.description'),
          name: 'weekDaysFullTime',
          style: { margin: 0 },
          element: (
            <InputNumber
              style={{ width: 100 }}
              placeholder="7"
              type="number"
              min={0}
              step="0.01"
              size="large"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          show: true,
        },
        {
          label: t('settings.attentia.average-hours.label'),
          description: t('settings.attentia.average-hours.description'),
          name: 'weekHoursAverage',
          style: { margin: 0 },
          element: (
            <InputNumber
              style={{ width: 100 }}
              placeholder="38"
              type="number"
              min={0}
              step="0.01"
              size="large"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          show: true,
        },
        {
          label: t('settings.attentia.effective-hours.label'),
          description: t('settings.attentia.effective-hours.description'),
          name: 'weekHoursEffective',
          style: { margin: 0 },
          element: (
            <InputNumber
              style={{ width: 100 }}
              placeholder="40"
              type="number"
              min={0}
              step="0.01"
              size="large"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          show: true,
        },
        {
          label: t('settings.attentia.payment-methods.label'),
          description: t('settings.attentia.payment-methods.description'),
          name: 'paymentMethod',
          style: { margin: 0 },
          element: (
            <Radio.Group>
              {settings?.fields.paymentMethods.map((paymentMethod) => (
                <Radio value={paymentMethod.id}>
                  <Tooltip overlay={paymentMethod.description} placement="left" mouseEnterDelay={0} mouseLeaveDelay={0}>
                    {paymentMethod.label}
                  </Tooltip>
                </Radio>
              ))}
            </Radio.Group>
          ),
          show: true,
        },
        {
          label: t('settings.attentia.wage-codes.label'),
          description: t('settings.attentia.wage-codes.description'),
          name: 'wageCodes',
          style: { margin: 0 },
          element: (
            <Select mode="multiple" size="large" placeholder={t('settings.attentia.wage-codes.label')}>
              {Object.entries(
                (settings?.fields.wageCodes || []).reduce((groups, wageCode) => {
                  const group = wageCode.group || 'Other'; // Default group if none exists
                  if (!groups[group]) groups[group] = [];
                  groups[group].push(wageCode);
                  return groups;
                }, {} as any),
              ).map(([group, wageCodes]) => (
                <Select.OptGroup key={group} label={group}>
                  {(wageCodes as any).map((wageCode: any) => (
                    <Select.Option key={wageCode.id} value={wageCode.id}>
                      {wageCode.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          ),
          show: true,
          elementBelow: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (settings) {
      form.setFieldsValue({ ...settings.data });
    }
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await editSettings(values);
      message.success(t('settings.attentia.settings-edited'));
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setFormHasChanged(false);
    }
  };

  return (
    <div className={className}>
      <h2>Attentia</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(HrAttentia)``;
