import AppContext from '@/pages/app/context';
import { useQuery } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSignatures } from '../api';
import SignatureActions from '../components/signature-actions';
import { V4Signature } from '../types/signatures.types';

export const useSignatures = () => {
  const { t } = useTranslation();
  const {
    state: { users },
  } = useContext(AppContext);

  const { data: signatures = [], isLoading: loading } = useQuery({
    queryKey: ['get-signatures'],
    queryFn: getSignatures,
  });

  const [columns, setColumns] = useState<ColumnsType<V4Signature>>([]);

  useEffect(() => {
    setColumns([
      {
        title: t('general.id'),
        dataIndex: 'id',
        key: 'id',
        render: (id: string, signature: V4Signature) => {
          return (
            <span>
              {id} {signature.default && <span className="badge-uppercase default-badge">{t('general.default')}</span>}
            </span>
          );
        },
      },
      {
        title: t('documents.signatures.signatory'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('documents.signatures.signatory-function'),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: t('general.signature'),
        dataIndex: 'url',
        key: 'url',
        render: (url: string) => {
          return <img src={url} height={60} />;
        },
      },
      {
        title: t('general.actions'),
        key: 'actions',
        render: (_, signature: V4Signature) => {
          return <SignatureActions signature={signature} />;
        },
      },
    ]);
  }, [users]);

  return {
    columns,
    loading,
    signatures,
  };
};
