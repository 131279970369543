import { IAddress } from '@/types/address.model';
import { AutoComplete } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
const service = new google.maps.places.AutocompleteService();
const geocoder = new google.maps.Geocoder();

interface Props {
  className?: string;
  value?: string;
  size?: SizeType;
  onChange?: (value: string) => void;
  onAddressChange: (address: IAddress) => void;
  disabled?: boolean;
}

const PlaceAutocomplete: React.FC<Props> = ({ className, value, size, disabled, onChange, onAddressChange }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const selectedOptionRef = useRef<any>(null);
  const autocompleteRef = useRef<HTMLFormElement>(null);

  const onAddressSearch = (searchText: string) => {
    setOptions([]);
    onAddressChange({});
    if (!searchText) {
      return;
    }
    service.getQueryPredictions({ input: searchText }, (predictions) => {
      if (predictions) {
        const newOptions = predictions.map((item: any) => ({
          value: item.description,
          key: item.place_id,
        }));
        setOptions(newOptions);
      } else {
        setOptions([]);
      }
    });
  };

  const onAddressSelect = (options: any) => {
    if (!options) {
      onAddressChange({});
    }
    if (options) {
      geocoder.geocode({ placeId: options.key }, (response) => {
        if (response?.length) {
          const { address_components, formatted_address, geometry } = response[0];
          console.log(response[0]);
          const address: IAddress = {
            address: formatted_address,
            fullAddress: formatted_address,
            city: null,
            country: null,
            lat: null,
            street: null,
            lng: null,
            streetNumber: null,
            zip: null,
          };
          if (address_components) {
            for (let i = 0; i < address_components.length; i++) {
              const address_component = address_components[i];
              if (address_component) {
                if (address_component?.types?.includes('street_number')) {
                  address.streetNumber = address_component.long_name;
                }
                if (address_component?.types?.includes('route')) {
                  address.street = address_component.long_name;
                }
                if (address_component?.types?.includes('locality')) {
                  address.city = address_component.long_name;
                }
                if (address_component?.types?.includes('country')) {
                  address.country = address_component.short_name;
                }
                if (address_component?.types?.includes('postal_code')) {
                  address.zip = address_component.long_name;
                }
              }
            }
          }
          address.lat = geometry?.location?.lat();
          address.lng = geometry?.location?.lng();
          onAddressChange(address);
        } else {
          onAddressChange({});
        }
      });
    }
  };

  return (
    <AutoComplete
      className={className}
      ref={autocompleteRef}
      options={options}
      onSelect={(value, option) => {
        selectedOptionRef.current = option;
        autocompleteRef.current?.blur();
      }}
      onBlur={() => {
        if (!selectedOptionRef.current) {
          onAddressSelect(options?.[0] || null);
        } else {
          onAddressSelect(selectedOptionRef.current);
          selectedOptionRef.current = null;
        }
      }}
      onSearch={debounce(onAddressSearch, 300)}
      onChange={onChange}
      value={value}
      placeholder={t('FORMS.ADDRESS_SEARCH')}
      allowClear={true}
      style={{ width: '100%' }}
      size={size || 'middle'}
      disabled={disabled || false}
    />
  );
};

export default PlaceAutocomplete;
