import { Button, Form, InputNumber, message, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';

interface Props {
  insightType: 'ca' | 'table_reservations' | 'number_of_events',
  className?: string;
}

const valuesByUnit = {
  'ca': {
    icon: '€',
    subTitle: 'SETTINGS.INSIGHTS.STAFF_BALANCE.SUB_TITLE_CA',
  },
  'table_reservations': {
    icon: <i className={'icon-table-restaurant'}/>,
    subTitle: 'SETTINGS.INSIGHTS.STAFF_BALANCE.SUB_TITLE_TABLE',
  },
  'number_of_events': {
    icon: <i className={'icon-calendar-alt'}/>,
    subTitle: 'SETTINGS.INSIGHTS.STAFF_BALANCE.SUB_TITLE_EVENT',
  },
}

const InsightBalance: React.FC<Props> = ({ className, insightType }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);

  const { icon, subTitle } = valuesByUnit[insightType];

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    form.resetFields();
    setFormHasChanged(false);
    if (!activeDepartmentId) {
      return;
    }
    setIsLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/staff-balance`, {
      params: {
        departmentId: activeDepartmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then(({ data }) => {
        if (mounted) {
          const { balance } = data;
          form.setFieldsValue({ balance });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/insights/staff-balance`,
      {
        ...values,
      },
      {
        params: {
          departmentId: activeDepartmentId,
        },
      },
    )
      .then(({ data }) => {
        const { balance } = data;
        form.setFieldsValue({ balance });
        setIsSaving(false);
        setFormHasChanged(true);
      })
      .catch((error) => {
        message.error(t('GLOBAL.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.STAFF_BALANCE.TITLE')}</h2>
      <p>{t(subTitle)}</p>
      <Spin spinning={isLoading || isSaving}>
        <Form
          layout="vertical"
          size="large"
          form={form}
          onValuesChange={() => setFormHasChanged(true)}
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.List name="balance">
            {(taskItems, { add, remove }) => {
              return (
                <div>
                  <div className="form-list">
                    <Form.Item
                      label={t('SETTINGS.INSIGHTS.FROM')}
                      style={{ display: 'inline-block', flex: '1', marginRight: '10px', marginBottom: 0 }}
                    />
                    <Form.Item
                      label={t('SETTINGS.INSIGHTS.TO')}
                      style={{ display: 'inline-block', flex: '1', marginRight: '10px', marginBottom: 0 }}
                    />
                    <Form.Item
                      label={t('SETTINGS.INSIGHTS.STAFF_BALANCE.NUMBER_OF_PEOPLE')}
                      style={{ display: 'inline-block', flex: '1', marginRight: '30px', marginBottom: 0 }}
                    />
                  </div>
                  {taskItems.map((taskItem) => {
                    return (
                      <div className="form-list" key={taskItem.key}>
                        <Form.Item
                          name={[taskItem.name, 'start']}
                          fieldKey={[taskItem.fieldKey, 'start']}
                          style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                          rules={[{ required: true, message: '' }]}
                        >
                          <InputNumber type="number" addonAfter={icon} />
                        </Form.Item>
                        <Form.Item
                          name={[taskItem.name, 'end']}
                          fieldKey={[taskItem.fieldKey, 'end']}
                          style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                          rules={[{ required: true, message: '' }]}
                        >
                          <InputNumber type="number" addonAfter={icon} />
                        </Form.Item>
                        <Form.Item
                          name={[taskItem.name, 'staff']}
                          fieldKey={[taskItem.fieldKey, 'staff']}
                          style={{ display: 'inline-block', flex: '1', marginRight: '30px' }}
                          rules={[{ required: true, message: '' }]}
                        >
                          <InputNumber type="number" />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(taskItem.name);
                          }}
                        />
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t('SETTINGS.INSIGHTS.STAFF_BALANCE.ADD_NEW_ROW')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
            <Button type="primary" htmlType="submit" loading={isSaving} style={{ width: '100%' }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default styled(InsightBalance)`
  form {
    margin-bottom: 50px;
    .form-list {
      position: relative;
      display: flex;
      .ant-row.ant-form-item {
        padding-bottom: 10px;
      }
      &:first-of-type {
        .ant-form-item-control {
          display: none;
        }
        .ant-form-item {
          padding-bottom: 0;
        }
      }
      .ant-row.ant-form-item {
        flex: 1;
        margin-bottom: 0;
        width: 100%;
        
        .ant-input-number.ant-input-number-lg {
          width: 100%;
        }

        .ant-input-number-group-wrapper {
          width: 100%;
        }
      }
      .anticon.anticon-minus-circle {
        position: absolute;
        margin-bottom: 25px;
        bottom: 0;
        right: 0;
        width: 20px;
      }
      .ant-form-item-explain.ant-form-item-explain-error {
        display: none;
      }
    }
    > .actions {
      position: fixed;
      width: 100%;
      max-width: 1024px;
      background: white;
      bottom: -80px;
      margin: 0;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;
      z-index: 2;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
