import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import moment, { Moment } from 'moment';
import { Affix } from 'antd';
import { default as Colors } from '@/styles/colors';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { useScheduleTurnover } from '@/pages/app/hours/manage/hooks';

interface Props {
  className?: string;
  offsetTop: number;
  totalEmployees: number[];
  totalHeadCounts: (number | null)[];
  columnWidth: number;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  hours: IScheduleHour[];
  selectedDate: Moment;
  balance: any;
  turnover: any;
}

const TotalEmployees: React.FC<Props> = ({
  className,
  offsetTop,
  totalEmployees,
  totalHeadCounts,
  columnWidth,
  refTotalHours,
  refPageContainer,
  hours,
  selectedDate,
  balance,
  turnover,
}) => {
  const [affixed, setAffixed] = useState(false);
  const [peopleNeededByHour, setPeopleNeededByHour] = useState<{ [$key: number]: number }>({});
  const {
    state: { activeSection, activeDepartment },
  } = useContext(AppContext);
  const { scheduleTurnover } = useScheduleTurnover(
    selectedDate.format('Y-MM-DD'),
    selectedDate.format('Y-MM-DD'),
    activeSection,
  );

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      if (element) {
        element.dispatchEvent(new Event('scroll'));
      }
    });
  };

  useEffect(() => {
    if (
      activeDepartment?.scheduleParams?.staff_quota_calculation_method === 'manual' &&
      scheduleTurnover &&
      scheduleTurnover.length > 0
    ) {
      const newPeopleNeededByHour: { [$key: number]: number } = {};

      hours.forEach((hour) => {
        const h = moment.unix(hour.date).hours();
        const turnoverTemplate = scheduleTurnover[0].details.find((quota) => quota.hour === h);

        if (!turnoverTemplate) {
          return null;
        }

        newPeopleNeededByHour[h] = turnoverTemplate.value;
      });

      setPeopleNeededByHour(newPeopleNeededByHour);
      return;
    }
    
    if (!turnover || !balance || !scheduleTurnover || scheduleTurnover.length === 0) {
      setPeopleNeededByHour({});
      return;
    }

    const newPeopleNeededByHour: { [$key: number]: number } = {};
    const biggestStaffQuota = balance[balance.length - 1];

    hours.forEach((hour) => {
      const h = moment.unix(hour.date).hours();
      const turnoverForDay = turnover[selectedDate.startOf('day').unix()];
      const turnoverTemplate = scheduleTurnover[0].details.find((quota) => quota.hour === h);

      if (!turnoverForDay?.value || !turnoverTemplate) {
        return null;
      }

      const hourTurnoverValue = (turnoverForDay.value * turnoverTemplate.value) / 100;
      const dayStaffQuota = balance.find(
        (element: any) => hourTurnoverValue > element.start && hourTurnoverValue <= element.end,
      );

      if (dayStaffQuota?.staff) {
        newPeopleNeededByHour[h] = dayStaffQuota.staff;
      }

      if(biggestStaffQuota && biggestStaffQuota?.end < hourTurnoverValue) {
        newPeopleNeededByHour[h] = biggestStaffQuota?.staff || 0;
      }
    });

    setPeopleNeededByHour(newPeopleNeededByHour);
  }, [turnover, scheduleTurnover, balance, selectedDate]);

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
      offsetTop={offsetTop}
    >
      <div ref={refTotalHours} className={`${className} ${affixed ? 'affixed' : ''}`}>
        <div className="left" />
        <div className={`right`}>
          {hours.map((hour, index) => {
            const total = totalEmployees[index];
            const peopleNeeded = peopleNeededByHour[moment.unix(hour.date).hours()];

            return (
              <div key={`budget_${index}`} style={{ width: columnWidth }}>
                {(total > 0 || peopleNeeded > 0) && (
                  <>
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {total}
                      {peopleNeeded && `/${peopleNeeded}`}
                    </span>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Affix>
  );
};

const TotalEmployeesStyled = styled(TotalEmployees)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${Colors.blueLight};
  background-color: #ffcf97;
  width: 100%;

  @media (max-width: 1440px) {
    min-width: 100%;
  }

  &.affixed {
    max-width: 100%;
    overflow: hidden;
  }

  > div {
    &.left {
      left: 0;
      width: 210px;
      padding: 10px;
      flex-shrink: 0;
      position: sticky;
      background-color: #ffcf97;
      border-right: 1px solid ${Colors.blueLight};
    }

    &.right {
      font-size: 10px;
      display: flex;
      flex-grow: 1;

      > div {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;

        &:nth-child(n + 2) {
          border-left: 1px solid ${Colors.blueLight};
        }
      }
    }
  }
`;

export default TotalEmployeesStyled;
